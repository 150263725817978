
@media only screen and (min-width:768px){

  .mobileview-show
  {
      display: none;
  }

}

@media only screen and (max-width:1400px){
.profile-menubar .gift-ico{
    width: 45px !important;
    height: 45px !important;
}

.profile-menubar .gift-ico img {
    max-width: 20px;
}

.count-bar{
    min-width: 110px !important;
    min-height: 45px !important;
}

.btn-login-bx .login-btn{
    width: 45px;
    height: 45px;
}

.profile-topbar .navbar-brand .img-fluid {
    max-width: 180px;
}

.lft-menu .navbar-brand {
    margin: 0 20px 0 20px !important;
}

.bell-ico img {
    max-width: 34px;
}

.pro-bread img {
    max-width: 31px;
}

} 

@media only screen and (max-width:1200px) and (min-width:768px){

    .kids-better-box .kids-main-bx .kids-content .btn-week
    {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
    }
    .videobx-main .video-custom .right-video-box .right-video-card img
    {
        height: auto;
    }
    .plan-card .btn-buy .btn-week
    {
        perspective: 1000px;
    }
    .footer-section .foter-logo {
        text-align: left;
        padding-left: 30px;
    }
.footer-section .row .one
{
    width: 35%;
}
.footer-section .row .two
{
    width: 65%;
}
.footer-section .foter-listnew ul li
{
    padding-left: 1px;
    padding-right: 1px;
}    

}  

@media only screen and (max-width:1200px){

    .banner-holder .right-box-slider .pic1-vector
    {
        left: 0px;
    }
    .testimonial-contain
    {
        padding: 0 20px;
    }
    .our-team-bx .teamlist-tex .teamlist-frame
    {
        width: 200px !important;
        height: 200px !important;
    }

    .about-section-boxx .about-card .aboutshape-bg:before
    {
        transform: rotateX(7deg) rotateY(1deg) !important;
        -webkit-transform: rotateX(7deg) rotateY(1deg) !important;
        -moz-transform: rotateX(7deg) rotateY(1deg) !important;
    }
    .dasboard-iiner .gamesoon-count ul li .number-remail
    {
        width: 46px !important;
        height: 65px !important;
        font-size: 30px !important;
    }

}   

@media only screen and (max-width:991px) and (min-width:768px){

    .videobx-main .video-custom
    {
        transform: translateY(40px);
    }
    .count-subj .listing-count li:nth-child(odd)
    {
        margin-top: 0px !important;
        margin-bottom: 50px !important;
    }
    .procust-section-new::before
    {
        left: 0px !important;
    }
    .product-slidertwo .prodt-contt
    {
        padding-left: 0px !important;
    }
    .prodt-listbtn li .tutor-becm .btnweak-bx
    {
        padding: 13px 12px !important;
        width: 140px !important;
        font-size: 16px !important;
    }
    .prodt-listbtn li
    {
        padding-right: 34px !important;
    }

    .dasboard-iiner .gamesoon-count ul li .number-remail {
        width: 35px !important;
        height: 55px !important;
        font-size: 26px !important;
    }

    .dasboard-iiner .gamesoon-count ul li:nth-child(2) {
        padding-right: 7px !important;
    }
    .dasboard-iiner .game-soon .gamerow .gamesoom-medium
    {
        flex: 0 0 40% !important;
        max-width: 40% !important;
    }
    .dasboard-iiner .game-soon .gamerow .gamesoon-mdd
    {
        flex: 0 0 30% !important;
        max-width: 30% !important;
    }
    .dasboard-iiner .game-soon .gamerow .gamesoon-right
    { flex: 0 0 30% !important;
        max-width: 30% !important;

    }
    .circlebx-list .circl-game-box .list-game-cardbd .smalbox img {
        max-width: 80px !important;
    }
    .circlebx-list .circl-game-box .list-game-cardbd .medimbox img {
        max-width: 110px !important;
    }
    .circlebx-list .circl-game-box .list-game-cardbd li img
    {
        max-width: 160px !important;
    }
    .circlebx-list .circl-game-box .list-game-cardbd li
    {
        margin-top: 80px !important;
    }

} 
@media only screen and (max-width:1450px) and (min-width:1201px){

    .educator-footer.footer-section .row .three {
        width: 22%;
    }
    .educator-footer.footer-section .row .one {
        width: 28%;
    }
   
    .educator-footer.footer-section .foter-logo {

        padding-left: 30px;
    }

} 

@media only screen and (max-width:1200px) and (min-width:992px){

    .count-subj .listing-count li
    {
        flex: 0 0 150px !important;
    max-width: 150px !important;
    margin-left:15px !important;
    margin-right:15px !important;
    }

    .count-subj .listing-count li h4
    {
        font-size: 45px !important;
    }


    .become-tutor .btn-buy {
        transform: translateY(30px) !important;
    }

    .testi-boxserch .slider-cardbox {
        padding: 20px 10px !important;
    }
    .banner-holder .right-box-slider .pic1-vector
    {
        left: 0px !important;
    }
    .become-tutor
    {
        padding: 0 20px !important;
    }
    
    .navbar-top .navbar-collapse {
        margin-left: 1%;
    }
    .navbar-top .navbar-collapse .navbar-nav li a
    {
        padding: 17px 15px;
        font-size: 16px;    
    }
    .navbar-light .navbar-brand
    {
        max-width: 180px;
    }
    .kids-better-box .kids-main-bx .kids-content {
        
        padding: 60px 80px 0px 40px;
    }
    .kids-better-box .kids-main-bx .kids-pic1
    {
        left: -60px;
    }
    .kids-better-box .kids-main-bx .kids-pic1 .pic1 {
        max-width: 300px;
    }
    .kids-better-box
    {
        max-width: 850px;
    }
    .why-illumiya .cardbx-left
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .week-free .weekfre-card {
        max-width: 650px;
        margin: 0 auto;
    }
    .still-thnk-box .rightpic-grl {
        position: absolute;
        top: -20px;
        right: -90px;
        z-index: 9;
        max-width: 400px;
    }

    .stud-contain .girlright-pic
    {
        max-width: 290px;
    }

    .form-contact .contact-detail
    {
        max-width: 700px !important;
    }
    .contact-bxx
    {
        padding-left: 20px;
    padding-right: 20px;
    }

    .games-card .games-card-bx .btn-week .btnweak-bx {
        padding: 13px 20px;
    }
    .games-card .card-inner-games .card-pic {
        flex: 0 0 150px;
        max-width: 150px;
    }

    .educator-footer.footer-section .row .two {
        width: 40%;
    }
    .educator-footer.footer-section .row .three {
        width: 30%;
    }
    .educator-footer.footer-section .row .one {
        width: 30%;
    }
    .educator-footer .foter-list .foter-listnew ul {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media only screen and (max-width:1128px) and (min-width:992px){

    .stud-contain .girlright-pic
    {
        bottom: 210px !important;
    }

}    

@media only screen and (max-width:991px){

 .desktop-show-only
 {
     display: none !important;
 }
.dashboard-topbarr .header-holder .lft-menu
{
    flex: 0 0 60%;
    max-width: 60%;
    text-align: left;
}
.dashboard-topbarr .header-holder .right-menu-dasboard
{
    flex: 0 0 40%;
    max-width: 40%;
    text-align: right;
    justify-content: flex-end;
}
.dashboard-topbarr .header-holder .right-menu-dasboard .bell-ico
{
    margin: 0 10px;
}


.mobileview-show.btn-getstarted {
    z-index: 9;
    padding-top: 10px;
}
.navbar-top
{
    .navbar-toggler
    {
        background: radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #632CAF;
        border-radius: 50%;
        color: #fff;
        width: 50px;
        height: 50px;
        padding: 0px;
        box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30), 0px 0px 0px 5px rgba(255, 255, 255, 0.25);
        order: 4;
        margin-right: 5px;
    }
    .navbar-toggler-icon
    {
        background-image: none !important;
        position: relative;
        width: auto;
        height: auto;
        font-size: 22px;
      
        &:before 
        {
            content: "\f0c9";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            position: relative;
            color: #fff;
        }
    }
 
}

.header-btn-top
{
    flex: 0 0 calc(100% - 340px);
    max-width: calc(100% - 340px);
    text-align: right;

}

.btn-login-bx .login-btn
{
    width: 50px;
    height: 50px;
}
.get-started {
    padding: 10px 15px;
}
.navbar-top
{
    .navbar-collapse
    {
        order: 5;
        margin: 0 auto;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        padding: 10px 40px 30px 40px;
    }
}

// .navbar-top .navbar-collapse:after
// {
//     transform: perspective(40em) rotateX(-10deg)
// }
// .navbar-top .navbar-collapse:before
// {
//     transform: perspective(40em) rotateX(-10deg)
// }
.navbar-top .navbar-collapse .navbar-nav li a
{
    z-index: 1;
}
.navbar-top .navbar-collapse .navbar-nav li a {
    padding: 10px 10px;
}
// .navbar-top .navbar-collapse .navbar-nav li .active:before
// {
//     transform: perspective(10px) rotateX(0.3deg);
// }
.navbar-top .navbar-collapse .navbar-nav li {
    padding: 10px 6px 0 6px;
}
.banner-holder {
    padding-top: 40px;
}
.home-sliderbx .slider-title h1
{
    font-size: 50px;
    line-height: 55px;
}
.kids-better-box .kids-main-bx .kids-content
{
    padding: 60px 60px 0px 30px;
}
.kids-better-box .kids-main-bx .kids-pic1 .pic1 {
    max-width: 250px;
}
.kids-better-box .kids-main-bx .kids-pic1 {
    left: -50px;
}
.kids-better-box
{
    top: 130px;
}
.heading-top .title-medium
{
    font-size: 34px;
    line-height: 46px;
}
.cardbx-left .cardbx-inner .card-row .card-pic
{
    flex: 0 0 120px;
    max-width: 120px;
    margin-left: -30px;
}
.cardbx-left .cardbx-inner .right-content-card p
{
    font-size: 18px;
    line-height: 24px;
    
}
.cardbx-left .cardbx-inner .card-row .right-content-card
{
    flex-basis: calc(100% - 120px);
    max-width: calc(100% - 120px);
}

.why-illumiya .cardbx-left
{
    padding-left: 10px;
    padding-right: 10px;
}
.cardpic-works img {
    max-width: 200px;
    width: 100%;
}
.card-body-works .smal-title
{
    font-size: 24px;
    line-height: 30px;
}
.videobx-main .video-custom .video-left {
    max-width: 200px;
    flex: 0 0 200px;

}
.videobx-main .video-custom .right-video-box
{
    height: 350px;
    padding: 15px 10px 5px 10px;
}
.playvd-btn
{
    width: 80px;
    height: 80px;
}
.games-card .card-inner-games .card-pic {
    flex: 0 0 100px;
    max-width: 100px;
}
.games-card .card-inner-games .right-card-games
{
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
}

.games-card .games-card-bx .btn-week .btnweak-bx {
    padding: 13px 15px;
    font-size: 16px;
    line-height: 24px;
}
.games-card .games-card-bx .btn-week {
    transform: translateY(40px);
}
.choose-plan
{
    padding-top: 80px;  
    padding-bottom: 400px;
}
.choose-plan .best-plan-card {
    padding-top: 30px;
}
.best-plan .heading-top {
    max-width: 300px;
    margin: 0 auto;
}
.best-plan-card .plan-card .title-plancard
{
    font-size: 24px;
    line-height: 30px;
}
.best-plan-card .plan-card .card-descript-paln
{
    font-size: 16px;
}
.best-plan-card .btnweak-bx
{
    padding: 13px 20px;
}
.testimonial-contain .testim-left .left-pic {
    flex: 0 0 200px;
    max-width: 200px;
}
.testimonial-contain .testim-left .right-box-testi {

    flex-basis: calc(100% - 200px);
    max-width: calc(100% - 200px);
}
.faq-main .accordion-button
{
    font-size: 20px;
}
.best-plan-card .card-icon-plan
{
    height: 120px;
}
.still-thnk-box .rightpic-grl {
    position: absolute;
    top: 30px;
    right: -140px;
    z-index: 9;
    max-width: 380px;
}
.still-thnk-box .still-content
{
    max-width: 540px;
}
.week-free .weekfre-card p
{
    font-size: 26px;
    line-height: 36px;
}
.week-free .weekfre-card {
    max-width: 500px;
    margin: 0 auto;
}
.still-thinking {
    padding-top: 80px;
    padding-bottom: 150px;
    overflow: hidden;
}
.footer-section .btnweak-bx {
    padding: 13px 10px;
    font-size: 16px;
    line-height: 24px;

}
.footer-section .foter-listnew ul li
{
    padding-bottom: 15px;
}
.footer-section .foter-listnew ul li a
{
    font-size: 14px;
    line-height: 24px;
}
.testimonial-bx .testi-main {
    transform: translateY(-250px);
    height: 360px;
}

.student-section
{
    max-height: initial;
}
.student-section .kids-better-box
{
    max-width: 95%;
}
.student-section .bgshpe
{
    transform: rotateX(-8deg) rotateY(0deg) !important;
    -webkit-transform: rotateX(-8deg) rotateY(0deg) !important;
    -moz-transform: rotateX(-8deg) rotateY(0deg) !important;
    left: 0px !important;
}
.student-section .kids-better-box .kids-main-bx .kids-pic2
{
    left: -20px !important;
}
.cardsection-int h4
{
    font-size: 18px !important;
    line-height: 26px !important;
}
.plan-works .btnweak-bx
{
    padding: 13px 12px !important;
    font-size: 15px !important;
}


.kids-better.useful-tech
{
    padding-top: 180px;
    padding-bottom: 150px;
}
.student-section .kids-better-box
{
    top: 120px;
}
.count-subj .listing-count li
{
    margin-bottom: 60px;
}
.become-tutor .custom-row .rightbx-tutor .tuttor-content h4
{
    font-size: 34px !important;
    line-height: 46px !important;
}
.become-tutor .custom-row .rightbx-tutor .tuttor-content {
    text-align: left;
    padding: 0 40px 0 40px !important;
}
.become-tutor .btn-buy {
    transform: translateY(30px) !important;
}
.testi-boxserch .slick-prev {
    left: 35% !important;
    z-index: 9;
}
.testi-boxserch .slick-next {
    right: 35% !important;
    z-index: 9;
}
.cardsection-int:before
{
    right: 0px;
}
.cardsection-int h4
{
    font-size: 20px;
    line-height: 27px;
}
.form-contact .contact-detail
{
    max-width: 500px !important;
}
.form-contact .girl-sidepic
{
    left: -60px;
    max-width: 180px;
}
.about-page {
    padding-top: 80px !important;
}
.stud-contain .girlright-pic
{
    bottom: 60% !important;
    right: 0px !important;
    max-width: 290px;
}

.about-section-boxx .about-content .about-card
{
    padding: 70px 20px 50px 40px;
}
.about-section-boxx .about-content .about-card:before
{
    left: -10px;
}
.useful-tech.about-section-boxx
{
    padding-bottom: 60px;
}
.out-team .our-team-bx {
    padding-top: 80px;
}
.foter-about {
    padding-top: 30px !important;
}
.out-team .subs-blog {
    padding: 60px 0 60px 0;
}

.about-page .stud-learn .stud-contain
{
    transform: translateY(60px);
   
}

.header-btn-top .btn-bx-get
{
    perspective: 1000px;
}
.about-page .slider-title .btn-week
{
    perspective: 1000px;
}


}

@media only screen and (max-width:767px){

    .serching-testimonial .testi-boxserch {
        max-width: 300px;
        margin: 0 auto;
    }
    .become-tutor .btn-buy {
        transform: translateY(50px) !important;
    }
    .intersting-you .custome-container .cardsection-int
    {
        padding-left: 0px !important;
    }

.container
{
    max-width: 100% !important;
}
.banner-holder .right-box-slider .pic1-vector
{
    left: 0;
    width: 100%;
    right: 0px;
    margin: 0 auto;
}
.desktop-show
{
    display: none !important;
}
.navbar-light .navbar-brand {
    color: rgba(0,0,0,.9);
    max-width: 150px;
}
.btn-login-bx .login-btn {
    width: 40px;
    height: 40px;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30), 0px 0px 0px 3px rgba(255, 255, 255, 0.25);
}
.btn-login-bx .login-btn:focus 
{
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30), 0px 0px 0px 3px rgba(255, 255, 255, 0.25) !important;
}
.btn-login-bx .login-btn i
{
    font-size: 15px;
}
.navbar-top .navbar-toggler
{
    width: 40px;
    height: 40px;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30), 0px 0px 0px 3px rgba(255, 255, 255, 0.25) !important; 
}
.navbar-top .navbar-toggler-icon
{
    font-size: 18px;
}
.header-btn-top
{
    flex:0 0 calc(100% - 250px);
    max-width: calc(100% - 250px);
}

.profile-topbar .header-btn-top {
    flex-basis:100%;
    max-width: 100%;
}

.home-sliderbx .slider-title h1 {
    font-size: 36px;
    line-height: 46px;
}
.home-sliderbx .slider-title p
{
    font-size: 18px;
}
.kids-section-uper .container 
{
    padding: 0px;
}

.kids-better-box .kids-main-bx .custom-row .left-pix-card {
    flex: 0 0 100%;
    max-width: 100%;
}
.kids-better-box .kids-main-bx .custom-row .kids-right {
    flex: 0 0 100%;
    max-width: 100%;
}
.kids-better-box .kids-main-bx .kids-pic1 {
    left: 0;
    position: relative;
}
.kids-better-box .bgshpe
{
    transform: rotateX(-2deg);
    -webkit-transform: rotateX(-2deg);
    -moz-transform: rotateX(-2deg);
}
.kids-section-uper {
    max-height: initial;
}
.kids-better-box .kids-main-bx .kids-content {
    padding: 60px 20px 0px 20px;
}
.kids-better-box .kids-main-bx .kids-content h4
{
    font-size: 26px;
    line-height: 32px;
    padding-bottom: 20px;
    max-width: 100%;
}
.kids-better
{
    padding-top: 200px;
    padding-bottom: 100px;
}
.navbar-top {
    padding-top: 20px;
}
.kids-better-box .kids-main-bx .kids-content .btn-week
{
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);    
}
.kids-better-box .kids-main-bx .btnweak-bx
{
    padding: 13px 20px;
}
.kids-better-box .kids-main-bx .kids-content p
{
    max-width: 100%;    
}
.cardbx-left .cardbx-inner
{
    max-width: 100%;    
}
.why-illumiya .heading-top {
    padding-bottom: 40px;
}
.cardbx-left .cardbx-inner:before
{
    transform: rotateX(-7deg) rotateY( 8deg);
    -webkit-transform: rotateX( -7deg) rotateY( 8deg);
    -moz-transform: rotateX(-7deg) rotateY(8deg);
}
.works-card .cards-works-bx
{
    text-align: center;
}
.works-card .card-body-works .smal-title {
    font-size: 18px;
    line-height: 26px;
}
.works-card .card-body-works p
{
    margin: 0 auto;
}

.why-illumiya .cardbx-left {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
}
.it-works {
    padding: 50px 0 50px 0;
}
.works-card .one,
.works-card .two,
.works-card .three,
.works-card .four
{
    width: 50%;
}
.rockt-icon {
 
    bottom: 5%;

}
.works-card .two .cards-works-bx, .works-card .four .cards-works-bx {
    margin-top: 0px;
}


.videobx-main .video-custom .video-left {
    max-width: 100%;
    flex: 0 0 100%;
    display: none;
}
.videobx-main .video-custom .right-video-box
{
    max-width: 100%;
    flex: 0 0 100%;
}
.videobx-main .video-custom .right-video-box {
    height: auto;
    padding: 15px 10px 5px 10px;
}

.playvd-btn {
    width: 50px;
    height: 50px;
}
.games-main .heading-top {
    max-width: 300px;
    margin: 0 auto;
}
.choose-plan {
    padding-top: 50px;
}
.best-plan-card .row .cardbxx
{
    padding-bottom: 70px;
}
.best-plan-card .one .plan-card, .best-plan-card .three .plan-card {
    transform: rotateX(3deg) rotateY(1deg);
    -webkit-transform: rotateX(3deg) rotateY(1deg);
    -moz-transform: rotateX(3deg) rotateY(1deg);
}
.choose-plan .best-plan-card
{
    padding-left: 5px;
    padding-right: 5px;
}
.testimonial-contain .testim-left .right-box-testi {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 20px;
}
.testimonial-contain .testim-left .left-pic {
    flex: 0 0 100%;;
    max-width: 100%;
    display: none;
}
.testimonial-bx .testi-main
{
    height: initial;
    transform: translateY(-130px);
}
.testimonial-boxx .slick-slider.slick-initialized {
    padding: 0 30px;
}
.slider-cardbox {
   
    padding: 60px 10px;
}
.slider-cardbox h4
{
    font-size: 22px;
    line-height: 26px;
}
.choose-plan
{
    padding-bottom: 170px;
}
.testimonial-contain
{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    
}
.games-card .games-content-bx h4
{
    font-size: 18px;
    line-height: 24px;
}
.games-card .games-content-bx h5
{
    font-size: 18px;
}
.videobx-main .video-custom .right-video-box .right-video-card img
{
    height: auto;
}
.videobx-main .video-custom .right-video-box .innervideo-frame
{
    padding: 20px 15px;
}
.faq-main .accordion-body
{
    padding: 30px 20px 30px 20px;
}
.faq-main .accordion-button {
    font-size: 18px;
}
.still-thnk-box .rightpic-grl
{
    display: none;
}
.still-thnk-box .still-content p
{
    font-size: 18px;
    line-height: 26px;
}
.still-thinking
{
    padding-top: 50px;
    padding-bottom: 100px;
}
.week-free .weekfre-card p {
    font-size: 18px;
    line-height: 26px;
    padding: 20px 15px;
}


.foter-list .foter-listnew ul {
    flex: 0 0 50%;
    max-width: 50%;
}
.foter-list .foter-listnew
{
    padding: 30px 10px 20px 10px;
}
.footer-section
{
    padding: 40px 15px;
}
.foter-copywrite ul
{
    text-align: center;
}
.footer-main {
    padding: 0 5px;
}
.videobx-main .video-custom .right-video-box
{
    margin-top: -110px;
}
.best-plan-card .two .plan-card
{
    transform: rotateX(3deg) rotateY(1deg);
    -webkit-transform: rotateX(3deg) rotateY(1deg);
    -moz-transform: rotateX(3deg) rotateY(1deg);
}
.plan-card .btn-buy .btn-week
{
    perspective: 1000px;
}

.games-card .two .games-card-bx:before
{
    transform: rotateX(-16deg) rotateY(2deg);
    -webkit-transform: rotateX(-16deg) rotateY(2deg);
    -moz-transform: rotateX(-16deg) rotateY(2deg);
}

.games-card .three .games-card-bx:before
{
    transform: rotateX(-16deg) rotateY(2deg);
    -webkit-transform: rotateX(-16deg) rotateY(2deg);
    -moz-transform: rotateX(-16deg) rotateY(2deg);
}
.games-card .games-card-bx
{
    margin-bottom: 50px;
}

.top-games-box {
    padding-top: 60px;
}
.games-card .games-content-bx {
    max-width: 100%;
}
.games-card .games-card-bx
{
    max-width: 100%;    
}
.games-card .games-card-bx .btn-week .btnweak-bx
{
    padding: 13px 20px;
    font-size: 15px;
}

.testimonial-boxx:before
{
    
    left: 0px;
}

.student-section .kids-better-box .kids-main-bx .custom-row .kids-right {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
.student-section .kids-better-box .kids-main-bx .custom-row .left-pix-card {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
.student-section .kids-better-box .kids-main-bx .kids-content {
    padding: 60px 30px 60px 30px !important;
}

.student-section .bgshpe {
   
    left: -0px !important;
}
.student-section .bgshpe
{
    transform: rotateX(-2deg) rotateY( 0deg) !important;
 -webkit-transform: rotateX( -2deg) rotateY( 0deg) !important;
 -moz-transform: rotateX(-2deg) rotateY(0deg) !important;
}
.count-subj .listing-count
{
    justify-content: flex-start !important;
}

.tutot-box .shape-tutor-right
{
    right: 0px !important;
    transform: rotateX(-3deg) rotateY(-3deg) !important;
            -webkit-transform: rotateX(-3deg) rotateY(-3deg) !important;
            -moz-transform: rotateX(-3deg) rotateY(-3deg) !important;
}
.tutr-shape-outer
{
    width: 100% !important;
}
.cardbox-tutor
{
    height: auto !important;
}

.student-section .kids-better-box .kids-main-bx .kids-pic2 {
    position: relative;
    top: 0px;
    left: -90px;
    margin-top: -90px;
}
.kids-better.useful-tech
{
    padding-top: 120px;
}
.count-section .count-subj .listing-count li
{
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 20px;
}
.count-section .count-subj .listing-count li h4
{
    font-size: 52px;
}
.count-section {
    padding: 100px 0 !important;
}
.become-tutor .custom-row .tutor-leftbx {
    flex: 0 0 100% !important;
    max-width:100% !important;
}
.become-tutor .custom-row .rightbx-tutor
{
    flex: 0 0 100% !important;
    max-width:100% !important;
    padding-bottom: 40px;
}
.become-tutor .custom-row .tutor-leftbx .left-boxpic
{
    margin-bottom: 80px !important;
}
.become-tutor .custom-row .tutor-leftbx .left-boxpic .shape-bx-pic
{
    right: 0px;
    margin: 0 auto !important;
    width: 80% !important;
}
.become-tutor .tutot-box .shape-tutor-right
{
    width: 100%;
}
.kids-better.useful-tech
{
    padding-bottom: 100px;
}
.testi-boxserch .slick-prev {
    left: 25px !important;
    z-index: 9;
}
.testi-boxserch .slick-next {
    right: 25px !important;
    z-index: 9;
}
.communty-pot {
    padding: 10px 10px 50px 10px !important;
}
.testimonial-eduction .testi-main .heading-top .title-medium {
    text-align: center !important;
}
.intersting-you .custome-container .cardsection-int
{
    margin-bottom: 20px;
}
.intersting-you {
    padding: 80px 0 60px 0 !important;
}

.cardsection-int .custom-row .right-cardint .inti-content {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.intersting-card .cardsection-int h4
{
    font-size: 20px;
    line-height: 26px;
}
.intersting-card .cardsection-int:before
{
    right: 0px;
}

.serching-testimonial {
    padding: 50px 0 50px 0 !important;
}
.blogcard .card-blogmain:before
{
    left: -2px;
}
.blogcard .blog-card-right
{
    padding:30px 0 20px 0;
}
.blogsection
{
    padding: 80px 0 100px 0 !important;
}
.blog-serchbar .serchfiled .formbox
{
    padding: 10px 50px 10px 20px !important;
    font-size: 14px;
    
}
.blog-serchbar .blog-card-right .content-card-inner .title-main
{
    font-size: 30px;
}
.subs-blog .subscribe-bxx .communty-box {
    padding: 50px 20px 50px 20px;
}
.subs-blog {
    padding: 50px 10px 60px 10px !important;
}
.inner-page-topbar
{
    padding-bottom: 35px !important;
}
.heading-top .title-medium {
    font-size: 30px;
    line-height: 40px;
}
.form-contact .girl-sidepic
{
    display: none;
}
.form-contact
{
    padding: 70px 30px 40px 30px !important;
}
.form-contact:before
{
    transform: perspective(1000px) rotateX( 8deg) rotateY(0deg) !important; 
}
.contact-main .contact-bxx .subsform-fild .form-control
{
    height: 50px;
}
.contact-main .contact-bxx .subsform-fild textarea.form-control
{
    height: 180px;
}
.contact-bxx .subtitle p
{
    font-size: 16px !important;
    line-height: 26px !important;
}
.contact-bxx {
    padding-bottom: 80px !important;
}
.stud-contain .girlright-pic
{
    display: none;
}

.about-page .stud-learn .stud-contain
{
    transform: translateY(0px);
    margin-top: 40px !important;
}
.about-content
{
    padding-left: 15px;
    padding-right: 15px;
}
// .tutot-box .shape-tutor-right
// {
//     transform: perspective(1000px) rotateX(-4deg) rotateY(0deg) !important;
// }
.become-tutor
{
    padding: 0 10px;
}
.procust-section-new::before
{
    left: 0px !important;
}
.product-section
{
    padding-left: 10px;
    padding-right: 10px;
}
.product-slidertwo .procust-section-new .two
{
    order: 2;
}
.prodt-contt
{
    padding-top: 30px;
}
.our-team-bx .teamcard h5
{
    font-size: 20px;
    line-height: 26px;
}
.our-team-bx .teamcard h6
{
    font-size: 16px;
}
.prodt-listbtn li
{
    padding-bottom: 20px;
}
.teamlist-tex ul
{
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
}
.teamlist-tex ul li
{
    flex: 0 0 50%;
    max-width: 50%;
}
.our-team-bx .teamlist-tex ul li .teamlist-frame
{
    width: 130px;
    height: 140px;
}
.about-content .about-card:before
{
    transform: perspective(1000px) rotateY(-2deg) rotateX( 2deg) !important;
    left: 0px !important;
}
.about-section-boxx .about-content .about-card
{
    padding: 40px 20px 40px 20px !important;
}
.contact-bxx
{
    padding-left: 20px;
padding-right: 20px;
}

.city-select {
    margin-top: 20px;
}
.profilesection .profile-tabs{
    margin: 0 auto 60px !important;
}

.profilesection .profile-form-section li select.form-control{
    background-position: 96% 24px !important;
}

.profilesection .profile-form-section li .form-control{
    padding: 14px 20px !important;
}

.phone-bar .number-fld .subsform-fild .form-control {
    padding: 4px 2px !important;
}

.social-media ul{
    width: 100%;
    justify-content: center;
}

.footer-section .foter-logo {
    text-align: center;
    padding-left: 0;
}
.product-section .slider-cardbox .slider-innercard
{
    max-width: 400px;
}
.product-section .procust-section-new .sliderpic
{
    max-width: 400px;
    margin: 0 auto;
}
.procust-section-new::before
{
    transform: perspective(1000px) rotateX(3deg) rotateY(0deg) !important;
    -webkit-transform: perspective(1000px) rotateX(3deg) rotateY(0deg) !important;
    -moz-transform: perspective(1000px) rotateX(3deg) rotateY(0deg) !important;
}
.product-slidertwo .procust-section-new::before
{
    transform: perspective(1000px) rotateX(-3deg) rotateY(0deg) !important;
    -webkit-transform: perspective(1000px) rotateX(-3deg) rotateY(0deg) !important;
    -moz-transform: perspective(1000px) rotateX(-3deg) rotateY(0deg) !important;
}
.about-section-boxx .about-card .aboutshape-bg:before
{
    transform: rotateX(4deg) rotateY(1deg) !important;
    -webkit-transform: rotateX(4deg) rotateY(1deg) !important;
}
.stud-learn .stud-learn-list ul li .list-std-leann p {
    padding-right: 30px !important;
}
.procust-section-new
{
    padding: 40px 20px !important;
}
.product-slidertwo .prodt-contt {
    padding-left: 0px !important;

} 
.our-team-bx .title-team
{
    font-size: 18px !important;
    line-height: 26px !important;
    margin: 20px auto 20px !important;
}
.our-team-bx .teamlist-tex .small-tex
{
    text-align: center !important;
}
.our-team-bx .teamlist-tex .t-status
{
    text-align: center !important;
}
.our-team-bx .teamlist-tex .t-name
{
    text-align: center !important;
}
.our-team-bx .teamlist-tex .team-text
{
    padding-top: 20px;
}
.card-blogmain .blog-leftpic .picard img
{
    margin: 0 auto !important;
}
.blog-card-right .content-card-inner .title-main {

    font-size: 26px !important;
    line-height: 34px !important;
    padding-bottom: 20px !important;
}
.serchfiled:before
{
    top: 0px !important;
}
.serchfiled .formbox
{
    height: 60px !important;
}
.serchfiled
{
    height: 60px !important;
}
.serchfiled .seachbtn
{
    height: 60px !important;
}

.dasboard-iiner .game-soon .gamerow .gamesoon-mdd, 
.dasboard-iiner .game-soon .gamerow .gamesoom-medium,
 .dasboard-iiner .game-soon .gamerow .gamesoon-right
{
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
.dasboard-iiner .game-soon .gamesoon-left
{
    text-align: center !important;
}
.dasboard-iiner .game-soon .gamesoon-left .takepart-tex
{
    padding-top: 30px !important;
    margin: 0 auto;
    padding-bottom: 20px;
}
.back-circl-dot .backdot-listt li .dotcircl-bk .dotcrl-icn
{
    width: 30px !important;
    height: 30px !important;
}
.back-circl-dot .backdot-listt .md-dot .dotcircl-bk .dotcrl-icn {
    width: 20px !important;
    height: 20px !important;
}
.back-circl-dot .backdot-listt .sml-dot .dotcircl-bk .dotcrl-icn
{
    width: 15px !important;
    height: 15px !important;
}
.back-circl-dot
{
    display: none;
}
.circl-box .circlebx-list .circle-linebx
{
    display: none;
}
.circlebx-list .circl-game-box .list-game-cardbd li
{
    margin-top: 0 !important;
}
.circlebx-list .circl-game-box .list-game-cardbd
{
    flex-wrap: wrap;
}
.circlebx-list .circl-game-box .list-game-cardbd .smalbox img {
    max-width: 100px !important;
}
.circlebx-list .circl-game-box .list-game-cardbd .medimbox img {
    max-width: 100px !important;
}
.circlebx-list .circl-game-box .list-game-cardbd .medimbox .gamecircle-tex h5 {
    font-size: 16px !important;
    line-height: 15px !important;
}
.circlebx-list .circl-game-box .list-game-cardbd .medimbox .gamecircle-tex h6
{
    font-size: 12px !important;
}
.circlebx-list .circl-game-box .list-game-cardbd .lginbox
{
    width: 100%;
    padding: 20px 0;
}
.circlebx-list .circl-game-box .list-game-cardbd .lginbox img
{
    max-width: 180px !important;
}
.circlebx-list .circl-game-box .list-game-cardbd .lginbox .gamecircle-tex h5 {
    font-size: 26px !important;
    line-height: 30px !important;
}
.circlebx-list .circl-game-box .list-game-cardbd .lginbox .gamecircle-tex h6
{
    font-size: 14px !important;
}
.dasboard-iiner .game-soon
{
    padding: 20px 20px !important;
}
.dasboard-iiner .gamesoom-medium {
    padding: 0 0px !important;
}
.dasboard-iiner .gamesoon-count ul li .number-remail {
    width: 40px !important;
    height: 60px !important;
    font-size: 26px !important;
}
.dasboard-iiner .game-soon .gamesoon-left .toptex-game h4
{
    font-size: 26px !important;
    line-height: 30px !important;
}
.gamesoon-right
{
    padding-top: 20px;
}
.bell-ico img {
    max-width: 30px !important;
}
.dashboard-topbarr .header-holder .right-menu-dasboard .bell-ico {
    margin: 0 5px !important;
}
.dashboard-topbarr .lft-menu .navbar-brand {
    margin: 0 20px 0 5px !important;
}
.sidemenubar-main .logobar-top .logoimg a
{
    display: inline-block;
}

}    
@media only screen and (max-width:575px){
    .communtiy-content .subs-form .custom-row .leftfiel-subs
    {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;;
    }
    

}

@media only screen and (max-width:350px){
    .games-card .games-card-bx .btn-week .btnweak-bx {
        padding: 13px 13px;
        font-size: 14px;
       
    }
    .count-section .count-subj .listing-count li
    {
        padding: 0px 10px;
    }
    .dashboard-topbarr .navbar-brand .img-fluid {
        max-width: 150px !important;
    }
    .dashboard-topbarr .pro-bread img {
        max-width: 25px!important;
    }
    .sidemenubar-main .logoimg img {
        max-width: 180px !important;
    }
    .sidemenubar-main .close-sidebar .btnclose
    {
        width: 25px !important;
        height: 25px !important;
    }

}  