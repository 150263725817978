.profile-list-inner
{
    position: relative;
    padding: 0px 0 10px 0;
    margin: 0px;
    li 
    {
        padding: 10px 0;
        .profile-linking
        {
          
        color: black;
        border-radius: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-decoration: none;
        display: block;

        &:hover 
        {
            color: var(--yellow-dark);
        }
        }
        .active
        {
            font-weight: 700;
        }
    }
    
}
.profile-footer
{
    padding-top: 30px;
    .btnweak-bx
    {
        border: none;
        padding: 10px 30px;
    }
}
.profile-lftsidee
{
    padding: 0 0 30px 0;
}
.myprofile-main
{
    .profilesection 
    {
        background: transparent;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        .profile-tabs 
        {
            .profileshape-bg
            {
                &:before 
                {
                    height: 85%;
                }
            }
        }
        .profile-pic 
        {
            .profile-image 
            {
                position: relative;
                .file-upnew {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    .profile-uplod-btn
                    {
                        width: 40px;
                        height: 40px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.myprofile-main
{
    padding-bottom: 80px;
    .educator-subject-list 
    {
        padding: 10px 0 10px 0;
        .subj-list-box 
        {
            padding: 0px;
        margin: 0px;
        li 
        {
            display: block;
            .subject-checkk
            {
                padding-left: 30px;
                margin-bottom: 12px;
            }
        }
        }
    }
}
.myprofile-main .profilesection .profile-form-section  .subj-list-box  li:last-child
{
    margin: 0px;
}


.myprofile-main .subject-checkk:hover input ~ .checkmark {
    background: rgba(255, 255, 255, 0.5);
}
.myprofile-main .subject-checkk .checkmark
{
    background: rgba(255, 255, 255, 0.5);
}

.myprofile-main .age-slidermax .css-eg0mwd-MuiSlider-thumb:hover, .age-slidermax .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.05);
}
.myprofile-main .age-slidermax .css-187mznn-MuiSlider-root {
    color: #ffffff;
}
.myprofile-main .age-slidermax .css-1kz0hui-MuiSlider-valueLabel
{
    background-color:#fff;
    color:var(--purple-dark);
}
.personal-info-headg
{
    h4 
    {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        text-align: left;
        margin-bottom: 0px;
        padding-bottom: 10px;
    }
}

.myprofile-main .age-slidermax .MuiSlider-root {
    color: #ffffff;
}
.myprofile-main .age-slidermax .MuiSlider-valueLabel  {
    background-color: #fff;
    color: var(--purple-dark);
}
.myprofile-main .age-slidermax .MuiSlider-thumb:hover, 
.age-slidermax .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.05);
}

// start subscripation

.Subscription-section
{
    .subs-cardpaln
    {
        background-color: rgba(255, 255, 255, 0.25);
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }
    .newlesson-listclk
    {
        max-width: 280px;
        margin: 0 auto;
        li 
        {
            .clock-time 
            {
                .timenum 
                {
                    font-size: 45px;
                    line-height: 48px;
                }
            }
        }
    }
    .btn-list-lesson 
    {
        .btn-listing-lesson 
        {
            li
            {
                padding-top: 10px;
            }
        }
    }
    .newlesson-top 
    {
        .bnewleson-heading 
        {
            padding: 8px 5px;
        }

    }
    .lessontime-bottom {
        padding: 10px 0 40px 0;
    }
    .newlesson-clock
    {
        padding: 20px 0 0px 0;
    }
    .curent-plan-left
    {
        text-align: center;
        h4 
        {
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            color: var(--yellow-dark);
            margin: 0px;
        }
        h3 
        {
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            color: var(--white);
            margin: 0px;
            padding-bottom: 10px;
        }
        p 
        {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: var(--white);
            margin: 0px;
            padding-bottom: 15px;
        }
        .duration
        {
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            color: var(--yellow-dark);
            margin: 0px; 
        }


    }
}

.weekly-cardiner
{
    background-color: var(--white);
    cursor: pointer;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 25px;
    height: 220px;
    overflow: hidden;
    transition: all 300ms linear;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    &:hover 
    {
        transition: all 300ms linear;
        height: 320px;
    }
    .discountText {
        font-weight: bold;
        color: black;
    }
    .points-btn
    {
        .totalpoints-btn
        {
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            color: var(--white);
            background-color: var(--sky);
            border-radius: 11px;
            height: 34px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;
            max-width: 120px;
            width: 100%;
        }
    }

    .quiz-bdy-content-bx 
    {
        p
        {
            max-width: 100%;
        }
        h4 
        {
            padding-bottom: 0px;
        }
        .duration-days
        {
            padding-bottom: 20px;
        }
    }
    .quiz-bdy-content-bx
    {
        position: relative;

        .weeklyhwr
        {
            padding-top: 20px;
            opacity: 0;
            transition: all 300ms linear;
        }
    }
    
    &:hover 
    {
        .weeklyhwr
        {
            opacity: 1;
            transition: all 300ms linear;
        }
    }
}
.weekly-pic
{
    padding-bottom: 20px;
    img 
    {
        width: 100%;
        max-width: 100px;
    }
}
.weekly-cardbx
{
    padding-top: 20px;
}

.tablebx-main
{
    tbody 
    {
        tr 
        {
         border-top: none;  
            td 
            {
                border: none;
                font-size: 14px;
                line-height: 18px;
                color: var(--grey);
                padding: 12px 6px;
               &:first-child 
               {
                   text-align: left;
               } 

            }
            &:last-child
            {
                border-bottom: none;
            }

            &:hover 
            {
                background-color: rgba(99, 44, 175, 0.07); 
                td 
                {
                    --bs-table-accent-bg:transparent;
                    color: var(--grey);
                }
            }
        }
    }
}
.tablebx-main
{
    background-color:var(--white);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.date-frmt
{
    white-space: nowrap;
}
.table-main
{
    padding-top: 30px;
}
.sky
{
    color: var(--sky) !important;
}
.purple
{
    color: var(--purple-dark) !important;
}
.orange
{
    color: var(--orange) !important;
}
.red 
{
    color: #ff0000 !important;
}

// end subscription

// start earaning

.earning-top
{
    background-color:var(--white);
    border-radius: 20px;
    padding: 40px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    .earning-card
    {
        ul 
        {
            padding: 0px;
            margin: 0;
            text-align: left;
            li 
            {
                padding: 0px 30px 0 0 ;
                margin: 0px;
                display: inline-block;
                text-align: center;
                .iner-cardearbg
                {
                    h5 
                    {
                        font-size: 12px;
                        text-transform: uppercase;
                        color: var(--purple-dark);
                        margin: 0px;
                    }
                    h4 
                    {
                        font-size: 30px;
                        font-weight: 700;
                        color: var(--grey);
                        margin: 0px;
                    }
                }
               
            }
        }

        .withdraw
        {
            .btnweak-bx
            {
                padding: 13px 30px;
            }
        }
    }
}
.table-form-top
{
    .form-control
    {
        color: #fff;
        border: none;
        padding: 15px 15px;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 500;
        background: rgba(255, 255, 255, 0.5);
       &:focus 
       {
        background: rgba(255, 255, 255, 0.5);
        color: #fff;
       }
       
    }
    select.form-control 
    {
        background: url(../assets/images/arrow-down.png) rgba(255, 255, 255, 0.5);
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: 93% 25px;
        padding: 15px 30px 15px 15px;

        &:focus 
        {
            background: url(../assets/images/arrow-down.png) rgba(255, 255, 255, 0.5);
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: 93% 25px;
         color: #fff;
        }
      
    }
    .form-group
    {
        padding-bottom: 10px;
    }
    .formlist-date
    {
        padding: 0px;
        margin:0px;
        display: flex;
        flex-wrap: wrap;

        li 
        {
            padding: 0px 24px 0 0;
            margin: 0px;
            flex: 0 0 50%;
            max-width: 50%;
            &:last-child 
            {
                padding-right: 0px;
            }

        }
    }
}

input[type="date"]::-webkit-calendar-picker-indicator
{
  
    background-image: url(../assets/images/calendar-week-solid.svg);
    
    background-repeat: no-repeat;
 
}

.iner-trasti
{
    padding-bottom: 10px;
    padding-top: 10px;
    .transction-row
    {
        display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color:var(--purple-dark);
    padding: 10px 15px;
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    }
}
.earnings-section
{
    .table-main
    {
        padding-top: 50px;
    }
}
.loadbtn-more
{
    text-align: left;
    padding-top:40px;
.btnweak-bx
    {
        padding: 10px 40px;
    }
}

.textlink
{
    color: var(--orange);
    text-decoration: underline;
}

// start security css

.security-subscribebx {
    padding-top: 80px;
}


// List of switches styles
.list {
	list-style: none;
	margin: 0;
	padding: 30px 16px;
	background-color: white;
	border-radius: 24px;
	
	
	&__item {
		padding: 4px 20px;
		border-radius: 20px;
		transition: .3s ease all;
		will-change: background-color;
		
		&:hover {
			background-color: #fafcfc;
		}
		
	}
}

// Switches style custom


.switch {
    display: inline-block;
    height: 30px;
    position: relative;
    width: 46px;

  }
  
  .switch input {
    display:none;
  }
  
  .switch .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }
  
  .switch .slider:before {
    background-color: #fff;
    bottom: 3px;
    content: "";
    height: 24px;
    left: 3px;
    position: absolute;
    transition: .4s;
    width: 24px;
  }
  
  .switch input:checked + .slider {
    background-color: var(--orange);
  }
  
  .switch input:checked + .slider:before {
    transform: translateX(16px);
  }
  
  .switch .slider.round {
    border-radius: 34px;
  }
  
  .switch .slider.round:before {
    border-radius: 50%;
  }

//   end swtich custom

//   inner swtich custom

.listswitch-li .iner-switchlist .inner-listswitch .switch .slider:before
{
    width: 16px;
    height: 16px;
}
.listswitch-li .iner-switchlist .inner-listswitch .switch
{
    height: 22px;
    width: 38px;
    flex: 0 0 auto;
    max-width: 38px;
}
//   end inner swtich custom

.switch-listt
{
    padding: 0px;
    margin: 0px;
    .listswitch-li
    {
        padding-bottom: 25px;

        .switch
        {
            flex: 0 0 auto;
            max-width: 46px;

        }


    }
    .switchbox
    {
        display: flex;
        flex-wrap: wrap;
      
        justify-content: space-between;
      

        .title-switch
        {
           
            color: var(--white);
            text-align: left;
            flex: 1;
        }
        .bold
        {
            font-size: 16px;
            font-weight: 700;
        }
    }
    .listswitch-li
    {
        .iner-switchlist
        {
            padding: 0px 0px 0 20px;
            margin: 0px;

            .inner-listswitch
            {
                .switchbox
                {
                    display: flex;
                    flex-wrap: wrap;
                   
                    justify-content: space-between;
                    padding-top: 20px;
                    .title-switch
                    {
                        
                        color: var(--white);
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
.save-notify
{
    text-align: left;
    padding-top: 20px;
    .btnweak-bx
    {
        padding: 10px 40px;
    }
}

// start support

.support-section
{
    .profile-form-section 
    {
        li
        {
           
            &:last-child{
                margin-top: 0px;
                margin-bottom: 20px;
            }

            .textarea-bx
            {
                resize: none;
                min-height: 150px;
            }
        }
            .support-btnlist
            {
                ul 
                {
                    padding: 30px 0px 0 0;
                    margin:0px;
                    text-align: left;

                    li
                    {
                        display: inline-block;
                        padding-right: 50px;
                        padding-bottom: 20px;
                        .btn-week
                        {
                            .btnweak-bx
                            {
                                padding: 10px 10px;
                                width: 200px;
                                text-align: center;
                                font-size: 16px;
                            }
                        }

                        &:last-child
                        {
                            padding-right: 0px;
                        }
                    }
                }
            }
    }
}

//  start how earn css

.howearn-main
{
    .current-lesson-main 
    {
        .current-lesson-topbr 
        {
            max-width: 100%;
            padding: 0 60px;
        }
        .current-lesson-contain 
        {
            .backbtn 
            {
                top: 10px;
            }
        }
    }
}
.earn-section
{
    .earn-contentbx
    {
        h3 
        {
            font-weight: 600;
            font-size: 28px;
            line-height: 30px;
            text-align: left;
            color: #fff;
            margin: 0 0 0px 0;
            padding-bottom: 10px;
        }
        p 
        {
            font-size: 14px;
            margin: 0;
            padding: 0 0 15px 0;
            text-align: left;
            letter-spacing: 0.04em;
            line-height: 20px;
            color: #fff;
        }
        .tex-underline
        {
            text-decoration: underline;
            color: var(--yellow-dark);
        }
        .smltex-uppr
        {
            margin: 0px;
            font-size: 14px;
            font-weight: 400;
            color: var(--yellow-dark);
            text-align: left;
            padding-bottom: 5px;
        }

    }
}


.smbtn
{
    .btnweak-bx
    {
        padding: 10px 20px;
        width: 115px;
        font-size: 17px;
        margin-bottom: 30px;
    }
}

.lgbtn
{
    padding-right: 25px;
    .btnweak-bx
    {
        padding: 10px 20px;
        width: 240px;
        font-size: 17px;
        margin-bottom: 30px;
    }
}
.frm-invite
{
    .form-control
    {
        background: rgba(255, 255, 255, 0.5);
    color: #fff;
    border: none;
    padding: 13px 20px;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 500;
    &::placeholder
    {
        color: var(--white);
    }
    &:focus
    {
        background: rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
        color: var(--white);
    }
    }
    
}
.invitefrm 
{
    .btn-week
    {
        .btnweak-bx
        {
            width: 130px;
            font-size: 17px;
            padding: 10px 20px;
        }
    }
}
.earn-innerbox {
    padding-bottom: 40px;
}
.month-serv-list
{
    padding: 0px;
    margin: 0px;
    text-align: left;
    .month-inerlist
    {
        padding: 0px;
        margin: 0px;
        font-size: 14px;
        font-weight: 400;
        color: var(--white);

        .num-highlight
        {
            font-size: 30px;
            font-weight: 700;
            color: var(--yellow-dark);
        }
    }
}
.indicator-card
{
    background-color: var(--white);
    border-radius: 20px;
    padding: 40px 20px;
    margin-bottom: 25px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0,0.15);
    .month-serv-list
    {
        .month-inerlist
        {
            color: var(--grey);
            .num-highlight
            {
                color: var(--purple-dark);
            }
        }
    }
    .medm-subtiel
    {
        font-size: 20px;
        font-weight: 700;
        color: var(--purple-dark);
        padding-bottom: 10px;
    }

}
.earn-section {
    padding-top: 50px;
}

// start media query 

@media only screen and (max-width:1200px){

.withdraw .btnweak-bx
{
    padding: 10px 20px;
}
.earning-top .earning-card ul li
{
    padding: 0px 15px 0 0;
}
.earning-top .earning-card ul li .iner-cardearbg h4
{
    font-size: 27px;
}
.smbtn .btnweak-bx {
    padding: 10px 5px;
    width: 98px;
    font-size: 16px;
}
.lgbtn .btnweak-bx {
    padding: 10px 10px;
    width: 200px;
    font-size: 16px;
    margin-bottom: 30px;
}

}  

@media only screen and (max-width:991px){

    .earning-top .earning-card ul
    {
        text-align: center;
        padding-bottom: 20px;
    }
    .earning-top .earning-card ul li:last-child 
    {
        padding-right: 0px;
    }
    .smbtn .btnweak-bx {
        padding: 10px 5px;
        width: 115px;
        font-size: 16px;
        text-align: center;
    }
    .lgbtn .btnweak-bx {
        padding: 10px 10px;
        width: 200px;
        font-size: 16px;
        margin-bottom: 30px;
    }
    .custm-wdth {
        max-width: 290px;
    }

}

@media only screen and (max-width:767px){
    .profilesection .tab-heading
    {
        font-size: 26px;
        line-height: 30px;
    }
    .profile-list-inner li .profile-linking
    {
        font-size: 16px;
    }
    .switch-listt .listswitch-li .iner-switchlist {
        padding: 0px 0px 0 15px;
    }
    .howearn-main .current-lesson-main .current-lesson-topbr {
        max-width: 100%;
        padding: 0 30px;
    }
    .earn-section
    {
        padding-top: 20px;
    }
    .earn-section .earn-contentbx h3
    {
        font-size: 20px;
        line-height: 28px;
    }
    .custm-wdth
    {
        max-width: 300px;
        margin: 0 auto;
        padding-top: 20px;
    }
    .matrial-earnwdh .one,
    .matrial-earnwdh .two
    {
        width: 50%;
    }
    .matrial-earnwdh
    {
        max-width: 300px;
        margin: 0 auto;
        .custm-wdth
        {
            max-width: 100%;
        }
    }
.frm-invite
{
    padding-bottom: 25px;
}
.indicator-card
{
    margin-bottom: 0px;
    margin-top: 25px;
}    
.myprofile-main.howearn-mainbxx {
    padding-bottom: 20px;
}

}