.newlesson-mainn
{
    .current-lesson-topbr 
    {
        max-width: 900px;
        h1 
        {
            font-size: 26px;
        }
    }
}
.time-newlesn
{
    .newtime-custome
    {
        max-width: 550px;
        margin: 0 auto;
    }
    .lesson-listing-curnt
    {
        padding: 0px;
        margin: 0px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        line-height: 1;

        li 
        {
            padding: 0px;
            margin: 0px;
            list-style: none;
            display: inline-block;
            padding-right: 15px;

            .medm-tex
            {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.04em;
                color: var(--yellow-dark);
            }
        }
    }
}
.newlesson-top
{
    .bnewleson-heading
    {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.04em;
        color: var(--purple-dark);
        font-weight: 700;
        
        padding: 13px 5px;
    }
}
.newlesson-listclk
{
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    line-height: 1;
    li 
    {
        padding: 0px 10px;
        margin: 0px;
        list-style: none;
        .clock-time
        {
            .timenum
            {
                font-size: 62px;
                line-height: 58px;
                font-weight: 700;
                color: var(--white);
            }
            .time-days
            {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: var(--white);
            }

        }
    }
}
.lessontime-bottom
{
    padding: 25px 0 50px 0;

    .lessonnew-cardbx
    {
        position: relative;
        border-radius: 32px;
        z-index: 1;
       
        .newlesson-shape
        {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: -1000;
            perspective: 1000px;
            &:before 
            {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, rgba(255, 255, 255, 0.20) 81%, var(--white) 50% );
                border-radius: 32px;
                transform: rotateX(10deg);
                -webkit-transform: rotateX(10deg);
                -moz-transform: rotateX(10deg);
                transform-origin: 0 0;
                transform-style: preserve-3d;
            }
        }
    }
}
.newlesson-clock {
    padding: 40px 0 10px 0;
}
.btn-list-lesson
{
    transform: translateY(20px);
    .btn-listing-lesson
    {
        padding: 0px;
        margin: 0px;

        li 
        {
            padding: 0px 20px;
            padding-top: 20px;
            margin: 0px;
            list-style: none;
            display: inline-block;
            .btnweak-bx
            {
                transform: perspective(250px) rotateY(-3deg);
                -webkit-transform: perspective(250px) rotateY(-3deg);
                -moz-transform: perspective(250px) rotateY(-3deg);
                font-size: 14px;
                line-height: 21px;
                padding: 10px 20px;
            }
        }
    }
}
.lesson-checknw
{
    padding: 10px 0 10px 0;
    .prev-lesson-chk
    {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-decoration-line: underline;
        font-weight: 700;
        color: var(--yellow-dark);
    }
    
}

.newlesson-mainn
{
    .time-newlesn
    {
        padding-bottom: 60px;
    }
}


@media only screen and (max-width:767px){

    .lessontime-bottom .lessonnew-cardbx .newlesson-shape:before
    {
        transform: rotateX(5deg);
        -webkit-transform: rotateX(5deg);
        -moz-transform: rotateX(5deg); 
        background: linear-gradient(to top, rgba(255, 255, 255, 0.20) 85%, var(--white) 50% );
    }
    .newlesson-listclk li .clock-time .timenum
    {
        font-size: 32px;
        line-height: 38px;
    }
    .newlesson-clock {
        padding: 50px 0 10px 0;
    }
    .newlesson-mainn .time-newlesn {
        padding-bottom: 30px;
    }
}