.gamequiz-main
{
    .back-btndrop
    {
        position: absolute;
        top: 0px;
        left: 0px;
        img 
        {
            width: 40px;
        }
    }
    .right-iconbox-quizz
    {
        position: absolute;
        top: 0px;
        right: 0px;

        ul 
        {
            padding: 0px;
            margin: 0px;
            li 
            {
                display: inline-block;
                padding: 0px 15px 0 0;
                margin: 0px;

                img 
                {
                    width: 25px;
                }
            }
        }
    }
}

.gamequiz-main
{
    .current-lesson-main 
    {
        .current-lesson-topbr
        {
            padding-top: 10px;
            max-width: 1000px;
        }
    }
}
.quiz-gamelession
{
    .lesson-listing-curnt
    {
        display: flex;
        justify-content: space-between;
        max-width: 700px;
        margin: 0 auto;
        flex-wrap: wrap;
    }
}

.quizgame-bodytable
{
    .aswer-details
    {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        .inner-quizgame-cont
        {
            flex: 1;
            align-self: center;
            padding: 10px;
        }
       
    }
    .answer-right
    {
        height: 100%;

    }
    .truefalse-quiz
    {
        .quiz-truebx
        {
            padding: 0px;
            margin: 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            margin: 0px;
            position: relative;
            right: -1px;
            li 
            {
                border-bottom: 1px solid #E2CEFF;
                height: 100%;
                &:last-child
                {
                    border-bottom: none;
                }
                .true-icon-iner
                {
                    height: 100%;
                    display: flex;
                    width: 40px;
                    align-items: center;
                    justify-content: center;
                    background: #f6f6ff;
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--grey-light);
                }
                .active 
                {
                    background-color: var(--orange);
                    color: var(--white);
                }
            }
        }
    }
}
.beginer-box .quizgame-bodytable .two {
    align-self: auto;
}

.questionbox-main 
{
    .quizgame-bodytable
    {
        .answer-right 
        {
            .aswer-details 
            {
                padding: 0px;
            }
        }
    }
  
}

.questionbox-main
{
    .quizgame-bodytable
    {
        .Question-listbx 
        {
            padding: 15px;
        }
    }
    
}
.student-rept-bx
{
    .row
    {
        align-items: center;
    }
    .heading
    {
        h4 
        {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #fff;
            text-align: left;
            margin-bottom: 0px;
            padding-bottom: 10px;
        }
        
    }
    .report-right-content
    {
        text-align: right;
        padding-bottom: 10px;
        p 
        {
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 18px;
            color: var(--white);
            font-weight: 400;
        }
        .downld-btnn
        {
            padding-left: 10px;
            .downlod-btn-nw
            {
                border: none;
                background-color: transparent;
                padding: 0px;
                color: var(--yellow-dark);
                font-size: 18px;
            }
        }
    }
}
.report-student
{
    padding-top: 40px;
}
.quiz-btm-cards
{
    margin-top: 20px;
}
.gamequiz-main{
    padding-bottom: 80px;
}

.send-revision
{
    padding-top: 30px;
    .send-revision-list
    {
        padding: 0px;
        margin: 0px;
        li 
        {
            display: inline-block;
            padding: 0 30px;
            padding-top: 30px;
            .btn-week
            {
                .btnweak-bx
                {
                    padding: 10px 10px;
                    width: 200px;
                    border: none;
                    all: none;
                }
            }

        }
    }
}


// start personal task

.personal-tsk-main
{
    padding-top: 30px;
    .persnl-taskiner
    {
        background: #fff;
        border-radius: 23px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        padding: 60px 20px;

        .task-content
        {
            text-align: left;
            max-width: 1000px;
            margin: 0 auto
            ;
            p 
            {
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 22px;
                font-weight: 400;
                color: var(--purple);
                padding-bottom: 15px;
            }
        }
    }
}
.number-points-frm
{
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 40px;
    .points-num-bx
    {
        .form-control
        {
            height: 52px;
            border-radius: 13px;
            background-color: rgba(106, 100, 230, 0.1);
            border: none;
            padding: 5px 20px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: var(--purple);
        }

       
    }
    .points-iner-nw
    {
        text-align: left;
        h5 
        {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: var(--purple);
            padding-left: 7px;
            margin-bottom: 0px;
            padding-bottom: 10px;
        }
        .btn-row{
            display: flex;
            justify-content: center;
            gap: 40px;
        }
        .save-points{
            width: fit-content;
        }
    }
    .save-points
    {
        .btnweak-bx
        {
            padding: 10px 40px;
            border: none;
            all: none;
        }
    }
}

.mygameflow-quizz
{
    .inner-quizgame-cont
    {
        ul 
        {
            padding: 0px;
            margin: 0px;
            li 
            {
                padding: 0px;
                margin: 0px;
                list-style: none;
                display: inline-block;
                padding-right: 20px;
                .medm-tex
                {
                    margin-bottom: 0px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    color: var(--grey);
                }
                .orng-clr
                {
                    color: var(--orange);
                }
            }
        }
    }
    .gameflw-right-count
    {
         flex: 0 0 auto;
         width: 24px;
         align-items: center;
        display: flex;
        flex-wrap: wrap;
         .countsml-flow
         {
            width: 24px;
            height: 24px;
            background: var(--sky);
            color: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            border-radius: 6px;
         }
    }
}

.mygameflow-quizz
{
    .quizgame-bodytable
    {
        .aswer-details 
        {
            padding: 10px;
            .inner-quizgame-cont 
            {
                padding: 0px;
            }
        }
    }
    .questionbox-main 
    {
        .quizgame-bodytable 
        {
            .Question-listbx
            {
                padding: 10px 15px; 
                .title
                {
                    padding-bottom:0px;
                }
                .question-contwnt
                {
                    .boldtex
                    {
                        font-weight: 600;
                    }
                }
            }
            .answer-right
            {
                .aswer-details 
                {
                    padding: 10px 15px;
                    padding-left: 0px;
                }
            }
        }
    }
    
}
.gameflw-link
{
    text-decoration: none;
}

.mygameflow-quizz
{
    .quizgame-bodytable
    {
        .gameflw-link
        {
            &:hover 
            {
                .left-quedtions
                {
                    background-color: transparent;
                }
                .innergame-flwbg
                {
                    background-color: rgba(101, 57, 161, 0.11);
                }
            }
        }
    }

}

.aswer-details
{
    .number-points-frm 
    {
        padding-top: 10px;
        padding-bottom: 20px;
        .row{
            align-items: center;
        } 
    }
}   

@media only screen and (min-width:767px){

    .aswer-details .number-points-frm h5 
    {
        padding-bottom: 0px;
        padding-left: 0px;
    }

}