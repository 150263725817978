.dashboard-bgnew
{
  
    background: linear-gradient( -143.54deg, #AC88DE -20.63%, #8548DA -17%, #4C85F4 69.57%, #556BBB 102.97%, #CC0F97 137.84%);
box-shadow: 0px -7px 59px rgba(0, 0, 0, 0.25);
}
.min-h-screen-fill
{
    min-height: calc(100vh - 80px);
}
.games-dashboard-main
{
    padding-top: 100px;
    margin-top: -60px;
}
.breadcrumb-new
{
    .breadcrumb-item
    {
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
        color: var(--white);
        padding-left: 5px !important;
        a 
        {
            color: var(--white);
            text-decoration: none; 
            &:hover 
            {
                color: var(--yellow-dark);
            }
        }
        &:before 
        {
            color: var(--white) !important;
            font-size: 9px;
            padding-right: 4px !important;
        }
    }
    .active 
    {
        color: var(--white) !important;
    }
}
.games-cards-diver
{
    .games-cardnew
    {
        
    background: rgba(255, 255, 255, 0.20);
    border-radius: 32px;
    padding: 20px;
        .cards-games-pic
        {
            position: relative;
            max-width: 150px;
            margin: 0 auto;
            z-index: 0;

            .game-pic
            {
                perspective: 500px;
                 img 
                 {
                    padding: 10px;
                    border-radius: 18px;
                    transform: rotateX(-13deg) rotateY(3deg);
                    -webkit-transform: rotateX(-13deg) rotateY(3deg);
                    -moz-transform: rotateX(-13deg) rotateY(3deg);
                 }
            }
            .shape-gamesnew
            {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                width: 100%;
                height: 100%;
                perspective: 1000px;
                z-index: -1000;

                &:before
                {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    transform: rotateY(  10deg) rotateX( -10deg);
                    -webkit-transform: rotateY(  10deg) rotateX(  -10deg);
                    -moz-transform: rotateY(10deg) rotateX(-10deg);
                    background-color: #fff;
                    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30);
                    border-radius: 18px;
                }

            }
        }
        .games-content-box
            {
                padding-top: 20px;
                max-width: 200px;
                margin: 0 auto;
                h4 
                 {
                    font-size: 20px;
                    line-height: 22px;
                    text-align: center;
                    font-weight: 700;
                    color: var(--white);
                    margin-bottom: 0px;
                    padding-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 64px;
                 }   
                 p 
                 {
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--white);
                    margin-bottom: 0px;
                    font-weight: 400;
                    padding-bottom: 15px;
                 }

            }
    }

    .game-outerbx-poup
    {
        text-decoration: none;
    }

    .one 
    {
        margin-bottom: 24px;
    }

}

// .no_game_found{
//     height: 80%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.diversity-box
{
    padding-bottom: 50px;
    .title-box-diver
    {
        text-align: left;
        font-size: 44px;
        font-weight: 700;
        line-height: 48px;
        color: var(--white);
        padding-bottom: 50px;
    }
}

.games-cards-diver .one:nth-child(even) .games-cardnew .cards-games-pic .shape-gamesnew:before
{
    transform: rotateY(-10deg) rotateX(7deg);
    -webkit-transform: rotateY(-10deg) rotateX(7deg);
    -moz-transform: rotateY(-10deg) rotateX(7deg);
}
.games-cards-diver .one:nth-child(even) .games-cardnew .cards-games-pic .game-pic img
{
    transform: rotateX( 2deg) rotateY( -3deg);
    -webkit-transform: rotateX( 2deg) rotateY( -3deg);
    -moz-transform: rotateX( 2deg) rotateY( -3deg);
}