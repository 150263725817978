.Message-chat-room
{
    .chat-upperbox 
{
    .chatbox-inner 
    {
        .message-box 
        {
            .message-tex 
            {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
.chatbox-room
{
    padding: 0px;
    border-top: 3px solid #E2CEFF;
    padding-top: 10px;
    border-radius: 0px;
    background: transparent;
    .chat-innerbox
    {
        height: calc(600px - 200px);
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

}
    .chat-footer
    {
        padding-top: 20px;
        .chat-footer-main
        {
            border-top: 3px solid #E2CEFF;
        }
    }

    .chat-footer-message 
    {
        padding-top: 0px;
        .message-send-new 
        {
            .message-textarea 
            {
                border-radius: 0px;
                background-color: transparent;
                color: #333;
                font-size: 14px;
                line-height: 28px;
                padding-left: 5px;

                &::placeholder 
                {
                    color: #BDBDBD;
                }

            }
            .right-chaticon
            {
                right: 0px;
                top: 30px;
            }
        }
    }

}
.Message-chat-room
{
    .chat-sender 
    {
        .message-box 
        {
            padding: 20px;
            border-radius: 10px;
        }
        .message-time
        {
            padding-right: 20px;
            .time
            {
                font-size: 13px;
                line-height: 20px;
                color: #BDBDBD;
            }
            
        }
    }
    .chat-receiver
        {
            .message-box {
                background-color: #ededff;
                color: #333;
                padding: 20px;
                border-radius: 10px;
                text-align: left;
                
            }
            .message-time
            {
                padding-left: 20px;
                .time
                {
                    font-size: 13px;
                    line-height: 20px;
                    color: #BDBDBD;
                }
                
            }
        }
 

    .message-rom-iner
    {
        display:flex;
    }
    .message-time
    {
        align-self: center;
    }

}
.my-messagerm-chat
{
    .myactivites-contain 
    {
        .activities-list-left
        {
            max-height: 440px;
        }
    }
}

.game-opn
{
    padding-top: 20px;
    .gameopn-btn
    {
        padding: 5px 20px;
            font-size: 14px;
    }
}

input:focus, textarea:focus
{
    box-shadow: none !important;
    outline: none !important;
}

@media only screen and (max-width:767px){

    .Message-chat-room .chat-upperbox .chatbox-inner .message-box .message-tex {
        font-size: 12px;
        line-height: 20px;
    }
    .Message-chat-room .chat-sender .message-box {
        padding: 10px;
    }
    .Message-chat-room .chat-sender .message-time {
        padding-right: 10px;
    }
    .chat-upperbox .chatbox-inner {
        flex: 0 0 95%;
        max-width: 95%;
        padding-bottom: 12px;
    }
    .Message-chat-room .chat-receiver .message-box
    {
        padding: 10px;
    }
    .Message-chat-room .chat-receiver .message-time
    {
        padding-left: 10px;
    }
    .Message-chat-room .chatbox-room .chat-innerbox
    {
        height: calc(500px - 200px);
    }

}