.profile-topbar{
    background: url(../assets/images/profiletop.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: drop-shadow(0px 19px 56px rgba(0, 0, 0, 0.25));
    position: relative;
    z-index: 99;
    padding-bottom: 45px;

    .navbar-top{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }



}

.subdesc{
    p{
        color: white;
        span{
            color: #ffd30f;
        }
    }
}
.profilesection {
    background: linear-gradient(147.04deg, #5081F2 27.21%, #7658DF 81.6%);
    box-shadow: 0px -7px 59px rgba(0, 0, 0, 0.2);
    padding: 100px 0 100px 0;
    margin-top: -40px;

    .profile-pic {
        max-width: 130px;
        margin: 0 auto 25px;
        position: relative;

        .profile-image {
            border: 4px solid #fff;
            border-radius: 50%;
            max-width: 130px;
            height: 130px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        }

    }

    .profile-tabs{
        position: relative;
        max-width: 380px;
        padding: 30px 30px;
        margin: 0 auto;
        // &:before {
        //     content: "";
        //     position: absolute;
        //     top: 20%;
        //     left: 0;
        //     right: 0px;
        //     width: 100%;
        //     height: 80%;
        //     box-shadow: 0px 8px 49px rgba(0, 0, 0, 0.2);
        //     border-radius: 25px;
        //     transform: perspective(100px) rotateY(-1deg) rotateX(0deg);
        //     background: #fff;
        //     z-index: 0;
        //     transition: all 300ms ease-in-out;
        // }
           .profileshape-bg
           {
               position: absolute;
               top: 0px;
               left: 0px;
               right: 0px;
               width: 100%;
               height: 100%;
               z-index: 0;
               perspective: 1000px;
               transform: rotate(180deg);
               -webkit-transform: rotate(180deg);
               -moz-transform: rotate(180deg);


               &:before 
               {
                content: "";
                position: absolute;
                top: 0%;
                left: 0;
                right: 0px;
                width: 100%;
                height: 80%;
                box-shadow: 0px 8px 49px rgba(0, 0, 0, 0.2);
                border-radius: 25px;
                transform: rotateY(10deg);
                -webkit-transform: rotateY(10deg);
                -moz-transform: rotateY(10deg);
                transform-style: preserve-3d;
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
                background: #fff;
                z-index: 0;
                transition: all 300ms ease-in-out;
               }

           } 

        .react-tabs__tab-list {
            border-bottom: unset;
            margin: 0;
            padding: 0;
        }
        .react-tabs__tab{
            width: 100%;
            bottom: 0;
            padding: 10px 0;
            font-size: 18px;
        }

        .react-tabs__tab--selected {
            background: #fff;
            border-color: transparent;
            color: black;
            border-radius: 0;
            font-weight: 700;
        }


    }
    
    .tab-heading {
        font-weight: bold;
        font-size: 34px;
        line-height: 39px;
        color: #fff;
        margin: 0 0 40px 0;
    }

    .profile-form-section{
        ul{
            margin: 0;
            padding:0;
        }
        li{
            list-style: none;
            margin: 0 0 20px 0;
            label{
                font-size: 14px;
                line-height: 20px;
                color: #FFF;
                text-align: left;
                width: 100%;
                padding-left: 20px;
                margin: 0 0 8px 0;
            }

            .form-control {
                background: rgba(255,255,255,0.5);
                color: #fff;
                border: none;
                padding: 20px 20px;
                border-radius: 18px;
                font-size: 16px;
                font-weight: 500;

                &:focus{
                    box-shadow: unset;
                }

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #fff;
                    opacity: 1; /* Firefox */
                  }
                  
                  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #fff;
                  }
                  
                  &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #fff;
                  }
            }
            select.form-control{
                background-image: url(../assets/images/arrow-down.png);
                background-size: 10px;
                background-repeat: no-repeat;
                background-position: 96% 30px;

                option
                {
                    background: #adbbf7;
                }
                

            }

            .btn-week {
                text-align: left;
            }

            &:last-child{
                margin: 60px 0 0 0;
            }
        }

    }

}

.social-media {
    max-width: 220px;
    margin: 0 auto;
    ul{
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }
    li {
        list-style: none;
    }
    a {
        width: 42px;
        height: 42px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #4FE7E7;
        border-radius: 50%;
        margin: 0 8px;
        img{
            width: 24px;
        }
        &:hover{
            background: #3cdfdf;
        }

    }
}

.pro-menubar{
    width: 100%;
    flex: 0 0 100%;
    justify-content: space-between !important;
}

.count-bar {
    min-width: 125px;
    background: #fff;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-weight: 600;
    font-size: 18px;
    min-height: 50px;
}

.lft-menu{
    .navbar-brand {
        margin: 0 30px 0 30px;
    }
    .btn-bx-get{
        padding-right: 0 !important;
    }    
    li {
        margin-right: 10px;
    }
}
.profile-menubar{
    display: inline-flex;
    align-items: center;
    
    .btn-bx-get {
        padding-left: 0;
        padding-right: 10px;
    }

    .gift-ico {
        width: 50px;
        height: 50px;
        background: #ffd300;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
    }

    .bell-ico {
        position: relative;
        margin: 0 40px 0 25px;
        span {
            position: absolute;
            top: -8px;
            right: -5px;
            width: 20px;
            height: 20px;
            color: #fff;
            font-size: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FC47C7;
            border: 2px solid #577bf0;
        }
    }

}

.phone-bar {
    display: flex;
    width: 100%;
    flex: 0 0 100%;
    background: rgba(255,255,255,0.5);
    border-radius: 18px;
    padding: 16px 20px;

    .country-cd {
        flex: 0 0 85px;
        max-width: 85px;
        background: #fff;
        border-radius: 8px;
        text-align: left;
        padding: 2px 15px;
    }
    .number-fld {
        flex: 1;
       padding: 0 0 0 20px;

       .form-control {
        background: transparent !important;
        padding: 4px 0 !important;
    }

    }

}


