.inner-page-topbar
{
    background: url(../../assets/images/headerbar.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: drop-shadow(0px 19px 56px rgba(0, 0, 0, 0.25));
    position: relative;
    z-index: 99;
    padding-bottom: 45px;
}
.blogsection
{
    background: linear-gradient(
        147.04deg, #5081F2 27.21%, #7658DF 81.6%);
            box-shadow: 0px -7px 59px rgba(0, 0, 0, 0.25);
            padding: 100px 0 100px 0;
            margin-top: -40px;
}
.serchfiled
{
    position: relative;
    z-index: 1; 
    height: 70px;
    perspective: 1000px;

    .formbox
    {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #fff;
        background-color: transparent;
        border: none;
        height: 60px;
        padding: 10px 50px 10px 30px;

        &:focus 
        {
            color: #fff;
        background-color: transparent;
        box-shadow: none;
        }
        &::placeholder
        {
            color: #fff;
        }

    }

    &:before 
    {
        content: "";
        position: absolute;
        top: -4px;
        left: 0px;
        width: 100%;
        height: 100%;
        // transform: perspective(1000px) rotateX(-24deg) rotateY( -1deg);
        transform: rotateX(-15deg);
        -webkit-transform: rotateX(-15deg);
        -moz-transform: rotateX(-15deg);
        background: #FFFFFF;
        opacity: 0.5;
        border-radius: 19px;
        z-index: -1;
    }

    .seachbtn
    {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 50px;
        height: 65px;
        font-size: 20px;
        background: none;
        border: none;
        &:hover 
        {
            background: none;
            border: none;
        }
        &:focus 
        {
            background: none;
            border: none;
            box-shadow: none;
            outline: none;
            border-color: transparent;
        }
    }
}
.tagsbox-list
{
    padding: 50px 0 40px 0;
    ul 
    {
        padding: 0px;
        margin: 0px;
        text-align: left;

        li 
        {
            padding: 0px;
            margin: 0px;
            list-style: none;
            display: inline-block;
            padding-right: 12px; 
            padding-bottom: 10px;
            

            
        }
    }
}
.tagbtnn
{
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: var(--purple);
    background: var(--yellow-dark);
    padding: 5px 20px;
    border-radius: 8px;
    text-decoration: none;
    max-width: 100px;
    width: 100%;
    display: block;
    &:hover 
    {
        color: var(--purple);
    }
}
.blog-card-right
{
    padding-right: 20px;
.content-card-inner
{
    text-align: left;
    .title-main
    {
        font-weight: 700;
        font-size: 34px;
        line-height: 39px;
        letter-spacing: 0.04em;
        color: var(--purple);
        padding-bottom: 25px;
        margin: 0px;
    }
    .small-tex
    {
        margin: 0px;
        padding-bottom: 20px;
        color: var(--purple);
    }
    .blog-date
    {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #A8A8A8;
    }
}
}

.card-blogmain
{
    position: relative;
    z-index: 9;
    padding: 20px 20px 35px 20px;
    margin-bottom: 40px;
    &:before 
    {
        content: "";
        position: absolute;
        top: 0px;
        left: -20px;
        width: 100%;
        height: 100%;
        transform: perspective(1000px) rotateY(-3deg);
        -webkit-transform: perspective(1000px) rotateY(0deg);
         background: #FFFFFF;
        border-radius: 30px;
        z-index: -1000;
        transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        display: block;
    }
    .blog-leftpic
    {
        .picard
        {
            img 
            {
            border-radius: 25px;
            overflow: hidden;
            border: 9px solid #fff;
            transform:rotateY(8deg); 
            -webkit-transform:rotateY(8deg);
            -moz-transform:rotateY(8deg);
            transform-style: preserve-3d;
            filter: drop-shadow(0px 19px 36px rgba(0, 0, 0, 0.25));
            transform-origin: 0% 0%;
            display: block;
            }
            
        }
    }

    .picard
    {
        perspective: 1000px;

    }

}
.align-center
{
    align-items: center;
}
.pagination-blog
{
    ul 
    {
        padding: 0px;
        margin: 0px;

        li 
        {
            padding: 0px;
        margin: 0px;
        list-style: none;
        display: inline-block;
        padding: 5px;
          
        a 
        {
            width: 47px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.20);
            box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30);
            border-radius: 9px;
            transform: perspective(100px) rotateY(-10deg);
            color: #fff;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            text-decoration: none;
        }
        .active 
        {
            box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3);
            background: var(--yellow);
            text-align: center;
            color: var(--purple);
        }
        &:nth-child(even)
        {
           a 
        {
            transform: perspective(100px) rotateY(10deg); 
        }
        }

        }
    }
}

.pagination-blog
{
    padding-top: 40px;
}

.subs-form
{
    .custom-row
    {
        display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 100%;

    .leftfiel-subs
    {
        flex: 1;
        padding-right: 30px;
    }
    .right-subbtn
    {
        flex: 0 0 170px;
        max-width: 170px;
        .btn-buy
        {
            padding-top: 0px;
            transform: none;
        }
    }

    }
}

.subscribe-bxx
{
    .communty-box
    {
        padding: 50px 30px 50px 30px;
    }

    .subsform-fild
    {
        position: relative;
        z-index: 9;

        &:before 
        {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            box-shadow: 0px 4px 10px rgba(0, 0,0, 0.15);
            border-radius: 14px;
            transform: perspective(1000px) rotateY(0deg) rotateX(-14deg);
            -webkit-transform: perspective(1000px) rotateY(0deg) rotateX(-14deg);
            display: block;
            z-index: -1000;
            background: #fff;
            transform-origin: 0% 0%;
            -webkit-transform-origin: 0% 0%;

        }
        .form-control
        {
            background: transparent;
            border: none;
            height: 62px;
            padding: 10px 20px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.04em;
            color: #C4C4C4;

            &::placeholder
            {
                color: #C4C4C4;
            }
            &:focus 
            {
                outline: none;
                box-shadow: none;
                border: none;
            }
        }
    }
    .communty-section 
    {
        p 
        {
            max-width: 400px;
    margin: 0 auto;
    padding-bottom: 40px;
        }
    }
}

.subs-blog {
    padding: 120px 0 60px 0;
}
.subscribe-blog
{
    &:before 
    {
        display: none;
    }
}