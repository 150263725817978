.instruction-section
{
    .contain-custm
    {
        max-width: 954px;
        margin: 0 auto;
        padding: 0 12px;
    }
}

.instruction-slider
{
    background: var(--white);
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin-bottom: 60px;
    .custom-row
    {
        display: flex;
        flex-wrap: wrap;
        line-height: 1;

        .leftbox-inst
        {
            flex:0 0 50%;
            max-width: 50%;
            position: relative;
        }
        .rightbox-inst
        {
            flex:0 0 50%;
            max-width: 50%;
            .btn-game
            {
                margin-top: -20px;
            }
        }
    }
    .slick-next, .slick-prev
    {
        display: none !important;
    }

    .quiz-card
    {
        text-align: left;
        padding: 0 50px;
        height: 100%;
        display: flex;
        align-items: center;
        .quizcard-content
        {
            h1 
            {
                font-size: 32px;
                line-height: 35px;
                letter-spacing: 0.04em;
                font-weight: 700;
                color: var(--purple-dark);
                margin-bottom: 0px;
            }

            .content-detailquiz
            {
                max-width: 280px;
                padding-bottom: 40px;
                p 
                {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.04em;
                    font-weight: 400;
                    color:var(--black);
                    margin-bottom: 0px;
                    padding-top: 20px;
                }
            }
        }
    }

}

.instruction-slider
{
    .slider-innercard
    {
        border-radius: 15px 0 0px 15px;
        img 
        {
            max-width: 100%;
            border-radius: 15px 0 0px 15px;
            width: 100%;
        }
    }
    .slick-dots
    {
        bottom: 20px;
       li 
       {
           width: 10px;
           height: 10px;
           transform: scale(0.8);
           -webkit-transform: scale(0.8);
           -moz-transform: scale(0.8);
          button 
          {
            width: 10px;
            height: 10px;
            &:before 
            {
                width: 100%;
                height: 100%;
                color: transparent;
                background-color: var(--white);
                border-radius: 50%;
            }
          }
       }
       .slick-active
       {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        button 
        {
            &:before 
            {
                background-color: var(--orange);
            }
        }
       }
    }
}

.btn-game
{
    margin-top: -20px;
    text-align: left;
    padding-left: 50px;

    .btnweak-bx
    {
        font-size: 14px;
        line-height: 21px;
        padding: 13px 20px;
    }
}

@media only screen and (max-width:575px){

    .instruction-slider .custom-row .leftbox-inst {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
    }
    .instruction-slider .custom-row .rightbox-inst {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .instruction-slider .quiz-card
    {
        padding: 0 20px;
    }
    .instruction-slider .custom-row .rightbox-inst .btn-game
    {
        padding-left: 20px;
    }

}