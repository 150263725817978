.youtube-url
{
    .one
    {
        width: calc(100% - 220px);
    }
    .two
    {
        width: 220px;
        text-align: right;
        padding-right: 30px;

        .btn-week
        {
            .btnweak-bx
            {
                font-size: 16px;
                line-height: 23px;
                padding: 13px 50px;
            }
        }
    }
}

.drag-file
{
    padding-bottom: 20px;
    .dragfile-box
    {
        border-radius: 13px;
        background-color: rgba(106, 100, 230, 0.1);
        padding: 40px 20px;
        h4 
        {
            font-size: 26px;
            line-height: 35px;
            letter-spacing: 0.04em;
            font-weight: 700;
            color: var(--purple-dark);
            margin-bottom: 0px;
            padding-bottom: 10px;
        }
        #uploadfile-drag
        {
            display: none;
        }
        .choosefile-now
        {
            padding-right: 25px;
            margin: 20px 0 50px 0px;
            .btnweak-bx
            {
                padding: 10px 40px;
                cursor: pointer;
                font-size: 16px;
            }
        }
    }
}
.drive-icon
{
    ul 
    {
        padding: 0px;
        margin: 0px;

        li 
        {
            display: inline-block;
            padding: 0 5px;

            .drive-icon-list
            {
                font-size: 18px;
                color: #ffffff;
                width: 40px;
                height: 40px;
                background: #ff7a00;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.material-video-btn
{
    ul 
    {
        li 
        {
            .gamestart-btnn 
            {
                .btnweak-bx
                {
                    font-size: 16px;
                    line-height: 27px;
                    padding: 13px 10px;
                    width: 330px;
                    transform: perspective(230px) rotateY(-3deg);
                    -moz-transform: perspective(230px) rotateY(-3deg);
                    -webkit-transform: perspective(230px) rotateY(-3deg);
                }
            }
        }
    }
}