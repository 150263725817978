.flownew-gamesbx
{
    padding-bottom: 50px;
    .materialBox{
        max-width: 1210px !important;
    }
    .container 
    {
        max-width: 1130px !important;
    }
  
    .Minigames-bx
    {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 23px;
        background-color: rgba(255, 255, 255, 0.50);
        padding: 35px;
        position: relative;
       
        .minigames-cards
        {
            padding-bottom: 0px;
        }

    }
    .games-topserch-bar
    {
        padding-top: 30px;
    }
    .heading-top-flowgames
    {
        h4 
        {
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
            color: var(--purple-dark);
            text-align: left;
            margin: 0px;
        }
    }
    .game-opn-create
    {
        .gamestbs-btn
        {
            padding: 10px 16px;
            font-size: 16px;
        }
    }

}
.materialBox{
    .container{
    max-width: 1210px !important;
}
}

.gameflow-check .checkmark
{
    top: 0px;
    left: 0px;
    right: auto;
    height: 28px;
    width: 28px;
}
.gameflow-check .checkmark:after
{
    left: 11px;
    top: 7px;
}

.gamesflw-cardchek
{
    max-height: 590px;
    overflow-y: auto;
    padding-right: 13px;
    padding-top: 10px;

    .quiz-body-content
    {
        padding-top: 30px;
    }
}

.close-flowgame
{
    .btnclose-flowgm
    {
            font-size: 35px;
            line-height: 35px;
            color: var(--orange);
            text-decoration: none;
            position: absolute;
            top: 10px;
            right: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 25px;
        
    }
}

.mymateial-main
{
    .gamesflw-cardchek 
    {
        .quiz-body-content
        {
            padding-top: 45px;
        }
    }
    .inner-cardquizz
    {
        padding: 15px 15px 20px 15px;
    }
}
.flownew-gamesbx.videomatrials-add
{
    .container
    {
        max-width: 1200px !important;
    }
}


// start media query

@media only screen and (max-width:767px){

    .flownew-gamesbx .Minigames-bx
    {
        padding: 35px 20px;
    }

}