.allfile-section
{
    .contain-custm
    {
        max-width: 1040px;
        margin: 0 auto;
        padding: 0 12px;
    }
    .myactivites-contain
    {
        padding: 25px;
        box-shadow: none;
    }
}
ul 
{
    padding: 0px;
    margin: 0px;
    li 
    {
        padding: 0px;
        margin: 0px;
        list-style: none;
    }
}
.allfiles-tabsnew-boxx
{
    .tabslist-activiti
    {
        padding: 0px;
        margin: 0px;
        text-align: left;
        .tabslist-activiti-tab
        {
            display: inline-block;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            letter-spacing: 0.04em;
            color: var(--purple-dark);
            padding: 0 15px 10px 15px;
            position: relative;
            .total-numfiles
            {
                color: var(--orange);
            }
            .allfilesmain-bx
            {
                cursor: pointer;
            }

          

        }
        .react-tabs__tab--selected
        {
            &:before 
            {
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0px;
                width:100%;
                height: 4px;
                background-color: var(--orange);
            }
        }
    }
    .allfiles-tabsbox
    {
        padding-left: 12px;
        position: relative;
        .close-iconbtn
            {
                position: absolute;
                top: 0px;
                right: -10px;

                .btnclose-glry
                {
                    border: none;
                    box-shadow: none;
                    outline: none;
                    background-color:transparent;
                    font-size: 28px;
                    width: 30px;
                    height: 30px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--orange);
                }
            }
    }
    
}
.all-files-gallery
{
    .one
    {
        margin-bottom: 24px;
    }
    .innergalery-file
    {
        border-radius: 6px;
        overflow: hidden;
        img 
        {
            border-radius: 6px;
        }
    }
    .myacti-listtabs
    {
        padding: 0 12px;
    }
}

.allfiles-bottombx
{
    padding-top: 12px;
   
    .all-files-gallery
    {
        max-height: 780px;
        overflow-y: auto;
        padding-right: 12px;
    }
 
}

@media only screen and (max-width:767px){

    .allfile-section .myactivites-contain {
        padding: 15px 10px;
     
    }
    .allfiles-tabsnew-boxx .allfiles-tabsbox .close-iconbtn .btnclose-glry
    {
        font-size: 24px;
    }
    .allfiles-tabsnew-boxx .allfiles-tabsbox .close-iconbtn
    {
        right: -5px;
    }
    .allfiles-tabsnew-boxx .allfiles-tabsbox
    {
        padding-right: 25px;
    }
    .allfiles-bottombx .all-files-gallery
    {
        padding-right: 0px;
        max-height: 550px;
    }
    .allfiles-tabsnew-boxx .tabslist-activiti .tabslist-activiti-tab
    {
        padding: 5px 10px 5px 10px;
    }
}