.loginmodal {
  .modal-dialog {
    max-width: 500px;
    .modal-content {
      background-color: transparent;
      box-shadow: none;
      border: none;
    }
  }

  .login-modal-mainbx {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    background: #fff;
    padding: 0px 60px;
  }
  .modal-title {
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;

    h4 {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: var(--purple-dark);
      margin-bottom: 0px;
      padding-bottom: 8px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #333;
      margin-bottom: 0px;
    }
  }
  .modal-header {
    flex-wrap: wrap;
    border: none;
    padding: 50px 0 20px 0;
  }
  .modal-body {
    padding: 0px;
  }
  .loginform-bx {
    .subsform-fild {
      padding-bottom: 20px;
      .form-label {
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
        color: #000;
        padding-left: 20px;
      }

      .form-control {
        height: 52px;
        border-radius: 13px;
        background-color: rgba(106, 100, 230, 0.1);
        border: none;
        padding: 5px 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--purple);

        &::placeholder {
          color: #bdbdbd;
        }
      }

      .forg-texfield {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .forget-tex {
          padding: 0 10px;
        }
      }
    }
    .title-med {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: var(--orange);
    }
    .have-account {
      text-align: center;
      padding-bottom: 20px;
    }
  }
  .btnlogin-main {
    text-align: center;
    margin-bottom: -25px;
    .btnlogin {
      padding: 10px 60px;
      border: none;
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0px;
    border: none;
    justify-content: center;
    background-color: transparent;
    overflow: hidden;
    font-size: 35px;
    line-height: 35px;
    color: var(--orange);
  }

  .socila-mediabx {
    padding-top: 60px;

    .social-media-detail {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 18px;
      background: #fff;
      padding: 20px;
      text-align: center;

      h5 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #333;
        margin-bottom: 0px;
        padding-bottom: 15px;
      }
    }
    .social-media {
      ul {
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.modal-open {
  #root {
    -webkit-filter: blur(3px);
    -ms-filter: blur(3px);
    -o-filter: blur(3px);
    filter: blur(3px);
  }
}
// start games flow popup

.gamesflow-popup {
  .modal-dialog {
    max-width: 290px;
    margin: 0 auto;
  }
  .modal-title {
    h4 {
      color: var(--purple-dark);
    }
  }
  .gameflow-popubox {
    padding: 0px 20px;

    .inner-detail-flow {
      padding-bottom: 30px;
      h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: var(--purple-dark);
        margin-bottom: 0px;
        padding-bottom: 10px;
      }
      .list-flowdetail-game {
        padding: 0px 10px 0 30px;
        margin: 0px;
        li {
          padding: 0px;
          margin: 0px;
          list-style: decimal;
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
          padding-bottom: 5px;
          color: var(--black);
        }
      }
    }
  }
  .modal-header {
    h5 {
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      max-width: 180px;
      margin: 0 auto;
      color: var(--black);
      padding-top: 10px;
    }
  }
}

// End ganes flow popup

// sign up start

.signup-popup-list {
  .signup-cardbx {
    background: var(--purple);
    border-radius: 13px;
    color: var(--white);
    padding: 25px 20px;
    margin-bottom: 12px;
    .custom-row {
      display: flex;
      flex-wrap: wrap;
      line-height: 1;
      align-items: center;

      .left-bx {
        flex: 0 0 45%;
        max-width: 45%;
        padding-right: 10px;
      }
      .right-content-signup {
        flex: 0 0 55%;
        max-width: 55%;

        p {
          font-size: 12px;
          line-height: 17px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
    .card-signup-new {
      .title-sml {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: var(--yellow-dark);
      }
    }
  }
  .signup-student {
    display: block;
    text-decoration: none;
  }
}
.have-account {
  text-align: center;
  padding-bottom: 20px;
  .title-med {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: var(--orange);
  }
}
.signup-box {
  .modal-title {
    p {
      padding-bottom: 20px;
    }
  }
}

.count-barr {
  .counting-barful {
    .linebar-tex {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: var(--orange);
      text-align: center;
    }
    .linebar-box {
      position: relative;
      background: var(--white);
      border-radius: 3px;
      max-width: 230px;
      width: 100%;
      margin: 0 auto;
      height: 4px;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 20%;
        height: 4px;
        background-color: #4fe7e7;
        border-radius: 3px;
      }
    }
  }
}
.parent-login {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 16.66%;
      }
    }
  }
}

.student-login-main {
  .modal-title {
    h4 {
      color: var(--purple-dark);
    }
  }
}

.btnback {
  .backbtn-icn {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0px;
    border: none;
    justify-content: center;
    background-color: transparent;
    overflow: hidden;
    font-size: 20px;
    line-height: 1;
    color: var(--orange);
    text-decoration: none;
  }
}

// end sign up

// start student sign up step2

.loginmodal {
  .subsform-fild {
    select {
      background: url(../../assets/images/down-arrow-black.svg);
      background-repeat: no-repeat;
      background-position: 96% 50%;
      background-size: 11px;
    }
  }
}

.std-count-step2 {
  .counting-barful {
    .linebar-box {
      &::before {
        width: 20%;
      }
    }
  }
}

.phone-bar-form {
  display: flex;
  align-items: center;
  line-height: 1;
  .country-cd {
    flex: 0 0 80px;
    max-width: 80px;
    border-radius: 13px 0px 0px 13px;
    height: 52px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    background: rgba(106, 100, 230, 0.1)
      url(../../assets/images/down-arrow-black.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%;
    background-size: 11px;

    img {
      max-width: 29px;
    }
  }
  .number-fld {
    flex: 1;
    padding-left: 2px;
    .subsform-fild {
      padding-bottom: 0px;
    }
  }

  .subsform-fild {
    .form-control {
      border-radius: 0px 13px 13px 0px !important;
    }
  }
}

.student-signup-step4 {
  .btnlogin-main {
    .btnlogin {
      padding: 10px 18px;
    }
  }
}

.student-signup-step5 {
  .phone-bar-form {
    .number-fld {
      position: relative;
      .sendagain-btn {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;

        a {
          color: var(--purple);
        }
      }
    }
    .subsform-fild {
      .form-control {
        border-radius: 0px 13px 13px 0px;
        padding: 5px 80px 5px 20px;
      }
    }
  }
  .btn-checkk {
    text-align: center;
    padding-bottom: 30px;

    .btnweak-bx {
      padding: 10px 60px;
    }
  }
}
.btn-checkk {
  .disable {
    background: radial-gradient(
        89.66% 75.31% at 55.08% 86.94%,
        rgba(214, 214, 214, 0.55) 0%,
        rgba(0, 0, 0, 0) 86.18%
      ),
      radial-gradient(
        63.69% 56.22% at 25.75% 17.94%,
        rgba(255, 255, 255, 0.413) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      ),
      #ebebeb;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    pointer-events: none;

    &:before {
      background: linear-gradient(
        181.68deg,
        #ffffff -2.86%,
        rgba(255, 255, 255, 0) 56.7%
      );
      opacity: 0.2;
      box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3);
    }
  }
}

.student-signup-step6 {
  .btnlogin-main {
    .btnlogin {
      padding: 10px 25px;
    }
  }
}

.std-count-step6 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 100%;
      }
    }
  }
}
.std-count-step5 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 83.33%;
      }
    }
  }
}
.std-count-step4 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 66.66%;
      }
    }
  }
}
.std-count-step3 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 50%;
      }
    }
  }
}
.std-count-step2 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 33.33%;
      }
    }
  }
}

// end student sign up step2

// start parent sign up

.mychild-frm {
  .activ-detail-list {
    .active-detail-row {
      .activiti-prof-icon {
        flex: 0 0 70px;
        max-width: 70px;
        .profile-icon-box {
          width: 70px;
          height: 70px;
          font-size: 28px;
        }
      }
      .activiti-center-detail {
        h5 {
          font-size: 18px;
          color: var(--purple-dark);
        }
        h6 {
          font-size: 14px;
        }
      }
    }
  }
}
.mychild-frm {
  .child-account {
    border-bottom: 3px solid #e2ceff;
  }
  .loginform-bx {
    .child-tex-fld {
      .form-label {
        font-size: 14px;
      }
    }
  }
  .child-boxnew {
    border-top: 3px solid #e2ceff;
  }
  .activ-detail-list {
    padding: 15px 15px;
  }
}

.parent-signup-step2 {
  .btnlogin-main {
    .btnlogin {
      padding: 10px 20px;
      transform: perspective(300px) rotateY(-3deg);
      -webkit-transform: perspective(300px) rotateY(-3deg);
      -mox-transform: perspective(300px) rotateY(-3deg);
      margin-right: 10px;
    }
  }
  .modal-dialog {
    max-width: 560px;
  }
}

.mychild-frm {
  .count-num-activiti {
    border: none;
  }
}
.parent-signup-step6 {
  .btnlogin-main {
    .btnlogin {
      padding: 10px 30px;
    }
  }
}
.parent-signup-step5 {
  .btn-checkk {
    .btnlogin {
      padding: 10px 60px;
    }
  }
}
.parent-signup-step1 {
  .btnlogin-main {
    .btnlogin {
      padding: 10px 30px;
    }
  }
}

.parent-count-step2 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 40%;
      }
    }
  }
}

.parent-count-step3 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 60%;
      }
    }
  }
}
.parent-count-step4 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 80%;
      }
    }
  }
}

.parent-count-step5 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 100%;
      }
    }
  }
}
// end parent sign up

// checkbox custom

.subject-checkk {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.subject-checkk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.subject-checkk .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgba(106, 100, 230, 0.1);
}

/* On mouse-over, add a grey background color */
.subject-checkk:hover input ~ .checkmark {
  background-color: rgba(106, 100, 230, 0.1);
}

//   /* When the checkbox is checked, add a blue background */
//   .subject-checkk input:checked ~ .checkmark {
//     background-color: #2196F3;
//   }

/* Create the checkmark/indicator (hidden when not checked) */
.subject-checkk .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.subject-checkk input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.subject-checkk .checkmark:after {
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 20px;
  height: 20px;
  background-color: var(--purple-dark);
  transform: scale(0.5);
}

// start educator sign up

.educator-subject-list {
  padding: 20px 0 30px 0;
  .subj-list-box {
    padding: 0px;
    margin: 0px;
    column-count: 3;

    li {
      padding: 0px;
      margin: 0px;
      list-style: none;
      display: inline-block;
    }
  }
}

.age-slidermax {
  .css-187mznn-MuiSlider-root {
    color: var(--purple-dark);
  }
  .css-1kz0hui-MuiSlider-valueLabel {
    background-color: var(--purple-dark);
    color: var(--white);
    -webkit-transform: translateY(-100%) scale(1);
    -moz-transform: translateY(-100%) scale(1);
    -ms-transform: translateY(-100%) scale(1);
    transform: translateY(-100%) scale(1);
  }
}

.age-slidermax .css-eg0mwd-MuiSlider-thumb:hover,
.age-slidermax .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(99, 44, 175, 0.16);
}

.age-slidermax .css-eg0mwd-MuiSlider-thumb.Mui-active {
  box-shadow: 0px 0px 0px 14px rgba(107, 85, 138, 0.16);
}

.educator-signup-step6 {
  .btnlogin-main {
    .btnlogin {
      padding: 10px 60px;
    }
  }
}
.educator-signup-step10 {
  .btnweak-bx {
    transform: perspective(250px) rotateY(-3deg);
    -webkit-transform: perspective(250px) rotateY(-3deg);
    -moz-transform: perspective(250px) rotateY(-3deg);
  }
  .loginform-bx {
    .title-med {
      font-size: 18px;
    }
  }
  .educt-tutor-pdf {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.ageslider-maxx {
  padding: 30px 0 1px 0px;
}
.educator-signup-step6 {
  .passwd-fild {
    position: relative;
    .icon-eye {
      position: absolute;
      top: 15px;
      right: 10px;
      color: #828282;
    }
  }

  .loginform-bx {
    .subsform-fild {
      .passwd-fild {
        .form-control {
          padding: 5px 30px 5px 20px;
        }
      }
    }
  }
}
.edu-count-step1 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 11.11%;
      }
    }
  }
}
.edu-count-step2 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 22.22%;
      }
    }
  }
}

.edu-count-step3 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 33.33%;
      }
    }
  }
}

.edu-count-step4 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 44.44%;
      }
    }
  }
}

.edu-count-step5 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 55.55%;
      }
    }
  }
}

.edu-count-step6 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 66.66%;
      }
    }
  }
}

.edu-count-step7 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 77.77%;
      }
    }
  }
}
.edu-count-step8 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 88.88%;
      }
    }
  }
}

.edu-count-step9 {
  .counting-barful {
    .linebar-box {
      &:before {
        width: 100%;
      }
    }
  }
}

// start become a tutor modal

.become-popup-modal {
  .modal-dialog {
    max-width: 600px;
  }

  .modal-title {
    p {
      max-width: 350px;
      margin: 0 auto;
    }
  }
  .loginform-bx {
    .subsform-fild {
      .tutor-textarea {
        height: 150px;
        resize: none;
      }
    }
  }
}

.become-popup-modal1 {
  .btnweak-bx {
    transform: perspective(300px) rotateY(-3deg);
    -moz-transform: perspective(300px) rotateY(-3deg);
    -webkit-transform: perspective(300px) rotateY(-3deg);
  }
  .btnlogin-main {
    padding-right: 30px;
    .btnlogin {
      padding: 10px 20px;
      max-width: 425px;
      width: 100%;
    }
  }
  .btnone-become {
    margin-bottom: 25px;
  }
  .modal-title {
    p {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

.become-count-step1 .counting-barful .linebar-box:before {
  width: 20%;
}
.become-count-step2 .counting-barful .linebar-box:before {
  width: 40%;
}
.become-count-step3 .counting-barful .linebar-box:before {
  width: 60%;
}
.become-count-step4 .counting-barful .linebar-box:before {
  width: 80%;
}
.become-count-step5 .counting-barful .linebar-box:before {
  width: 100%;
}
.become-popup-modal5 {
  .loginform-bx {
    .have-account {
      padding-bottom: 40px;
      padding-top: 20px;
      .title-med {
        font-size: 16px;
      }
    }
  }
  .btnlogin-main {
    .btnlogin {
      padding: 10px 20px;
      transform: perspective(250px) rotateY(-3deg);
      -moz-transform: perspective(250px) rotateY(-3deg);
      -webkit-transform: perspective(250px) rotateY(-3deg);
    }
  }
}
.become-popup-modal6 {
  .modal-title {
    padding-bottom: 30px;
  }
}

.file-upnew {
  .brower-btn {
    display: none;
  }
}
.file-upnew {
  .btn-browse-file {
    padding: 11px 40px;
    font-size: 16px;
    cursor: pointer;
  }
}
.upload-certif-bx {
  padding-bottom: 30px;
}
.become-popup-modal7 {
  .certific-bx {
    margin-left: -5px;
    margin-right: -5px;

    .one,
    .two {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.become-popup-modal7 {
  .btnlogin-main {
    .btnlogin {
      padding: 10px 25px;
      transform: perspective(250px) rotateY(-3deg);
      -moz-transform: perspective(250px) rotateY(-3deg);
      -webkit-transform: perspective(250px) rotateY(-3deg);
    }
  }
}

// end become modal

// satrt add pupil modal

.modal-pupil-new {
  .btnweak-bx {
    transform: perspective(200px) rotateY(-3deg);
    -moz-transform: perspective(200px) rotateY(-3deg);
    -webkit-transform: perspective(200px) rotateY(-3deg);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

    max-width: 320px;
    width: 100%;
  }
  .loginform-bx {
    .btnlogin-main {
      padding-right: 30px;
    }
  }
  .btnlogin-main {
    .btnlogin {
      padding: 10px 10px;
    }
  }
}
.modal-pupil-new {
  .modal-dialog {
    max-width: 590px;
  }
}
.btnad-pupil-lg {
  padding-bottom: 80px;
}
.newpupil-list-bx {
  padding-bottom: 30px;
}

// end add pupil modal

// start add group popup

.profile-uplod-btn {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: var(--orange);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}
.upload-profilebx {
  padding: 10px 0 20px 0;
}

.addgroup-count-step1 .counting-barful .linebar-box:before {
  width: 50%;
}
.addgroup-count-step2 .counting-barful .linebar-box:before {
  width: 100%;
}

.mychild-frm {
  .activ-detail-list {
    padding: 10px 5px;
    .active-detail-row {
      .activiti-prof-icon {
        flex: 0 0 47px;
        max-width: 47px;
        .profile-icon-box {
          width: 47px;
          height: 47px;
          font-size: 20px;
        }
      }
      .activiti-center-detail {
        h5 {
          font-size: 14px;
        }
        h6 {
          font-size: 10px;
        }
      }
    }
  }
  .height-max-flow {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 5px;
  }
}
.add-group-modal2 {
  .modal-title {
    p {
      max-width: 350px;
      margin: 0 auto;
    }
    h5 {
      font-size: 18px;
      line-height: 20px;
      color: var(--orange);
      font-weight: 500;
      margin: 0px;
      padding: 10px 0 0 0;
    }
    .confirmation-detail{
      max-width: unset !important;
    }
  }
  .child-boxnew {
    border-top: none;
  }
}

.serch-inner-group {
  .serchfiled-activities {
    position: relative;
    padding-bottom: 20px;
    .seachbtn {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 50px;
      height: 50px;
      border-radius: 0px;
      font-size: 20px;
      background-color: transparent;
      border: none;
      color: var(--purple);
    }
  }
}
.add-group-modal2 {
  .loginform-bx {
    .subsform-fild {
      .serch-inner-group {
        .form-control {
          padding: 5px 40px 5px 10px;
        }
      }
    }
  }
}

// end Add group popup

// start send Invite popup

.send-invite-modal1 {
  .modal-title {
    p {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}
.send-invite-modal2 {
  .modal-header {
    padding-bottom: 50px;
  }
  .modal-title {
    p {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}
.add-group-modal2 {
  .newpupil-list-bx {
    .child-tex-fld {
      .activiti-center-detail {
        padding-right: 25px;
      }
    }
  }
}
// end send Invite

// start group member

.educator-lsttabs-new {
  .chat-headerbar {
    .activiti-center-detail {
      h5 {
        display: inline-block;
      }
    }
  }
}
.group-member {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
  font-weight: 400;
  margin-bottom: 0px;
  color: var(--orange);
  padding-left: 10px;
}

.grpmember-btn {
  cursor: pointer;
}
.mychild-frm {
  .groupmbr-step1 {
    .child-boxnew {
      border-top: none;
    }
  }
}
.member-bxnw {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .member-texsml {
    font-size: 16px;
    line-height: 20px;
    color: var(--orange);
    font-weight: 500;
    margin: 0px;
    padding: 0px 0 0 0;
  }

  .addbtn-meber {
    padding-left: 10px;
    .addmbr-btn-sml {
      width: 30px;
      height: 30px;
      background: var(--purple-dark);
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
      border-radius: 6px;
      border: none;
    }
  }
}

.mychild-frm {
  .groupmbr-step1 {
    .height-max-flow {
      max-height: 480px;
    }
  }
}
.btn-delet-bx {
  ul {
    padding: 20px 0;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      padding: 0 20px;
      max-width: 170px;
      width: 100%;

      .btnweak-bx {
        border: none;
        padding: 10px 20px;
        transform: perspective(120px) rotateY(-3deg);
        -moz-transform: perspective(120px) rotateY(-3deg);
        -webkit-transform: perspective(120px) rotateY(-3deg);
      }
    }
  }
}

//end group member

// custom checkbox

.custm-check {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custm-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custm-check .checkmark {
  position: absolute;
  top: 12px;
  right: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #f1eafa;
}

/* When the checkbox is checked, add a blue background */
.custm-check input:checked ~ .checkmark {
  background-color: var(--orange);
}

/* Create the checkmark/indicator (hidden when not checked) */
.custm-check .checkmark:after {
  content: "";
  position: absolute;
}

/* Show the checkmark when checked */
.custm-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custm-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// end custome checkbox

@media only screen and (max-width: 767px) {
  .loginmodal .login-modal-mainbx {
    padding: 0px 20px;
  }
  .student-signup-step2 .row .one {
    margin-bottom: 10px;
  }
  .loginmodal .btnlogin-main .btnlogin {
    font-size: 14px;
    padding: 10px 40px;
  }
  .student-signup-step5 .btn-checkk .btnweak-bx {
    padding: 10px 40px;
    font-size: 14px;
  }
  .student-signup-step4 .btnlogin-main .btnlogin {
    padding: 10px 18px;
    margin-right: 15px;
  }

  .student-signup-step6 .btnlogin-main .btnlogin {
    padding: 10px 25px;
  }
  .mychild-frm .activ-detail-list .active-detail-row .activiti-prof-icon {
    flex: 0 0 40px;
    max-width: 40px;
  }
  .mychild-frm
    .activ-detail-list
    .active-detail-row
    .activiti-prof-icon
    .profile-icon-box {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  .mychild-frm
    .activ-detail-list
    .active-detail-row
    .activiti-center-detail
    h5 {
    font-size: 14px;
  }
  .mychild-frm
    .activ-detail-list
    .active-detail-row
    .activiti-center-detail
    h6 {
    font-size: 10px;
  }
  .mychild-frm .activ-detail-list {
    padding: 15px 5px;
  }
  .become-popup-modal1 .btnlogin-main .btnlogin {
    padding: 10px 8px;
    font-size: 13px;
  }
  .become-popup-modal7 .certific-bx .one {
    width: 65%;
  }
  .become-popup-modal7 .certific-bx .two {
    width: 35%;
  }
  .file-upnew .btn-browse-file {
    padding: 11px 20px;
  }
  .modal-pupil-new .btnlogin-main .btnlogin {
    padding: 10px 10px;
  }
}
.phone-with-country {
  .sendagain-btn {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    a {
      color: var(--purple);
    }
  }
}
.PhoneInput {
    width: 100%;
    .PhoneInputCountry {
      flex: 0 0 80px;
      max-width: 80px;
      border-radius: 13px 0px 0px 13px;
      height: 52px;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      background-color: rgba(106, 100, 230, 0.1);
      background-repeat: no-repeat;
      background-position: 90% 50%;
      background-size: 11px;
    }

    .PhoneInputInput {
      height: 52px;
      border-radius: 0px 13px 13px 0px !important;
      background-color: rgba(106, 100, 230, 0.1);
      border: none;
      padding: 5px 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--purple);
    }
  }
