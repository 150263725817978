.child-details-uper
{
    background-color: var(--white);
    border-radius: 20px;
    padding: 40px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    position: relative;
   .custmrow-detail
   {
    display: flex;
    align-items: center;
    flex-wrap: wrap; 

   .left-chlddetal
   {
       flex:0 0 auto;
       max-width: 100%;
       width:120px;


   } 
   .right-chlddetal
   {
    flex:1;
    max-width: 100%;
    text-align: left;
    padding-left: 30px;
   }
    
   }
   .profile-pic-child
   {
       .prof-picchild
       {
        width: 120px;
        height: 120px;
        background: var(--orange);
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        border-radius: 50%;
       }
   }
   .detail-child-inner
   {
       .lastseen
       {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.04em;
        font-weight: 400;
        color: var(--orange);
       }
       h3 
       {
        font-size: 30px;
        line-height: 28px;
        font-weight: 700;
        color: var(--purple-dark);
        margin: 0px;
        padding-bottom: 10px;
       }

       .subjet-detailchld
       {
           .subjchld-list
           {
               padding: 0px;
               margin: 0px;
               li 
               {
                   display: inline-block;
                   width: 25%;
                   padding-right: 10px;
                   h5 
                   {
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.04em;
                    font-weight: 700;
                    color: var(--grey);
                    margin-bottom: 0px;
                   }
                   h6 
                   {
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.04em;
                    font-weight: 400;
                    color: #828282;
                    margin-bottom: 0px;
                   }
                   .paid
                   {
                    background: var(--sky);
                    font-weight: 500;
                    padding: 0 10px;
                    border-radius: 3px;
                    color: var(--white);
                    margin-left: 5px;
                   }
               }
           }
       }

   }
   
}

.curentplain-bx
{
    .plancurnt-main
    {
        background: var(--white);
        padding: 20px 20px 20px 20px;
        border-radius: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        h4 
        {
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
            color: var(--purple-dark);
            margin: 0px;
            padding-bottom: 0px;
        }
        h5 
        {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: var(--orange);
            margin: 0px;
            padding-bottom: 0px;
        }
    }
}

.plancurnt-main .newlesson-listclk li .clock-time .timenum
{
    font-size: 45px;
    line-height: 48px;
    color: var(--grey);
}
.plancurnt-main .newlesson-listclk li .clock-time .time-days
{
    color: var(--grey);
}
.plancurnt-main .newlesson-top .bnewleson-heading {
    padding: 8px 5px;
    color: var(--white);
}
.plancurnt-main .btn-list-lesson .btn-listing-lesson li {
    padding-top: 10px;
}
.plancurnt-main .newlesson-listclk {
    max-width: 280px;
    margin: 0 auto;
}
.plancurnt-main .newlesson-clock {
    padding: 20px 0 0px 0;
}
.plancurnt-main .lessontime-bottom .lessonnew-cardbx .newlesson-shape:before
{
    background: linear-gradient(to top, var(--white) 81%, var(--orange) 50%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.plancurnt-main .btn-list-lesson .btn-listing-lesson li .btnweak-bx
{
    font-size: 16px;
    line-height: 27px;
}


.curentplain-bx
{
    padding-top: 25px;
}
.livelessn-card
{
    .child-lession
    {
      
        h4 
        {
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
            color: var(--purple-dark);
            margin: 0px;
            padding-bottom: 20px;
        }
        .lession-leftnw
        {
            padding-bottom: 20px;
         
            p 
            {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: var(--grey-light);
                margin-bottom: 0px;
                padding-bottom: 5px;

                strong
                {
                    color:var(--purple-dark);
                }
                span 
                {
                    color: #333;
                }
            }
        }
        .weekduring
        {
            padding-bottom: 20px;
           p 
           {
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            color:var(--grey-light);
            margin-bottom: 0px;
           }
           .total-bkdleson
           {
               font-weight: 700;
               padding-top: 5px;
               color: var(--grey);
           }
        }
    }
    .morelesn-btn
    {
        .btn-week
        {
            padding-right: 15px;
            .btnweak-bx
            {
                font-size: 16px;
                    padding: 10px 20px;

            }
        }
    }

}
.livelessn-card
{
    padding: 20px 0;
    border-top: 2px solid #E2CEFF;
}
.payment-histry-btn
{
    font-weight: 700;
    padding-top: 5px;
    color: var(--orange);
    &:hover 
    {
        color: var(--orange);
    }
}
.history-pay
{
    padding-top: 20px;
}

.activity-process {
    background: var(--white);
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.title-process
{
    text-align: left;
    h3 
    {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        color: var(--purple-dark);
        margin-bottom: 0px;
        padding-bottom: 10px;
    }
}
.progress-cardouter-main
{
    padding-top: 25px;
}
.activity-process
{
    .sesson-listmain 
    {
        padding: 10px 0px;
        .title-main 
        {
            padding: 5px 0;
        }
    }
    .lesson-hovrbx
    {
        padding: 5px 0;
        border-bottom: 3px solid #E2CEFF;
        border-top: none;
    }

}

.moreactive-btn
{
    padding: 20px 0 0px 0;

    .history-pay
    {
        padding-top: 0px;
    }
}
.payment-histry-btn:hover 
{
    color: #ff8d24;
}


.newscard-inner 
{
    .activ-detail-list 
    {
        .active-detail-row 
        {
            .activiti-center-detail 
            {
                h5 
                {
                    font-size: 16px;
                    color: var(--purple-dark);
                }
                h6 
                {
                    font-size: 14px;
                }
            }
        }
    }
    .news-content
    {
        .news-row
        {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding:10px 0;
        }
        .news-left
        {
            flex: 1;
        }
        .news-contentbx
        {
            text-align: left;
            padding-right: 20px;
            p {
                margin: 0px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: var(--grey-light);
            }
        }
       
        .report-btn
        {
            font-size: 14px;
            font-weight: 600;
            background-color: var(--sky);
            color: var(--white);
            padding: 5px 10px;
            border-radius: 7px;
            text-decoration: none;
            &:hover 
            {
                background-color: #3fd7d7;     
            }
        }
    }
    .activ-detail-list
    {
        padding: 10px 0px;
    }
}

.imgfluid
{
    width: 100%;
}

.helping-other
{
    .helping-other-main
    {
        background: #EDEDFF;
    border-radius: 10px;
    padding: 10px;

        .left-img
        {
            img 
            {
                border-radius: 10px 0 0px 10px;
            }
        }
        .two
        {
            align-self: center;
        }
        .helping-card
        {
            h5 
            {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.04em;
                margin-bottom: 0px;
                color: var(--orange);
            }
            h4
            {
                line-height: 20px;
                font-weight: 700;
                margin-bottom: 0px;
                font-size: 18px;
                color: var(--purple-dark);
            }

            .helping-points-list
            {
                padding-top: 20px;
                ul 
                {
                    padding: 0px;
                    margin: 0px;
                    li 
                    {
                        .point-content
                        {
                            margin: 0px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: var(--grey-light);
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}

.news-card
{
    border-bottom: 3px solid #E2CEFF;
    padding-bottom: 10px;
}
.childrenprogres-details .child-details
{
    padding-bottom: 50px;
}


// start media query

@media only screen and (max-width:1600px){

    .footer-parents .foter-list .foter-listnew ul li {
        padding: 0 20px;
    }

}  
@media only screen and (max-width:1280px){
    .footer-parents .foter-list .foter-listnew ul li {
        padding: 0 10px;
    }

}
@media only screen and (max-width:1200px) and (min-width:992px){

    .footer-parents .row .one {
        width: 25%;
    }
    .footer-parents .row .two {
        width: 75%;
    }
    .footer-parents .foter-listnew ul li a
    {
        font-size: 14px;
    }

}


@media only screen and (max-width:991px){

    .plancurnt-main .newlesson-listclk li .clock-time .timenum
    {
        font-size: 35px;
        line-height: 34px;
    }
    .plancurnt-main .newlesson-listclk li .clock-time .time-days
    {
        font-size: 12px;
    }
    .plancurnt-main .newlesson-listclk li
    {
        padding: 0px 5px;
    }
    .curentplain-bx .plancurnt-main
    {
        padding: 20px 10px 20px 10px;
    }
    .plancurnt-main .btn-list-lesson .btn-listing-lesson li
    {
        padding: 0px 5px;
        padding-top: 10px;
    }
    .plancurnt-main .btn-list-lesson .btn-listing-lesson li .btnweak-bx
    {
        font-size: 14px;
        line-height: 27px;
        padding: 10px 15px;
    }
    .plancurnt-main .newlesson-top .bnewleson-heading
    {
        font-size: 10px;
    }
    .livelessn-card .morelesn-btn .btn-week .btnweak-bx {
        font-size: 14px;
        padding: 10px 20px;
    }
    .child-details-uper .profile-pic-child .prof-picchild
    {
        width: 100px;
        height: 100px;
        font-size: 40px;
    }
    .child-details-uper .custmrow-detail .left-chlddetal
    {
        width: 100px;
    }
    .child-details-uper .custmrow-detail .right-chlddetal
    {
        padding-left: 20px;
    }
    .child-details-uper .detail-child-inner h3
    {
        font-size: 24px;
    }
    .child-details-uper .detail-child-inner .subjet-detailchld .subjchld-list li
    {
        width: 50%;
    }
}
@media only screen and (max-width:767px){

    .footer-parents .foter-list .foter-listnew ul
    {
        padding-right: 0px;
    }
    .footer-parents .foter-list .foter-listnew ul
    {
        text-align: center;
    }
    .footer-parents .foter-list .foter-listnew ul a 
    {
        font-size: 12px;
    }

    .child-details-uper .custmrow-detail .left-chlddetal {
        width: 100%;
        padding-bottom: 20px;
    }
    .child-details-uper .custmrow-detail .right-chlddetal {
        padding-left: 0px;
    }
    .child-details-uper .profile-pic-child .prof-picchild
    {
        margin: 0 auto;
    }
    .child-details-uper .custmrow-detail .right-chlddetal .detail-child-inner
    {
        text-align: center;
    }
    .curentplain-bx .plancurnt-main {
        padding: 20px 20px 20px 20px;
    }
    .newscard-inner .news-content .news-contentbx p
    {
        font-size: 14px;
    }

}
.backarw-child {
    position: absolute;
    top: 10px;
    left: 10px;
    .backbtn-lg
    {
        img 
        {
            width: 30px;
        }
    }
}