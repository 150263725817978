.educator-headermain
{
    .center-navbar
    {
        flex:1;
       .educator-menubar
       {
           padding: 0 20px;
           margin: 0px;

           li 
           {
               display: inline-block;
                padding: 0 40px;

                .educator-menu-link
                {
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 400;
                    color: var(--white);
                    text-decoration: none;

                    &:hover 
                    {
                        color: var(--yellow-dark);
                    }

                    .child-num
                    {
                        font-size: 18px;
                        line-height: 20px;
                        padding: 3px 5px;
                        color: var(--black);
                        background-color: var(--yellow-dark);
                        font-weight: 600;
                        display: inline-block;
                        border-radius: 5px;
                        margin-left: 10px;
                    }

                }

                .active 
                {
                    font-weight: 700;
                    
                    
                }

           }
       } 

    }
}

.puil-mainbx
{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .add-pupil
    {
        flex:0 0 46px;
        max-width: 46px;
        padding-bottom: 30px;
    }

    .pupil-dropdwn
    {
        .dropdown-main
        {
            .dropdown-btnn
            {
                width: 46px;
                height: 46px;
                background-color: var(--purple-dark);
                border: none;
                border-radius: 10px;
                color: var(--white);
                &::after{
                    display: none;
                }
                &:focus 
                {
                    box-shadow: none;
                    outline: none;
                }

            }
        }
    }

    .serchfiled-activities
    {
        flex: 1;
        padding-left: 20px;
        .formbox
        {
            height: 46px;
        }
        .seachbtn
        {
            height: 46px;
            font-size: 20px;
        }
    }
}

.dropmenu-custlistt
{
    .customdrop-listmenu
    {
        background-color: transparent;
        border: none;
        .dropdown-item
        {
            background: var(--purple);
            color: var(--white);
            border-radius: 12px;
            padding: 12px 15px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            margin-bottom: 5px;

            .rightarrow-icon{
                color: var(--orange);
            }

            &:hover 
            {
                background: #7e78ed;
            }

        }

    }
}


.educator-lsttabs-new
{
    .chat-topbar-list
    {
        .activ-detail-list 
        {
            .activiti-count-mesg
            {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        .dropdown-main 
        {
            .addactv-btndrp
            {
                width: auto;
                min-height: 46px;
                background-color: var(--purple-dark);
                color: var(--white);
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                border-radius: 12px;
                padding: 10px 20px;
            }
            .dropdown-btn-item
            {
                top: 45px !important;
            }
        }
    }
    .list-chatbx
    {
       
        
        .edu-badg-lst
        {
            padding-right: 10px;
            .chatbtn
            {
                img 
                {
                    width: 30px;
                }
            }
        }
    }
    
}
.educator-lsttabs-new
{
.chat-topbar-list
{
    .activ-detail-list
    {
        .activiti-count-mesg
        {
            .chat-right-droplist
            {
                .list-chatbx
                {
                    .educ-acvt
                    {
                        padding-right: 40px;
                    }
                                .educ-chat-lst
                    {
                        padding-right: 20px;
                    }
                }
            }
        }
    }
}
}

.btn-login-bx .dropmenu-custlistt .customdrop-listmenu
{
    margin-top: 0px !important;
}
.btn-login-bx .dropmenu-custlistt .customdrop-listmenu .dropdown-item
{
    background: #7973eb;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
}
.btn-login-bx .dropmenu-custlistt .customdrop-listmenu .dropdown-item:hover {
    background: #7e78ed;
}

// side menu bar educator
.educator-headermain  .menuside
{
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    left: auto;
    right: 0;
}
.educator-headermain .showmenu {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
}


// start media query


@media only screen and (min-width:992px){

.educator-headermain .btn-bx-get.educator-mob-sidebar
{
    display: none;
}

}

@media only screen and (max-width:1200px){

    .educator-lsttabs-new .chat-topbar-list .dropdown-main .addactv-btndrp
    {
        padding: 10px 15px;
    }
    .educator-lsttabs-new .chat-topbar-list .activ-detail-list .activiti-count-mesg .chat-right-droplist .list-chatbx .educ-acvt {
        padding-right: 10px;
    }
    .educator-lsttabs-new .chat-topbar-list .activ-detail-list .activiti-count-mesg .chat-right-droplist .list-chatbx .educ-chat-lst {
        padding-right: 10px;
    }
    .chat-topbar-list .activ-detail-list .activiti-count-mesg .chat-right-droplist .list-chatbx .chat-iconn {
        padding-right: 6px;
    }
    .educator-lsttabs-new .chat-topbar-list .dropdown-main .addactv-btndrp
    {
        font-size: 14px;
    }
    .myactivites-main .activities-rightbx .activiti-prof-icon .profile-icon-box {
        width: 47px;
        height: 47px;
        font-size: 20px;
    }
    .myactivites-main .activities-rightbx .activiti-prof-icon {
        flex: 0 0 47px;
        max-width: 47px;
    }
    .chatbtn .chatbtn-new img {
        width: 30px;
    }
    .educator-lsttabs-new .list-chatbx .edu-badg-lst .chatbtn img {
        width: 25px;
    }
    .educator-headermain .center-navbar .educator-menubar li
    {
        padding: 0px 10px;
    }

}
@media only screen and (max-width:991px){
    .educator-headermain .dashboard-topbarr .header-holder .lft-menu {
        flex: 0 0 35%;
        max-width: 35%;
        text-align: left;
    }
    .educator-headermain .dashboard-topbarr .header-holder .right-menu-dasboard {
        flex: 0 0 80px;
        max-width: 80px;
      
    }
    .educator-headermain .center-navbar .educator-menubar {
        padding: 0 8px;
    }
    .educator-headermain .center-navbar .educator-menubar li .educator-menu-link
    {
        font-size: 16px;
    }
    .educator-headermain .center-navbar .educator-menubar li {
        padding: 0px 5px;
    }
    .educator-headermain .center-navbar .educator-menubar li .educator-menu-link .child-num
    {
        font-size: 16px;
        margin-left: 2px;
    }

    .educator-footer.footer-section .row .one {
        width: 30%;
    }
    .educator-footer.footer-section .row .two {
        width: 40%;
    }
    .educator-footer.footer-section .row .three {
        width: 30%
}
.footer-section.educator-footer .btnweak-bx {
    padding: 13px 13px;
}
.educator-footer .foter-list .foter-listnew ul {
    flex: 0 0 50%;
    max-width: 50%;
}
.educator-headermain .lft-menu .navbar-brand
{
    margin-left: 0px!important;
}
.educator-headermain .profile-menubar .btn-bx-get
{
    padding-left: 20px;
    padding-right: 0px;
}
.educator-headermain .sidemenubar-main .close-sidebar
{
    right: 0px;
    left: auto;
}
.educator-headermain .sidemenubar-main .logobar-top
{
    padding-bottom: 50px;
}
.educator-menu-link .child-num
{
    line-height: 20px;
    padding: 3px 5px;
    color: var(--black);
    background-color: var(--yellow-dark);
    font-weight: 600;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    margin-left: 2px;
}
.educator-headermain .inner-page-topbar
{
    padding-bottom: 35px;
}


}

@media only screen and (max-width:991px) and (min-width:768px){

   .educator-home .myactivites-contain .activies-row .left-pannel {
        flex: 0 0 35%;
        max-width: 35%;
    }
    .educator-home .myactivites-contain .activies-row .right-panel {
        flex: 0 0 65%;
        max-width: 65%;
        padding: 0 10;
    }
    .educator-home .chat-headerbar .chat-topbar-list {
        padding: 20px 10px;
    }
    .educator-home .activ-detail-list .active-detail-row .activiti-prof-icon .profile-icon-box
    {
        width: 40px;
        height: 40px;
    }
    .educator-home .activ-detail-list .active-detail-row .activiti-prof-icon {
        flex: 0 0 40px;
        max-width: 40px;
    }
    .educator-lsttabs-new .chat-topbar-list .dropdown-main .addactv-btndrp {
        font-size: 12px;
        padding: 5px 10px;   
    min-height: 35px;
    }
    .chatbtn .chatbtn-new img {
        width: 25px;
    }
    .educator-lsttabs-new .list-chatbx .edu-badg-lst .chatbtn img {
        width: 20px;
    }
    .chat-topbar-list .activ-detail-list .activiti-center-detail h6
    {
        font-size: 12px;
        line-height: 14px;
    }
    .chat-topbar-list .activ-detail-list .activiti-center-detail h5
    {
        font-size: 16px;
    }
    .educator-lsttabs-new .chat-topbar-list .activ-detail-list .activiti-count-mesg {
        flex: 0 0 55%;
        max-width: 55%;
    }
    .educator-lsttabs-new .activ-detail-list .active-detail-row .activiti-center-detail {
        padding: 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .educator-lsttabs-new .chat-topbar-list .activ-detail-list .activiti-center-detail h5,
    .educator-lsttabs-new .chat-topbar-list .activ-detail-list .activiti-center-detail h6
    {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .puil-mainbx .pupil-dropdwn .dropdown-main .dropdown-btnn
    {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .puil-mainbx .add-pupil {
        flex: 0 0 40px;
        max-width: 40px;
    
    }
    .puil-mainbx .serchfiled-activities .formbox {
        height: 40px;
    }
    .puil-mainbx .serchfiled-activities .seachbtn {
        height: 40px;
        
    }
    .puil-mainbx .serchfiled-activities
    {
        padding-left: 10px;
    }
    .activ-detail-list {
        padding: 20px 10px;
    }
   

}
@media only screen and (max-width:767px){
    .myactivites-main .react-tabs__tab--selected
    {
        background-color: transparent;
    }
    .educator-lsttabs-new .chat-topbar-list .activ-detail-list .activiti-count-mesg {
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
        padding-bottom: 15px;
    }
    .educator-home .myactivites-main .activities-rightbx .activiti-prof-icon {
        order: 2;
    }
    .educator-home .chat-headerbar .activ-detail-list .active-detail-row .activiti-center-detail
    {
        order: 3;
    }
    .educator-lsttabs-new .chat-topbar-list .dropdown-main .addactv-btndrp
    {
        padding: 5px 15px;
        font-size: 12px;
        min-height: 35px;
    }
    .puil-mainbx .add-pupil
    {
        padding-bottom: 20px;
    }
    .educator-footer.footer-section .row .one {
        width: 100%;
    }
    .educator-footer.footer-section .row .two {
        width: 100%;
    }
    .educator-footer.footer-section .row .three {
        width: 100%;
    }
    .educator-footer .footer-become {
        text-align: center;
        margin-right: 40px;
    }
    .educator-headermain .profile-menubar .btn-bx-get {
        padding-left: 15px;
    }
    .educator-headermain .sidemenubar-main .close-sidebar {
  
        top: -30px;
    }
    .educator-headermain .sidemenubar-main .close-sidebar .btnclose
    {
        width: 25px;
        height: 25px;
    }

    }