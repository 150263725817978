.articlesection {
    background: linear-gradient(147.04deg, #5081F2 27.21%, #7658DF 81.6%);
    box-shadow: 0px -7px 59px rgba(0, 0, 0, 1.5);
    padding: 100px 0 100px 0;
    margin-top: -30px;

    .common-navs{
        li{
            font-size: 14px;
            color: #fff;
            &:before{
                color: #fff;
            }
        }
        a {
            color: #fff;
            text-decoration: none;
        }
    }

    .article-details {
        position: relative;
        z-index: 9;
        padding: 40px 40px 65px 40px;
        margin-bottom: 0;

        .row{
            position: relative;
            z-index: 99;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: -20px;
            width: 100%;
            height: 100%;
            transform: perspective(1000px) rotateY(-1deg);
            -webkit-transform: perspective(1000px) rotateY(0deg);
            background: #FFFFFF;
            border-radius: 30px;
            z-index: 0;
            display: block;
            transform-origin: 0% 0%;
            -webkit-transform-origin: 0% 0%;
        }

        .article-leftpic{
            position: relative;
            z-index: 99;
            .picard{
                position: relative;
                margin-left: -90px;
                img{
                    border-radius: 25px;
                    overflow: hidden;
                    border: 9px solid #fff;
                    transform: perspective(1000px) rotateY(8deg);
                    -webkit-transform: perspective(1000px) rotateY(8deg);
                    filter: drop-shadow(0px 19px 36px rgba(0, 0, 0, 0.25));
                    transform-origin: 0% 0%;
                    -webkit-transform-origin: 0% 0%;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    z-index: 99;
                }
            }
        }

        .article-text{
            text-align: left;
            padding-top: 20px;
            .small-tag{
                background: var(--yellow);
                color:var(--purple);
                font-weight: bold;
                border-radius: 6px;
                padding: 5px 20px;
                display: inline-block;
                font-size: 12px;
                margin-bottom: 15px;
            }
    
            .title-main {
                font-weight: 700;
                font-size: 34px;
                line-height: 39px;
                letter-spacing: 0.04em;
                color: var(--purple);
                padding-bottom: 10px;
                margin: 0px;
            }

            .date-section{
                font-size: 14px;
                color: #A8A8A8;
                font-weight: 600;
                margin:0 0 20px 0;
            }
    
            .small-tex {
                margin: 0px;
                padding-bottom: 20px;
                color: var(--purple);
            }
        }

        .article-section-two{
            text-align: left;
            padding-top: 60px;

            .title-main {
                font-weight: 700;
                font-size: 18px;
                letter-spacing: 0.04em;
                color: var(--purple);
                padding-bottom: 10px;
                margin: 0px;
            }
            
            .small-tex {
                margin: 0px;
                padding-bottom: 20px;
                color: var(--purple);
                line-height: 20px;
            }
        }

        .article-text-three{
            text-align: left;
            padding-top: 20px;
    
            .title-main {
                font-weight: 700;
                font-size: 18px;
                letter-spacing: 0.04em;
                color: var(--purple);
                padding-bottom: 10px;
                margin: 0px;
            }
    
            .small-tex {
                margin: 0px;
                padding-bottom: 20px;
                color: var(--purple);
                line-height: 20px;
            }
        }

        .article-leftpic-three{
            .picard-three{
                position: relative;
                margin-right: -90px;
                margin-top: 30px;
                img{
                    border-radius: 25px;
                    overflow: hidden;
                    border: 9px solid #fff;
                    transform: perspective(1000px) rotateY(8deg);
                    filter: drop-shadow(0px 19px 36px rgba(0, 0, 0, 0.25));
                    transform-origin: top;
                }
            }
        }

        .article-section-four{
            margin: 80px 0;
            padding:0 50px;
            .small-tex {
                margin: 0px;
                padding-bottom: 20px;
                color: var(--purple);
                line-height: 26px;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.04em;
                text-align: center;
            }
        }

        .article-five{
            padding: 0 50px;
            text-align: left;
            .small-tex {
                margin: 0px;
                color: var(--purple);
                line-height: 20px;
            }
        }

        .share-bar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 50px 0 0 0;
            ul{margin: 0;
            padding: 0;
            display: inline-flex;
            align-items: center;
            }
            li{
                list-style: none;
                font-size: 14px;
                margin-left: 25px;
            }

            .social-media{
                a {
                    width: 30px;
                    height: 30px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: #4FE7E7;
                    border-radius: 50%;
                    margin: 0 0 0 6px;
                }
            }

        }



    }

    .comment-box-main{
    position: relative;
    z-index: 99;
    padding: 60px 60px 65px 60px;
    margin-top: -90px;
    top: 160px;

    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left:0;
        width: 100%;
        height: 100%;
        transform: perspective(1000px) rotateY(0.5deg) rotateX(-5deg);
        -webkit-transform: perspective(1000px) rotateY(0.5deg) rotateX(-5deg);
        background: #FFFFFF;
        border-radius: 30px;
        z-index: -1000;
        display: block;
        transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
    }

    .heading-text {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.04em;
        color: var(--purple);
        padding-bottom: 10px;
        margin: 0px;
        text-align: left;
    }

    .type-comment{
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;

        .in-user{
            flex: 0 0 85px;
            max-width: 85px;
            border-radius: 50%;
            overflow: hidden;
        }

        .textbox-holder{
            flex: 1;
            padding:0 20px;
            .type-txt{
                background: #F1EAFA;
                border-radius: 10px;
                border: none;
                padding: 12px 20px;
                font-size: 14px;
            }
            .post-check {
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
                margin: 10px 0 0 0;
                
                p {
                        flex: 1;
                        display: flex;
                        font-size: 14px;
                        color: #6A64E6;
                        display: flex;
                        a{
                            color: #FF5C00;
                        }
                    }   
                    .align-right {
                        justify-content: flex-end;
                    }

            }
        }

        .button-holder{
            flex: 0 0 140px;
            max-width: 140px;

            button{
                background: #632CAF;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.4px;
                border: none;
                padding: 10px 0;
                width: 100%;
                border-radius: 10px;
            }
        }

    }


    .user-comment-section {
        display: flex;
        margin: 30px 0 0 0;

        .user-img {
            flex: 0 0 85px;
            max-width: 85px;
            border-radius: 50%;
            overflow: hidden;
        }

        .user-content {
            flex: 1;
            text-align: left;
            padding: 0 0 0 15px;

            .user-name{
                font-size: 18px;
                font-weight: 700;
                color: #632CAF;

                span{
                    font-size: 12px;
                    color: #828282;
                    font-weight: 400;
                    position:relative;
                    padding-left: 50px;
                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #ddd;
                        position: absolute;
                        top: 4px;
                        left: 20px;
                        border-radius: 20px;
                    }
                }
            }

            .comment-text{
                font-size: 14px;
                color: #000;
                font-weight: 500;
                margin: 8px 0 8px 0;
            }

            .like-section{
                ul {
                    display: flex;
                    margin: 0;
                    padding: 0;
                }
                li{
                    list-style: none;
                    position: relative;
                    padding-right: 50px;

                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        background: #ddd;
                        border-radius: 20px;
                        top: 8px;
                        right: 20px;
                    }


                    a{
                        font-size: 16px;
                        color: #6A64E6;
                        font-weight: 600;
                    }
                }
                li:last-child{
                    &:before{
                        content: unset;
                    }
                }
            }


        }

    }


    }
}

.interest-section{
    padding-top: 130px;
}

