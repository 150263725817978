.myactivites-contain
{
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 23px;
  padding: 5px 0;

    .activies-row
    {
        min-height: 25rem;
        display: flex;
        flex-wrap: wrap;
        line-height: 1;

        .left-pannel
        {
            flex: 0 0 30%;
            max-width: 30%;
            padding: 0 20px;
            border-right: 3px solid #E2CEFF;
        }
        .right-panel
        {
            flex: 0 0 70%;
            max-width: 70%;
            padding: 0 20px;
        }


    }

    .serchfiled-activities
    {
        position: relative;
        padding-bottom: 30px;

        .formbox
        {
            background: #EDEDFF;
            border-radius: 10px;
            height: 50px;
            border: none;
            font-size: 14px;
            line-height: 20px;
            // color: #BDBDBD;
            outline: none;
            box-shadow: none;
            padding: 5px 18px;
            font-size: 0.9rem;
            color: var(--purple-dark);
            &::placeholder 
            {
                color: #BDBDBD;
                font-weight: 600;
                font-size: 0.9rem;
            }

        }
        .seachbtn
        {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 50px;
            height: 50px;
            border-radius: 0px;
            font-size: 25px;
            background-color: transparent;
            border: none;
            color: var(--purple);
        }

    }
    .leftbx-activites
    {
        padding: 20px 0;
    }

}
.activ-detail-list
{
    padding: 20px 15px;
    .active-detail-row
    {
        display: flex;
        flex-wrap: wrap;
        line-height: 1;
        align-items: center;

        .activiti-prof-icon
        {
            flex: 0 0 47px;
            max-width: 47px;

            .profile-icon-box
            {
                width: 47px;
                height: 47px;
                background:var(--orange);
                color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                border-radius: 50%;
            }

        }
        .activiti-center-detail
        {
            flex: 1;
            text-align: left;
            padding:0 12px;

            h5 
            {
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                margin-bottom: 0px;
                color: var(--black);
            }
            h6 
            {
                font-size: 10px;
                line-height: 20px;
                font-weight: 400;
                margin-bottom: 0px;
                color: #828282;
            }
           
        }
        .right-boxlesson
        {
            flex: 0 0 24px;
            max-width: 24px;
        }
        .activiti-count-mesg
        {
            flex: 0 0 24px;
            max-width: 24px;

            .count-num-activiti
            {
                width: 24px;
                height: 24px;
                background:var(--sky);
                color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 600;
                border-radius: 6px;
            }

        }
    }
}


.myactivites-main
{
    .activities-rightbx
    {
        .activiti-prof-icon
        {
            flex: 0 0 70px;
            max-width: 70px ;

            .profile-icon-box
            {
                width: 70px;
                height: 70px;
                font-size: 30px;
            }

        }
    }
}

.activities-list-left
{
    .tabslist-activiti
    {
        padding: 0px;
        margin: 0px;
        .tabslist-activiti-tab
        {
            padding: 10px 0;
            margin: 0px;
            list-style: none;
            cursor: pointer;
            border-bottom: 3px solid #E2CEFF;
        }
        .react-tabs__tab--selected
        {
            .activ-detail-list
            {
                background: #EDEDFF;
            border-radius: 10px;
            }
        }
    }
 
}
.myacti-listtabs
{
    .top-session
    {
        .custm-row
        {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: 1;
            .profile-box
            {
                flex: 0 0 70px;
                max-width: 70px;
            }
            .right-detail-box
            {
                flex: 0 0 100px;
                max-width: 100px;
                text-align: right;
            }
        }
    }
}

.chat-topbar-list
{
    .activ-detail-list
    {
        padding: 0px;
        .activiti-center-detail
        {
            h5 
            {
                font-size: 18px;
                line-height: 20px;
                font-weight: 700;
                color: var(--purple-dark);
            }
            h6 
            {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.04em;
            }
        }
        .activiti-count-mesg
        {
            flex: 0 0 30%;
            max-width: 30%;
            text-align: right;
            .chat-right-droplist
            {
                .list-chatbx
                {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 0px;
                    margin: 0px;
                    li 
                    {
                        padding: 0px;
                        margin: 0px;
                        list-style: none;
                        display: inline-block;
                    }
                    .chat-iconn
                    {
                        padding-right: 10px;
                    }
                }
             
             }
         }
  
    }
    .droplist-right-btn
    {
        .dropdown-main
        {
            .dropdown-btnn 
            {
                padding: 0px;
                height: 30px;
                line-height: 1;
                background-color: transparent;
                border: none;
                outline: none;
                color: var(--purple-dark);
                font-size: 25px;

                &:after 
                {
                    display: none;
                }
                &:focus 
                {
                    box-shadow: none;
                    outline: none;
                    border: none;
                }
            }
            .dropdown-btn-item
            {
                transform: none !important;
                right: 0px !important;
                left: auto !important;
                top: 30px !important;
                background-color: transparent;
                border: none;
                .dropdown-item
                {
                    background: var(--purple);
                    color: var(--white);
                    border-radius: 12px;
                    padding: 12px 15px;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 700;
                    margin-bottom: 5px;

                    &:hover 
                    {
                        background: #7e78ed;
                    }
                    .rightarrow-icon
                    {
                        padding-right: 5px;
                        color: var(--orange);
                    }
                }
            }
        }
    }
}
.chatbtn
{
    .chatbtn-new
    {
        img 
        {
            width: 35px;
        }
    }
}
.chat-headerbar
{
    .chat-topbar-list
    {
        padding: 20px 15px;
    }
}
.lesson-hovrbx
{
    border-top: 3px solid #E2CEFF;
    padding: 10px 0;
}
.completed-activity-notification{
    background-color: rgba(185, 195, 185, 0.152);
}

.sesson-listmain
{
    padding: 20px 15px;

    .custom-row
    {
        display: flex;
        flex-wrap: wrap;
        line-height: 1;
    }
    .live-lesson-main
    {
        .custom-row
        {
            justify-content: space-between;

            .lesson-leftbx
            {
                padding-right: 10px;
            }
        }  
      
    }
    .title-main
    {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        color: var(--grey);
        font-weight: 700;
        padding-bottom: 12px;
        max-width: 80%;
        padding-top: 12px;
    } 
    .lesson-list-bottom
    {
        .left-boxleson
        {
            flex:1;
            text-align: left;

            .list-point-lesson
            {
                padding: 0px;
                margin: 0px;
                li 
                {
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    display: inline-block;
                    padding-right: 50px;
                    
                }
            }
        }
        .right-boxlesson
        {
            flex: 0 0 32px;
            max-width: 32px;
        }
    }

    .medm-tex
    {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.04em;
        color: #bdbdbd;
    }
    .purple-clr
    {
        color: var(--purple-dark);
    }
    .orng-clr
    {
        color: var(--orange);
    }
    .sky-clr
    {
        color: var(--sky);
    }
}
.count-total-actvnum
{
    width: 32px;
    height: 32px;
    background: var(--sky);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
}

.activities-rightbx
{
    .live-lesson-listbtm
    {
        max-height: 720px;
        overflow-y: auto;
        padding: 0 10px 0 0px;
    }
}

.myactivites-contain
{
    .activities-list-left
    {
        max-height: 720px;
        overflow-y: auto;
        padding-right: 5px;
    }
}

@media only screen and (max-width:991px){

    .myactivites-contain .activies-row .left-pannel {
        flex: 0 0 40%;
        max-width: 40%;
        padding: 0 10px;
    }
    .myactivites-contain .activies-row .right-panel {
        flex: 0 0 60%;
        max-width: 60%;
        padding: 0 10px;
    }
    .activ-detail-list .active-detail-row .activiti-center-detail
    {
        padding: 0 8px;
    }
}

@media only screen and (max-width:767px){

    .myactivites-contain
    {
        background-color: transparent;
        box-shadow: none;
    }
    .myactivites-contain .activies-row .left-pannel {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 10px;
        border-right: none;
        background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 23px;
    margin-bottom: 20px;
    }
    .myactivites-contain .activies-row .right-panel {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 10px;
        background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 23px;
    padding-bottom: 20px;
    }
    .myactivites-contain .serchfiled-activities
    {
        padding-bottom: 20px;
    }
    .activ-detail-list {
        padding: 10px 10px;
    }
    .activities-list-left .tabslist-activiti .tabslist-activiti-tab
    {
        padding: 6px 0;
    }

    .myactivites-contain
    {
        padding: 5px 0 20px 0;
    }
    .activities-rightbx .live-lesson-listbtm
    {
        max-height: 400px;
    }
    .sesson-listmain .title-main
    {
        max-width: 100%;
    }
    .diversity-box .title-box-diver
    {
        font-size: 28px;
        line-height: 35px;
        padding-bottom: 40px;
    }
    .myactivites-contain .activities-list-left
    {
        max-height: 250px;
    }

  

}

.live-lesson-listbtm
{
    .newlesson-card
    {
        text-decoration: none;
        display: block;
        &:hover 
        {
            .sesson-listmain
            {
                background: #EDEDFF;
                border-radius: 10px;
            }
        }
    }
}
@media only screen and (max-width:767px){

    .sesson-listmain .lesson-list-bottom .left-boxleson .list-point-lesson li
    {
        padding-right: 10px;
    }
    .sesson-listmain .medm-tex
    {
        font-size: 13px;
    }
    .count-total-actvnum
    {
        width: 24px;
        height: 24px;
        font-size: 12px;
    }
    .sesson-listmain .lesson-list-bottom .right-boxlesson {
        flex: 0 0 24px;
        max-width: 24px;
    }
    .inner-page-topbar
    {
        filter: drop-shadow(0px 19px 26px rgba(0, 0, 0, 0.25));
    }
}
.submit-already-btn,.submit-already-btn:hover{
// choose background color to show disabled button
background-color: #EDEDFF;
position: relative;
color: #828282;
top: 70px;
}
.number-points:disabled{
    background-color: #d8d8dfaf !important;
    color: #828282 !important;
}