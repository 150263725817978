.tabslist-gamestp {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  .react-tabs__tab {
    font-size: 15px;
    line-height: 20px;
    color: var(--white);
    font-weight: 400;
    padding: 10px 20px;
    border-radius: 0px;
    position: relative;
  }
  .react-tabs__tab--selected {
    background: transparent;
    border: none;
    font-weight: 700;

    &:after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: 5px;
      background-color: var(--orange);
      border-radius: 2px;
    }
  }
}

.games-topserch-bar {
  .form-control {
    height: 56px;
    background-color: #ededff;
    border-radius: 10px;
    border: none;
    color: #333;
    &::placeholder {
      color: #bdbdbd;
    }
  }
  .list-study {
    select {
      background: url(../assets/images/down-arrow-blue.svg);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 11px;
      background-color: #ededff;
      padding: 6px 30px 6px 15px;
    }
  }
}
.serch-bx-games {
  position: relative;

  .seachbtn {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 56px;
    border-radius: 0px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: var(--purple);

    &:hover {
      background-color: transparent;
      color: var(--purple);
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent;
      color: var(--purple);
    }
  }
  .formbox {
    padding: 6px 50px 6px 15px;
  }
}

select {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.gamestbs-btn {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}
.game-opn-create {
  padding-right: 36px;
  .gamestbs-btn {
    padding: 10px 40px;
  }
}
.games-topserch-bar {
  padding: 50px 0 20px 0;
}
.heading-gamesbtm {
  padding: 10px 0 18px 0;
  h3 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 0px;
    text-align: left;
  }
}

.inner-cardquizz {
  background-color: var(--white);
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 25px;
  .addlist-quiz {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .quiz-leftcard {
      display: inline-flex;
      flex: 1;
    }
    .quiz-rightcard {
      flex: 0 0 20px;
      max-width: 20px;
    }
    .attachment-quiz {
      border: none;
      background-color: transparent;
      padding: 0px;
      width: 30px;
      height: 24px;
      display: inline-block;
    }
    .cursor-pointer {
      cursor: pointer;
    }
  }
  .dropdown-btnn {
    background-color: transparent;
    border: none;
    width: 20px;
    height: 24px;
    padding: 0px;
    &:after {
      display: none;
    }
    &:hover {
      background-color: transparent;
      border: none;
    }
    &:focus {
      background-color: transparent !important;
      border: none;
      outline: none;
      box-shadow: none !important;
    }
  }
  .active {
    background-color: transparent !important;
    border: none;
    outline: none;
    box-shadow: none !important;
  }
}
.quiz-bdy-content-bx {
  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--orange);
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
  h3 {
    font-size: 22px;
    line-height: 20px;
    font-weight: 700;
    color: var(--purple-dark);
    margin-bottom: 0px;
    padding-bottom: 10px;
    height: 80px;
    word-break: break-word;
    line-height: 26px;
  }
  h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: var(--sky);
    margin-bottom: 0px;
    padding-bottom: 8px;
  }
  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--grey);
    margin-bottom: 0px;
    padding-bottom: 15px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--grey-light);
    margin-bottom: 0px;
    max-width: 190px;
    margin: 0 auto;
  }
}
.inner-cardquizz {
  .points-btn {
    padding: 25px 0 5px 0;
    .totalpoints-btn {
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: var(--white);
      background-color: var(--sky);
      border-radius: 11px;
      height: 34px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      // max-width: 96px
    }
  }
}

.addlist-quiz .show > .btn-success.dropdown-toggle {
  background-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.addlist-quiz {
  .btn-listnew {
    .dropdown-btn-item {
      transform: none !important;
      right: 0px !important;
      left: auto !important;
      top: 30px !important;
      background-color: transparent;
      border: none;
    }
  }
}

.minigames-cards {
  padding-bottom: 100px;
}

.add-group-modal2 {
  .mystudent-games {
    .modal-title {
      h5 {
        padding-top: 0px;
      }
    }
  }
}
.publish-matrial {
  .modal-title {
    h4 {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}
.thanks-applictn {
  .btn-delet-bx {
    text-align: center;
    padding-right: 30px;

    .thanxbtn {
      transform: perspective(200px) rotateY(-3deg);
      -moz-transform: perspective(200px) rotateY(-3deg);
      -webkit-transform: perspective(200px) rotateY(-3deg);
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
      max-width: 270px;
      width: 100%;
      padding: 10px 10px;
      border: none;
      margin: 0 auto;
    }
  }
}

.loginmodal {
  .loginform-bx {
    .subsform-fild {
      .newgames-createbx {
        .form-control {
          margin-bottom: 15px;
        }
        .std-point-frm {
          .form-control {
            color: var(--black);
          }
          .form-label {
            padding: 0px;
            margin-bottom: 15px;
            text-align: center;
          }
        }
        .std-point-label {
          text-align: center;
          align-self: center;
        }
      }
      textarea {
        font-weight: 300 !important;
        height: 130px !important;
        resize: none;
      }
    }
  }
}
.mygameflw-tabs {
  .card-quizgames {
    padding-top: 50px;
  }
  .quiz-bdy-content-bx {
    p {
      max-width: 220px;
    }
    h3 {
      padding-bottom: 0px;
    }
    h5 {
      padding-top: 13px;
    }
  }
  .top-list-quiz {
    padding-bottom: 30px;
  }
}

.gameflw-template {
  .top-list-quiz {
    padding-bottom: 25px;
  }
  .quiz-bdy-content-bx {
    padding-bottom: 30px;
  }
}
.learner-listing-games {
  padding: 0px;
  margin: 0px;
  text-align: left;
  li {
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 10px;
    .btn-learner-lsit {
      border: none;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: var(--grey);
      background: rgba(255, 255, 255, 0.59);
      border-radius: 13px;
      height: 52px;
      padding: 5px 30px;
    }
    .active {
      background: var(--orange);
      font-weight: 700;
      color: var(--white);
    }
  }
}
.choose-learning-list {
  padding-bottom: 30px;
}
.mygameflw-tabs,
.gameflw-template {
  .game-opn-create {
    .gamestbs-btn {
      padding: 10px 30px;
    }
    .add-material-btn {
      min-width: 270px !important;
    }
  }
}

.gameflw-contbx {
  .quiz-bdy-content-bx {
    h3 {
      min-height: 60px;
    }
    .type-material-iner {
      min-height: 140px;
    }
  }
}

// start media query

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .games-topserch-bar form .two {
    width: 48%;
  }
  .games-topserch-bar form .three {
    width: 27%;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .games-topserch-bar form .two {
    width: 45%;
  }
  .games-topserch-bar form .three {
    width: 30%;
  }
  .mymateial-main form .gamestbs-btn {
    font-size: 16px;
  }
  .game-opn-create .gamestbs-btn {
    padding: 10px 30px;
    font-size: 16px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}

@media only screen and (max-width: 991px) {
  .educator-headermain .navbar-top {
    padding-top: 20px;
  }
  .desktop-only-shw {
    display: none;
  }
  .mobile-only-shw {
    display: block;
  }
  .educator-headermain .center-navbar .educator-menubar {
    padding: 0 15px !important;
    padding-top: 15px !important;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .games-topserch-bar form .two {
    width: 35%;
  }
  .games-topserch-bar form .three {
    width: 40%;
  }
  .game-opn-create .gamestbs-btn {
    padding: 10px 10px;
    font-size: 16px;
    width: 210px;
  }
  .game-opn-create .add-material-btn {
    width: 230px !important;
  }
  .mymateial-main .game-opn-create .gamestbs-btn {
    padding: 10px 10px !important;
  }
  .mygameflw-tabs .game-opn-create .gamestbs-btn,
  .gameflw-template .game-opn-create .gamestbs-btn {
    padding: 10px 10px;
  }
  .games-topserch-bar {
    padding: 30px 0 20px 0;
  }
  .card-quizgames .onecard {
    width: 33.33%;
  }
}

@media only screen and (max-width: 767px) {
  .games-topserch-bar form .one,
  .games-topserch-bar form .two {
    padding-bottom: 10px;
  }
  .games-topserch-bar form .three {
    padding-top: 10px;
    text-align: left;
  }
  .games-topserch-bar .game-opn-create .gamestbs-btn {
    padding: 8px 20px;
    font-size: 16px;
  }
  .games-topserch-bar {
    padding: 30px 0 20px 0;
  }
  .games-topserch-bar .form-control {
    height: 45px;
  }
  .games-topserch-bar .serch-bx-games .seachbtn {
    height: 45px;
    font-size: 17px;
  }
  .minigames-cards {
    padding-bottom: 40px;
  }
  .learner-listing-games li .btn-learner-lsit {
    height: 45px;
    padding: 5px 15px;
  }
}

.cancel-confirm-modal {
  h4 {
    color: var(--purple-dark);
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
  }
  p {
    color: var(--grey);
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 16px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .cancl-lession-btn {
    margin-bottom: 16px;
  }
  .go-back-btn {
    text-align: center;
  }
  .loginform-bx {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
