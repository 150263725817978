.zommnone
{
    animation-name: none;
    position: relative;
    top: -50px;
    z-index: 9;
}

.teahers-slider
{

.home-sliderbx 
{
    .slider-title
    {
        max-width: 100%;
        padding: 30px 0;

        h1 
        {
            padding-bottom: 50px;
        }
        p 
        {
            max-width: 410px;
            padding-bottom: 50px;
        }
    }

}
.btn-week
{
    .btn-title
    {
        text-transform: uppercase;
    }
}

}


.first-month
{

        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        text-align: center;     
        color: #E0E0E0;
        margin: 0px;
        font-family: 'Mulish', sans-serif;
        max-width: 200px;
        margin-top: 20px;

}
.student-section
{

.kids-better-box 
{
    .kids-main-bx
    {
        .custom-row 
        {
            .left-pix-card
            {
                flex: 0 0 20%;
                max-width: 20%;
            }
            .kids-right
            {
                flex: 0 0 80%;
                max-width: 80%;
            }
        }
        .kids-pic2
        {
            position: relative;
            top: 60px;
            left: -70px;

            img 
            {
                max-width: 170px;
                width: 100%;
            }

        }
        .kids-content
        {
            padding: 60px 80px 60px 0px;
            h4 
            {
                max-width: 700px;
            }
            p 
            {
                max-width: 800px;
            }
            h6 
            {
                
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.04em;
                color: var(--purple);
                margin: 0px;
                max-width: 800px;
            }
        }
    }
}

.bgshpe
{
    transform: rotateX( -12deg) rotateY(-1deg);
    -webkit-transform: rotateX( -12deg) rotateY(-1deg);
    -moz-transform: rotateX( -12deg) rotateY(-1deg);
    left: -20px;
}
.kids-better-box
{
    padding-bottom: 40px;
    top: 50px;
}

}

.count-subj
{
    .listing-count
    {
        padding: 0px;
        margin: 0px;
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        li 
        {
            padding: 0px;
            margin: 0px;
            list-style: none;
            display: inline-block;
            text-align: left;
            flex: 0 0 220px;
            max-width: 220px;

           &:nth-child(even)
           {
            .list-counter 
            {
                .shapebx-count
                {
                    // transform: perspective(100px) rotateX( -3deg) rotateY( 6deg);
                    // transform: perspective(100px) rotateX( -3deg) rotateY( 6deg);
                    display: block;
                    transform: rotateX(-7deg) rotateY(7deg);
                    -webkit-transform: rotateX(-7deg) rotateY(7deg);
                    -moz-transform: rotateX(-7deg) rotateY(7deg);
                    transform-style: preserve-3d;
                    transform-origin:0;
                    -webkit-transform-origin:0;
                    left: 20px;
                }
            }
           } 
           &:nth-child(odd)
           {
                margin-top: 40px;
           }

            .list-counter
            {
                height: 150px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                position: relative;
                z-index: 9;
                perspective: 130px;
                .shapebx-count
                {
                    position: absolute;
                    top: -6px;
                    left: 10px;
                    width: 100%;
                    height: 100%;
                    z-index: -1000;
                    // transform: perspective(100px) rotateX(4deg) rotateY(-6deg);
                    // -webkit-transform: perspective(100px) rotateX(4deg) rotateY(0deg);
                    background: #FFFFFF;
                    opacity: 0.2;
                    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30);
                    border-radius: 18px;
                    max-width: 170px;
                    display:block;
                    transform: rotateX(7deg) rotateY(-6deg);
                    -webkit-transform: rotateX(7deg) rotateY(-6deg);
                    -moz-transform: rotateX(7deg) rotateY(-6deg);
                    transform-style: preserve-3d;
                    transform-origin:0;
                    -webkit-transform-origin:0;
                }


            }

            h4 
            {
                font-weight: 700;
                font-size: 52px;
                line-height: 55px;
                text-align: left;
                margin-bottom: 2px;
                color: var(--yellow-dark);
                // font-family: 'Mulish', sans-serif;
            }
            h6 
            {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.04em;
                color: #FFFFFF;
            }
        }
    }

}

.count-section {
    padding: 150px 0;
}

.become-tutor
{
    .custom-row
    {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 100%;
        max-width: 100%;
        align-items: center;

        .tutor-leftbx
        {
            flex: 0 0 300px;
             max-width: 300px;
             position: relative;
             z-index: 9;

             .left-boxpic
             {
                position: relative;
                width: 100%;
                height: 410px;
                z-index: 9;

                .shape-bx-pic
                {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    z-index: -1000;
                    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.30);
                    border-radius: 18px;
                    transform: perspective(1000px) rotateX( -13deg) rotateY( 19deg);
                    -webkit-transform: perspective(1000px) rotateX( 0deg) rotateY( 19deg);
                    display: block;
                    transform-origin: 0% 0%;

                }
                .girlpic
                {
                    position: absolute;
                    z-index: 9;
                    top: -130px;
                    margin: 0 auto;
                    left: 0px;
                    right: 0px;

                    
                }

             }

        }
        .rightbx-tutor
        {
            flex: 0 0 calc(100% - 300px);
             max-width: calc(100% - 300px); 

             .tuttor-content
             {
                 text-align: left;
                 padding: 0 60px 0 80px;
                 h4 
                 {
                    font-weight: 700;
                    font-size: 44px;
                    line-height: 56px;
                    letter-spacing: 0.04em;
                    color: #FFFFFF;
                    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    margin:0px;
                    padding-bottom: 25px;
                 }
                 p 
                 {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.04em;
                    margin:0px;
                    padding-bottom: 30px;
                    color: #FFFFFF;
                 }
                 h6 
                 {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.04em;
                    margin:0px;
                    padding-bottom: 0px;
                    color: #FFFFFF;
                    max-width: 600px;
                 }
             }

        }
    }
    .btn-buy
    {
        transform: translateY(60px);
    }
}

.tutot-box
{
    position: relative;
    max-width: 1160px;
    margin: 0 auto;
    padding: 30px 0 0px 0px;
    z-index: 9;

    .shape-tutor-right
    {
        position: absolute;
        top: 0px;
        right: 30px;
        width: 100%;
        height: 100%;
        background: radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.177) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #632CAF;
        border-radius: 37px;
        z-index: -1000;
        // transform: perspective(1000px) rotateX( 0deg) rotateY( -8deg);
        // -webkit-transform: perspective(1000px) rotateX( 0deg) rotateY( -8deg);
        display: block;
        transform: rotateX(-15deg) rotateY(-7deg);
        -webkit-transform: rotateX(-15deg) rotateY(-7deg);
        -moz-transform: rotateX(-15deg) rotateY(-7deg);
        transform-style: preserve-3d;
        transform-origin: 0;
        -webkit-transform-origin: 0;
    }
}

.tutr-shape-outer
{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 90%;
    height: 100%;
    perspective: 1000px;
    z-index: -1000;
}

.communty-section
{
    max-width: 750px;
    margin: 0 auto;
    h4 
    {
        margin: 0px;
        padding-bottom: 15px;
    }
    h6 
    {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: var(--yellow);
        margin: 0px;
        padding-bottom: 20px;
    }
    p 
    {
        color: #fff;
        margin: 0px;
    }
}

.communty-box
{
    position: relative;
    max-width: 1080px;
    margin: 0 auto;
    padding: 50px 30px 0 30px;
    z-index: 1;

    &:before 
    {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        opacity: 0.1;
        border-radius: 37px;
        transform: perspective(1000px) rotateX(15deg) rotateY(0deg);
        z-index: -1;
    
    }
    .btn-buy
    {
        transform: translateY(30px);
        padding-top: 60px;
    }

}
.tutor-becm
{
    .btnweak-bx
    {
        padding: 13px 30px;
    }
}

.plan-works
{
    .best-plan 
    {
        .heading-top 
        {
            max-width: 650px;
            margin: 0 auto;
        }
    }
}

.plan-works
{
    .small-tex
    {
        padding-bottom: 20px;
    max-width: 280px;
    margin: 0 auto;
    color: var(--purple);
    }
    .card-descript-paln
    {
        min-height: 60px;
    }
    .btnweak-bx
    {
        padding: 13px 20px;
        font-size: 16px;
        line-height: 20px;
    }
}

.testimonial-eduction
{
    .testi-main
    
    {
        .heading-top
        {
            max-width: 440px;
        margin: 0 auto;
        
        .title-medium
        {
            text-align: center;
        }
        }
    }
    
}

.cardbox-tutor img{
    border: none !important;
}

.cardsection-int
{
    .custom-row
    {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 100%;
        max-width: 100%;
        align-items: center;

        .left-cardint
        {
            flex: 0 0 40%;
            max-width: 40%;
        }
        .right-cardint
        {
            flex: 0 0 60%;
            max-width: 60%;
            text-align: left;
            
            .inti-content
            {
                padding-left: 30px;
                padding-right: 30px;
            }
        }

    }
    .tagbtn
    {
        .tagname
        {
            background: var(--yellow);
            border-radius: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
           color: var(--purple);
           padding: 6px 15px;
        }
    }
    h4 
    {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.04em;
        text-transform: capitalize;
        color: var(--purple);
        margin: 0px;
        padding: 10px 0 15px 0;

    }
    .small-tex
    {
        color: var(--purple);
        padding-bottom: 15px;
        margin: 0px;
    }
    .date-inti
    {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #A8A8A8;
    }
}
.cardsection-int {
    position: relative;
    padding: 20px 0;
    z-index: 9;
    perspective: 1000px;
    &:before 
    {
    content: "";
    top: 0px;
    right: 0px;
    width: 85%;
    height: 100%;
    position: absolute;
    z-index: -1000;
    background: #FFFFFF;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    // transform: perspective(1000px) rotateX(-14deg) rotateY(2deg);
    // -webkit-transform: perspective(1000px) rotateX(-14deg) rotateY(2deg);
    display: block;
    transform: rotateX(-13deg) rotateY(2deg);
    -webkit-transform: rotateX(-13deg) rotateY(2deg);
    -moz-transform: rotateX(-13deg) rotateY(2deg);
    transform-style: preserve-3d;
    transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
    }
    .cardpic-int
    {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
}

.intersting-you
{
    padding: 150px 0 150px 0;
    .custome-container
    {
        max-width: 1400px;

        .cardsection-int
        {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
        padding-bottom: 30px;
        }
    }
    .heading-top
    {
        padding-bottom: 40px;
    }
}

.communty-pot {
    padding: 100px 0 50px 0;
}

.useful-tech
{
    &:before
    {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        background: url(../assets/images/vectorbg4.png);
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
        background-position-y: -380px;
        background-size: cover;
    }

    .kids-better
    {
        padding-bottom: 180px;
    }

}


.serching-tutor-main
{
    &:before 
    {
        content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: url(../assets/images/vectorbg5.png);
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    background-position-y: 130px;
    background-size: cover;
    }
}

.serch-tutor-testi
{
    .slider-innercard
    {
        h5 
        {
            font-weight: 600;
            font-size: 22px;
            // line-height: 20px;
            // letter-spacing: 0.04em;
            color: #fff;
            text-align: center;
        }
    }
    .slider-cardbox
    {
        padding: 0px;;
    }

    .farme-testibx
    {
        position: relative;
            height: 200px;
            z-index: 1;
        &:before 
        {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3);
            border-radius: 18px;
            z-index: -1;
            background: #fff;
        }

    }

}

.cardbox-tutor
{
    position: relative;
    z-index: 1;
    height: 200px;
    border-radius: 18px;
    z-index: -1;
    transform: perspective(1000px) rotateX(0deg) rotateY(-19deg) scale(0.9);

    // &:before 
    // {
    //     content: "";
    //     position: absolute;
    //     top: 0px;
    //     left: 0px;
    //     width: 100%;
    //     height: 100%;
      
    // }
}

.slick-slide:nth-child(even){
    .cardbox-tutor {
        transform: perspective(1000px) rotateX(0deg) rotateY(19deg) scale(0.9);
    }
}

.slick-current{
    .cardbox-tutor{
        transform: perspective(1000px) rotateX(0deg) rotateY(-19deg) scale(1.1) !important;
    }
}

.testi-boxserch
{
    h5 
    {
        font-weight: 600;
        font-size: 22px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #fff;
        text-align: center;
        // padding: 20px 0 10px 0;
    }

    .slider-cardbox
    {
        padding: 20px 30px; 
    }

}
.testi-boxserch .slick-next, .testi-boxserch .slick-prev
{
    width: 50px;
    height: 50px;
    margin-top: -20px;
}
.testi-boxserch .slick-next
{
    right: 40%;
    z-index: 9;
}
.testi-boxserch .slick-prev
{
    left: 40%;
    z-index: 9;
}

.serching-testimonial
{
    padding: 100px 0 50px 0;
    .heading-top
    {
        padding-bottom: 50px;
    }
}

.become-tutor
{
    padding: 0 20px;
}

.best-plan-card
{
    .btn-buy 
    {
        .btn-week 
        {
            perspective: 1000px;
        }
    }
}


.plan-works
{
    .best-plan-card 
{
    .plan-card 
    {
        .work-plain-listt
        {
            ul 
            {
                max-width: 300px;
                li 
                {
                    .list-detail-plan
                    {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                    }
                }
            }
        }
        .card-descript-paln
        {
            color: #FF7A00;
        }
    }
}
.descript-month
{
    display: block;
    padding-top: 5px;
    color: var(--purple);
}

.best-plan-card
    {
        .btn-buy 
        {
            .btn-week {
                perspective: 250px;
                margin-right: 30px;
            }
        }
    }



}
