
@mixin transform($transform...) {
    -moz-transform:    $transform;
    -o-transform:      $transform;
    -webkit-transform: $transform;
    transform:         $transform;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
.menuside {
    position: fixed;
    left: 0px;
    top: 0px;
    max-width: 450px;
    height: 100vh;
    width: 100%;
    background-color:var(--purple);
    color: #fff;
    padding: 0 15px 20px 15px;
   
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    @include transition(all 300ms ease-in-out);
    z-index: 999;
   

}
.showmenu
{
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0); 
}

.sidemenubar-list
{
    .list-sidemenu 
    {
        padding: 0px;
        margin: 0px;

        li 
        {
            padding: 0px 0 20px 0;
        margin: 0px;
        list-style: none;
            .sidemenu-tex
            {
                text-decoration: none;
                font-size: 17px;
                line-height: 20px;
                text-align: center;
                font-weight: 400;
                color: var(--white);

                &:hover 
                {
                    color: var(--yellow-dark);
                }            
            }
            .active 
                {
                    font-size: 20px;
                    font-weight: 700;
                    color: var(--yellow-dark);
                }
        }
    }

}
.sidemenubar-main
{
    .logobar-top
    {
        padding-bottom:80px;
        position: relative;
        max-width: 400px;
        margin: 0 auto;
        margin-top: 50px;
    }
    .close-sidebar
    {
        position: absolute;
        top: 6px;
        left: 0px;
        z-index: 9;

        .btnclose
        {
            background-color: transparent;
            box-shadow: none;
            border: none;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
        }
    }
    .logoimg 
    {
        img 
        {
            max-width: 227px;
        }
    }
}

.filter-bartop
{
    .filterbar-container
    {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 10px;

        .filterbar-list
        {
            padding-bottom: 30px;
        }
        .filterbar-list
        {
            .profile-menubar
            {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 100%;
                max-width: 100%;
                flex-wrap: wrap;

                li 
                {
                    padding-bottom: 10px;
                }
            }
        }
    }
}

.openheader-dashbd 
{
    overflow: hidden;
}
// .openheader-dashbd .header-holder.navbar-top 
// {
//     filter: blur(10px);
// }
.top-annoucebar
{
    background-color: var(--orange);
    padding: 22px 0;

    .user-topbar-contain
    {
        max-width: 1350px;
        margin: 0 auto;
        padding: 0 10px;
    }

}
.user-topbar-box
{
    .customrow
    {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        line-height: 1;

        .leftbox-user-box, .center-user-box, .right-user-box
        {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }

        .user-educt-content
        {
            text-align: left;

            .title-boxx
            {
                font-weight: 800;
                font-size: 20px;
                line-height: 24px;
                color: #f2f2f2;
                text-transform: uppercase;
            }
        }
        .right-user-box
        {
            text-align: right;
            .free-lesson
            {
                display: flex;
                align-items: center;
                line-height: 1;
                flex-wrap: wrap;
                .free-lesson-content
                {
                    flex: 1;
                    text-align: left;

                    .free-lesson-tex
                    {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 400;
                        color: #f2f2f2;
                        padding-right: 5px;
                        .texbld
                        {
                            font-weight: 800;
                        }
                    }

                }
                .btn-rightbx
                {
                    flex: 0 0 160px;
                    max-width: 160px;
                }
            }

        }

    }

}

.learn-more-bttn
{
    .btn-learn-blue
    {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--purple);
        border-radius: 12px;
        height: 56px;
        color: var(--white);
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-decoration: none;
    }
}
.user-topbar-box
{
    .timer-card
    {
        ul 
        {
            padding: 0px 5px;
            margin: 0px;

            li 
            {
                padding: 0px 4px;
                margin: 0px;
                list-style: none;
                display: inline-block;

                .timer-counter-new {
                    width: 75px;
                    height: 56px;
                    background-color: var(--white);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding: 6px;
                    span 
                    {
                        display: block;
                        width: 100%;
                    }
                    .timer-numm
                    {
                        font-weight: 800;
                        font-size: 32px;
                        line-height: 22px;
                        color: #632CAF;
                    }
                    .timer-day
                    {
                        font-size: 10px;
                        line-height: 12px;
                        font-weight: 400;
                        color: var(--black);
                    }
                   
                }

            }

        }
    }
}

.blur-bg
{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height:100%;
    background-color: rgba(0,0, 0, 0.40);
    z-index: 999;
    display: none;
}
.openheader-dashbd
{
    .blur-bg
    {
        display: block;
    }
    
}
.openheader-dashbd
{
     .top-annoucebar, .dashboard-topbarr, .dasboard-iiner, .blur-bgg 
     {
        -webkit-filter: blur(3px);
        -ms-filter: blur(3px);
        -o-filter: blur(3px);
        filter: blur(3px);
    }
}



@media only screen and (min-width:992px){
.filter-bartop
{
    display: none;
}

}    

@media only screen and (max-width:991px){

    .user-topbar-box .timer-card ul li .timer-counter-new
    {
        width: 65px;
        height: 56px;
    }
    .user-topbar-box .timer-card ul li .timer-counter-new .timer-numm
    {
        font-size: 28px;
    }
    .user-topbar-box .customrow .right-user-box .free-lesson .free-lesson-content
    {
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 8px;
    }
    .top-annoucebar
    {
        padding: 15px 0;
    }
}

@media only screen and (max-width:767px){

    .user-topbar-box .customrow .leftbox-user-box, .user-topbar-box .customrow .center-user-box, .user-topbar-box .customrow .right-user-box
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .user-topbar-box .customrow .right-user-box .free-lesson .free-lesson-content
    {
        text-align: center;
    }
    .user-topbar-box .customrow .right-user-box .free-lesson .btn-rightbx
    {
        margin: 0 auto;
    }
    .user-topbar-box .customrow .user-educt-content {
        text-align: center;
    }

    .user-topbar-box .timer-card ul {
        padding: 10px 5px;
        margin: 0px;
    }
    .user-topbar-box .customrow .user-educt-content .title-boxx
    {
        font-size: 16px;
        line-height: 20px;
    }
    .user-topbar-box .customrow .right-user-box .free-lesson .free-lesson-content .free-lesson-tex
    {
        font-size: 14px;
    line-height: 20px;
    }
    .learn-more-bttn .btn-learn-blue
    {
        height: 48px;
    }
    .user-topbar-box .timer-card ul li .timer-counter-new {
        width: 65px;
        height: 50px;
    }

}
