@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero-box {
  background: var(--primary-gradient);
  min-height: 800px;
  box-shadow: 0px 19px 56px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 9;
}

.navbar-top {
  padding-top: 40px;
  position: relative;
  z-index: 99;

  .navbar-collapse {
    margin-left: 5%;
  }

  .bg-light {
    background-color: transparent !important;
    padding: 0px;
  }

  .navbar-collapse {
    position: relative;
    max-width: 1058px;
    z-index: 9;
    perspective: 1200px;
    //  background-image: url('../assets/images/home-top.svg');
    //  background-repeat: no-repeat;
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: 100%;

      //  transform: perspective(40em) rotateX(-20deg);
      //  -webkit-transform: perspective(40em) rotateX(-20deg);
      background: linear-gradient(180deg, #e6c5ff 0%, #f7f7f7 100%);
      border-radius: 18px;
      box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.25);
      transform: rotateX(-20deg);
      -webkit-transform: rotateX(-20deg);
      transform-style: preserve-3d;
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      //  transform-origin: 0% 0%;
      //  -webkit-transform-origin: 0% 0%;
      display: block;
      z-index: -1000;
    }
    &:after {
      content: "";
      position: absolute;
      top: 4px;
      margin: 0 auto;
      left: 0px;
      right: 0px;
      width: calc(100% - 10px);
      height: calc(100% - 8px);
      // transform: perspective(40em) rotateX(-20deg);
      // -webkit-transform: perspective(40em) rotateX(-20deg);
      background: linear-gradient(
        179.71deg,
        #fffefe 0.26%,
        rgba(255, 255, 255, 0.2) 45.19%
      );
      opacity: 0.3;
      box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      transform: rotateX(-20deg);
      -webkit-transform: rotateX(-20deg);
      transform-style: preserve-3d;
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      // transform-origin: 0% 0%;
      // -webkit-transform-origin: 0% 0%;
      display: block;
      z-index: -1000;
    }

    .navbar-nav {
      z-index: 1;
      width: 100%;
      justify-content: space-around;
      padding: 0 15px;
      li {
        padding: 0 6px;
        a {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: var(--purple);
          padding: 17px 30px;
          position: relative;
          display: block;
          text-decoration: none;
          perspective: 100px;

          &:hover {
            // text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            color: var(--yellow);
            font-weight: 500;
            // &:before
            // {
            //     opacity: 0;
            //     visibility: visible;
            // }
          }
          // &:before
          // {
          //     content: "";
          //     position: absolute;
          //     top: -2px;
          //     left: 0px;
          //     right: 0px;
          //     width: 100%;
          //     height: 100%;
          //     background:var(--yellow);
          //     box-shadow: inset 0px 0px 9px rgba(0, 0, 0, 0.39);
          //     z-index: -1;
          //     transform: perspective(10px) rotateX(0.5deg);
          //     -webkit-transform: perspective(10px) rotateX(0.5deg);
          //     opacity: 0;
          //     visibility: hidden;
          //     transform-origin: 0% 0%;
          //     -webkit-transform-origin: 0% 0%;
          //     display: block;
          //     z-index: -1000;
          // }
        }
        .active {
          font-weight: 700;
          position: relative !important;
          color: var(--purple);
          text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
          &:before {
            content: "";
            position: absolute;
            top: -2px;
            left: 0px;
            right: 0px;
            width: 100%;
            height: 96%;
            background: var(--yellow);
            box-shadow: inset 0px 0px 9px rgba(0, 0, 0, 0.39);
            z-index: -1;
            // transform: perspective(10px) rotateX(0.5deg);
            // -webkit-transform: perspective(10px) rotateX(0.5deg);
            opacity: 1;
            visibility: visible;
            transform: rotateX(8deg);
            -webkit-transform: rotateX(8deg);
            -moz-transform: rotateX(8deg);
            // transform-style: preserve-3d;
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            // transform-origin: 0% 0%;
            // -webkit-transform-origin: 0% 0%;
            display: block;
          }
          &:hover {
            font-weight: 700;

            color: var(--purple);
            text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
.header-btn-top {
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    display: inline-block;
  }
  .btn-bx-get {
    padding-right: 30px;
    padding-left: 15px;
  }
}
.get-started {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-decoration: none;
  padding: 13px 40px;
  border-radius: 14px;
  display: inline-block;
  transform: perspective(100px) rotateY(-6deg);
  -webkit-transform: perspective(100px) rotateY(-6deg);
  -moz-transform: perspective(100px) rotateY(-6deg);
  color: var(--purple);
  background: linear-gradient(0deg, #ffc700, #ffc700),
    radial-gradient(
      63.69% 56.22% at 25.75% 17.94%,
      rgba(255, 255, 255, 0.413) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #75e7e8;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  border-radius: 14px;

  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3),
    0px 0px 0px 5px rgba(255, 255, 255, 0.25);
  position: relative;

  //     &:before
  //     {
  //         content: "";
  //         position: absolute;
  //         top: 0px;
  //         left: 0px;
  //         right: 0px;
  //         margin: 0 auto;
  //         width: 100%;
  //         height: 100%;
  //         background: #FFFFFF;
  //         opacity: 0.1;
  //     }
  &::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: linear-gradient(
      181.68deg,
      #ffffff -19.86%,
      rgba(255, 255, 255, 0.19) 100%
    );
    opacity: 0.2;
    border-radius: 14px;
    z-index: -1;
    margin: 0 auto;
    right: 0px;
  }

  &:hover {
    color: var(--purple);
    background: linear-gradient(0deg, #ffc700, #ffd234),
      radial-gradient(
        63.69% 56.22% at 25.75% 17.94%,
        rgba(255, 255, 255, 0.413) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      ),
      #75e7e8;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.1),
      0px 0px 0px 5px rgba(255, 255, 255, 0.25);
  }
}

.btn-login-bx {
  .login-btn {
    width: 56px;
    height: 56px;
    background: radial-gradient(
        89.85% 73.57% at 84.21% 75.36%,
        rgba(0, 0, 0, 0.23) 0%,
        rgba(0, 0, 0, 0) 86.18%
      ),
      radial-gradient(
        63.69% 56.22% at 25.75% 17.94%,
        rgba(255, 255, 255, 0.413) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      ),
      #632caf;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    // box-shadow: 0px 0px 0px 5px rgba(255, 255, 255,0.10);
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3),
      0px 0px 0px 5px rgba(255, 255, 255, 0.25);
    border: none;

    &:focus {
      box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3),
        0px 0px 0px 5px rgba(255, 255, 255, 0.25) !important;
    }
    &:hover {
      background: radial-gradient(
          89.85% 73.57% at 84.21% 75.36%,
          rgba(0, 0, 0, 0.23) 0%,
          rgba(0, 0, 0, 0) 86.18%
        ),
        radial-gradient(
          63.69% 56.22% at 25.75% 17.94%,
          rgba(255, 255, 255, 0.413) 0%,
          rgba(255, 255, 255, 0) 69.79%,
          rgba(255, 255, 255, 0) 100%
        ),
        #632caf;
      border-color: transparent;
    }

    i {
      width: 25px;
      height: 25px;
      box-shadow: 0px 7px 8px -5px rgba(0, 0, 0, 0.25);
      line-height: 25px;
    }
  }
}

.btn-login-bx .show > .btn-success.dropdown-toggle {
  background: radial-gradient(
      89.85% 73.57% at 84.21% 75.36%,
      rgba(0, 0, 0, 0.23) 0%,
      rgba(0, 0, 0, 0) 86.18%
    ),
    radial-gradient(
      63.69% 56.22% at 25.75% 17.94%,
      rgba(255, 255, 255, 0.413) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #632caf;
  border-color: transparent;
}

.home-sliderbx {
  .slider-title {
    text-align: left;
    h1 {
      font-size: 62px;
      line-height: 65px;
      letter-spacing: 0.04em;
      color: #ffffff;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1%);
      text-align: left;
      font-weight: 700;
      margin-bottom: 0px;
      padding-bottom: 30px;
    }
    p {
      font-weight: 500;
      font-size: 22px;
      line-height: 31px;
      letter-spacing: 0.04em;
      color: #ffffff;
      margin-bottom: 0px;
      padding-bottom: 20px;
    }
  }
}
.item-center {
  align-items: center;
}

.banner-holder {
  .right-box-slider {
    position: relative;

    .pic1-vector {
      position: absolute;
      top: 0px;
      left: 0px;
      top: 60px;
      left: 0px;
      width: 100%;
    }
  }
}

.playbtn {
  font-weight: 700;

  text-decoration: none;
  padding: 15px 40px;
  border-radius: 14px;
  display: inline-block;
  transform: perspective(100px) rotateY(-5deg);
  -webkit-transform: perspective(100px) rotateY(-5deg);
  -moz-transform: perspective(100px) rotateY(-5deg);
  color: var(--purple);
  background: radial-gradient(
      89.66% 75.31% at 55.08% 86.94%,
      rgba(232, 45, 45, 0.55) 0%,
      rgba(253, 203, 27, 0) 86.18%
    ),
    radial-gradient(
      63.69% 56.22% at 25.75% 17.94%,
      rgba(255, 255, 255, 0.413) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ffc700;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  max-width: 210px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  color: #6a64e6;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3),
    0px 0px 0px 5px rgba(255, 255, 255, 0.25),
    3px 36px 30px -25px rgba(0, 0, 0, 0.7);
  transition: all 300ms ease-in-out;

  &:hover {
    color: #6a64e6;
    background: radial-gradient(
        89.66% 75.31% at 55.08% 86.94%,
        rgba(232, 45, 45, 0.55) 30%,
        rgba(253, 203, 27, 0) 86.18%
      ),
      radial-gradient(
        63.69% 56.22% at 25.75% 17.94%,
        rgba(255, 255, 255, 0.413) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      ),
      #ffd029;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3),
      0px 0px 0px 5px rgba(255, 255, 255, 0.25),
      3px 36px 30px -25px rgba(0, 0, 0, 0.1);
  }
}
.btn-login-bx {
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}

.btn-login-bx {
  .dropdown-menu {
    margin: 0px;
    border-radius: 10px;
    border: none;
    // transform: perspective(100px) rotateY(-4deg);
    // -webkit-transform: perspective(100px) rotateY(-4deg);
    // -moz-transform: perspective(100px) rotateY(-4deg);
    right: 0px;
    margin-top: 10px !important;
  }
}

.scaleup-anm {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: scaleup;
  -moz-animation-name: scaleup;
}

.pic1-vector {
  .scaleup-anm {
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-name: scaleup;
    -moz-animation-name: scaleup;
  }
}

@-webkit-keyframes scaleup {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes scaleup {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
.pic1 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: zommin-bx;
  -moz-animation-name: zommin-bx;
}

@-webkit-keyframes zommin-bx {
  0% {
    transform: scale(1.04);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.04);
  }
}
@keyframes zommin-bx {
  0% {
    transform: scale(1.04);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.04);
  }
}
.home-sliderbx {
  .btn-playbx {
    margin-top: 20px;
  }
}

.kids-better {
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  box-shadow: 0px -7px 59px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 1;
  padding-top: 400px;
  padding-bottom: 300px;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: url("../assets/images/ui-shape1.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    background-position-y: -230px;
    background-size: cover;
  }
}
.kids-section-uper {
  max-height: 250px;
}

.kids-better-box {
  position: relative;
  z-index: 1;
  max-width: 1100px;
  margin: 0 auto;
  top: 80px;
  perspective: 1000px;
  .kids-main-bx {
    .custom-row {
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      flex-wrap: wrap;

      .left-pix-card {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .kids-right {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }

    .kids-pic2 {
      position: absolute;
      top: -90px;
      left: 0px;
    }
    .kids-pic1 {
      position: absolute;
      left: -100px;
      top: 60px;

      .pic1 {
        max-width: 350px;
      }
    }
    .kids-content {
      text-align: left;
      padding: 60px 80px 50px 0px;

      h4 {
        font-weight: 700;
        font-size: 34px;
        line-height: 39px;
        letter-spacing: 0.04em;
        color: var(--purple);
        max-width: 340px;
        padding-bottom: 30px;
        margin-bottom: 0px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: var(--purple);
        margin-bottom: 0px;
        padding-bottom: 20px;
        max-width: 550px;
      }
      .btn-week {
        margin-top: 10px;
        transform: translateY(40px);
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
      }
    }
  }
}
.bgshpe {
  background: #fff;

  box-shadow: 0px 19px 36px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  // transform: perspective(1000px) rotateX(-6deg);
  // -webkit-transform: perspective(1000px) rotateX(-6deg);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  transform: rotateX(-12deg);
  -webkit-transform: rotateX(-12deg);
  -moz-transform: rotateX(-12deg);
  transform-style: preserve-3d;
  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  display: block;
  z-index: -1000;
}
.btnweak-bx {
  display: inline-block;
  -webkit-transform: perspective(100px) rotateY(-3deg);
  transform: perspective(100px) rotateY(-3deg);
  -moz-transform: perspective(100px) rotateY(-3deg);
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-decoration: none;
  padding: 13px 40px;
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3),
    0px 0px 0px 5px rgba(255, 255, 255, 0.25),
    inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  background: radial-gradient(
      89.85% 73.57% at 84.21% 75.36%,
      rgba(240, 52, 52, 0.23) 0%,
      rgba(241, 49, 49, 0) 86.18%
    ),
    radial-gradient(
      63.69% 56.22% at 25.75% 17.94%,
      rgba(255, 255, 255, 0.413) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ffc700;
  border-radius: 14px;
  color: var(--purple);
  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: linear-gradient(
      181.68deg,
      #ffffff -19.86%,
      rgba(255, 255, 255, 0.19) 100%
    );
    opacity: 0.2;
    border-radius: 14px;
    z-index: 0;
    margin: 0 auto;
    right: 0px;
  }
  &:hover {
    color: #fff;
    background: radial-gradient(
        89.66% 75.31% at 55.08% 86.94%,
        rgba(232, 45, 45, 0.55) 0%,
        rgba(253, 203, 27, 0) 86.18%
      ),
      radial-gradient(
        63.69% 56.22% at 25.75% 17.94%,
        rgba(255, 255, 255, 0.413) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      ),
      #ff8a00;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.18),
      0px 0px 0px 5px rgba(255, 255, 255, 0.25),
      inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  .btn-title {
    position: relative;
  }
}
.cardbx-left {
  padding-bottom: 25px;
  .cardbx-inner {
    position: relative;
    max-width: 525px;
    padding: 10px 10px 20px 10px;
    z-index: 9;
    perspective: 1000px;
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 20px;
      right: 0px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 8px 49px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      // transform: perspective(100px) rotateY(1deg) rotateX(-3deg);
      // -webit-transform: perspective(100px) rotateY(1deg) rotateX(-3deg);
      background: #fff;
      z-index: -1000;
      display: block;
      transform: rotateX(-18deg) rotateY(8deg);
      -webkit-transform: rotateX(-18deg) rotateY(8deg);
      -moz-transform: rotateX(-18deg) rotateY(8deg);
      transform-style: preserve-3d;
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      transition: all 300ms ease-in-out;
    }
    &:hover {
      &:before {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 8px 49px rgba(0, 0, 0, 0.25);
        transition: all 300ms ease-in-out;
      }
    }

    .card-row {
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      max-width: 100%;
      flex-wrap: wrap;

      .card-pic {
        flex: 0 0 150px;
        max-width: 150px;
        margin-left: -50px;
      }
      .right-content-card {
        flex: 0 0 calc(100% - 150px);
        max-width: calc(100% - 150px);
        text-align: left;
        padding-left: 20px;
      }
    }
    .right-content-card {
      p {
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.04em;
        color: var(--purple);
      }
    }
  }
}

.heading-top {
  .title-medium {
    font-weight: bold;
    font-size: 44px;
    line-height: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
  }
}
.why-illumiya {
  .heading-top {
    padding-bottom: 80px;
  }
}
.cardpic-works {
  img {
    max-width: 200px;
  }
}
.card-body-works {
  .smal-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 0px;
    letter-spacing: 0.04em;
    color: #fff;
    padding-bottom: 10px;
  }
  p {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    max-width: 200px;
  }
}
.cards-works-bx {
  text-align: left;
  padding-bottom: 20px;
}
.it-works {
  padding: 100px 0 50px 0;
  .works-card {
    padding-top: 30px;
  }
}
.works-card {
  .two,
  .four {
    .cards-works-bx {
      margin-top: 120px;
    }
  }
}

.rockt-icon {
  position: absolute;
  right: 7%;
  bottom: 30%;
  z-index: -2;
}
.video-section {
  position: relative;
  background: linear-gradient(
    143.54deg,
    #ac88de -20.63%,
    #8548da -17%,
    #4c85f4 69.57%,
    #556bbb 102.97%,
    #cc0f97 137.84%
  );
  box-shadow: 0px 19px 56px rgba(0, 0, 0, 0.25);
  z-index: 9;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: url("../assets/images/vectorbg2.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    background-position-y: 130px;
    background-size: cover;
  }
}

.videobx-main {
  .video-conatin {
    max-width: 1100px;
    margin: 0 auto;
  }
  .video-custom {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
    .video-left {
      max-width: 260px;
      flex: 0 0 260px;
      margin-top: -250px;
    }
    .right-video-box {
      margin-left: -4px;
      margin-top: -250px;
      display: block;
      flex: 1;
      perspective: 1000px;

      .innervideo-frame {
        background: radial-gradient(
            93.94% 91.77% at 86.03% 95.68%,
            rgba(0, 0, 0, 0.23) 0%,
            rgba(0, 0, 0, 0) 86.18%
          ),
          radial-gradient(
            66.59% 70.13% at 24.91% 24.06%,
            rgba(255, 255, 255, 0.413) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #ffffff;
        box-shadow: 0px 15px 37px rgba(0, 0, 0, 0.25);
        border-radius: 34px;
        padding: 20px;
        max-width: 810px;
        transform: rotateX(-7deg) rotateY(5deg);
        -webkit-transform: rotateX(-7deg) rotateY(5deg);
        -moz-transform: rotateX(-7deg) rotateY(5deg);
        transform-style: preserve-3d;
        transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
      }

      .right-video-card {
        height: 100%;
        img {
          border-radius: 34px;
          margin-top: -7px;
          height: 100%;
        }
      }
    }
  }
}

.playvd-btn {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  transform: translateY(-50%);
  filter: drop-shadow(4px 28px 41px rgba(0, 0, 0, 0.2));
  width: 106px;
  height: 106px;
  cursor: pointer;
}
.games-main {
  .heading-top {
    max-width: 350px;
    margin: 0 auto;
    .subtitle-tex {
      font-weight: 600;
      font-size: 26px;
      line-height: 24px;
      letter-spacing: 0.04em;
      color: #ffd300;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      text-align: right;
      display: block;
    }
  }
}

.games-card {
  padding-top: 60px;
  .one {
    .games-card-bx {
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        border-radius: 37px;
        // transform: perspective(1000px) rotateY(-1deg) rotateX(-14deg);
        // -webkit-transform: perspective(1000px) rotateY(-1deg) rotateX(-14deg);
        // -moz-transform: perspective(1000px) rotateY(-1deg) rotateX(-14deg);
        background: radial-gradient(
            89.85% 73.57% at 84.21% 75.36%,
            rgba(0, 0, 0, 0.23) 0%,
            rgba(0, 0, 0, 0) 86.18%
          ),
          radial-gradient(
            63.69% 56.22% at 25.75% 17.94%,
            rgba(255, 255, 255, 0.177) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #632caf;
        box-shadow: 0px 19px 56px rgba(0, 0, 0, 0.25);
        display: block;
        z-index: -1000;
        transform: rotateX(-16deg) rotateY(2deg);
        -webkit-transform: rotateX(-16deg) rotateY(2deg);
        -moz-transform: rotateX(-16deg) rotateY(2deg);
        transform-style: preserve-3d;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
      }

      //  .card-icon
      //  {
      //     transform: perspective(1000px) rotateY(1deg) rotateX(14deg);
      //     -webkit-transform: perspective(1000px) rotateY(1deg) rotateX(14deg);
      //     -moz-transform: perspective(1000px) rotateY(1deg) rotateX(14deg);
      //  }
      //  .games-content-bx
      //  {
      //     transform: perspective(1000px) rotateY(1deg) rotateX(14deg);
      //     -webkit-transform: perspective(1000px) rotateY(1deg) rotateX(14deg);
      //     -moz-transform: perspective(1000px) rotateY(1deg) rotateX(14deg);
      //  }
    }
  }
  .two {
    .games-card-bx {
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        border-radius: 37px;
        // transform: perspective(1000px) rotateY(12deg) rotateX(0deg);
        // -webkit-transform: perspective(1000px) rotateY(12deg) rotateX(0deg);
        // -moz-transform: perspective(1000px) rotateY(12deg) rotateX(0deg);
        background: radial-gradient(
            440.32% 673.57% at 111.59% 149.59%,
            rgba(0, 0, 0, 0.23) 3.55%,
            rgba(0, 0, 0, 0) 100%
          ),
          radial-gradient(
            63.69% 56.22% at 25.75% 17.94%,
            rgba(255, 255, 255, 0.177) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #632caf;
        box-shadow: 0px 19px 56px rgba(0, 0, 0, 0.25);
        display: block;
        z-index: -1000;
        transform: rotateX(-1deg) rotateY(8deg);
        -webkit-transform: rotateX(-1deg) rotateY(8deg);
        -moz-transform: rotateX(-1deg) rotateY(8deg);
        transform-style: preserve-3d;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
      }
    }
  }
  .three {
    .games-card-bx {
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        border-radius: 37px;
        // transform: perspective(1000px) rotateY(12deg) rotateX(0deg);
        // -webkit-transform: perspective(1000px) rotateY(12deg) rotateX(0deg);
        // -moz-transform: perspective(1000px) rotateY(12deg) rotateX(0deg);
        background: radial-gradient(
            89.85% 73.57% at 84.21% 75.36%,
            rgba(0, 0, 0, 0.23) 0%,
            rgba(0, 0, 0, 0) 86.18%
          ),
          radial-gradient(
            63.69% 56.22% at 25.75% 17.94%,
            rgba(255, 255, 255, 0.177) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #632caf;
        box-shadow: 0px 19px 56px rgba(0, 0, 0, 0.25);
        display: block;
        z-index: -1000;
        transform: rotateX(-1deg) rotateY(8deg);
        -webkit-transform: rotateX(-1deg) rotateY(8deg);
        -moz-transform: rotateX(-1deg) rotateY(8deg);
        transform-style: preserve-3d;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
      }
    }
  }

  .four {
    .games-card-bx {
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        border-radius: 37px;
        // transform: perspective(1000px) rotateY(-1deg) rotateX(-14deg);
        // -webkit-transform: perspective(1000px) rotateY(-1deg) rotateX(-14deg);
        // -moz-transform: perspective(1000px) rotateY(-1deg) rotateX(-14deg);
        background: radial-gradient(
            89.85% 73.57% at 84.21% 75.36%,
            rgba(0, 0, 0, 0.23) 0%,
            rgba(0, 0, 0, 0) 86.18%
          ),
          radial-gradient(
            63.69% 56.22% at 25.75% 17.94%,
            rgba(255, 255, 255, 0.177) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #632caf;
        box-shadow: 0px 19px 56px rgba(0, 0, 0, 0.25);
        z-index: -1000;
        display: block;
        transform: rotateX(-16deg) rotateY(2deg);
        -webkit-transform: rotateX(-16deg) rotateY(2deg);
        -moz-transform: rotateX(-16deg) rotateY(2deg);
        transform-style: preserve-3d;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
      }
    }
  }

  .games-card-bx {
    position: relative;
    max-width: 548px;
    margin-bottom: 80px;
    z-index: 9;
    perspective: 1000px;

    .btn-week {
      transform: translateY(50px);
      -webkit-transform: translateY(50px);
      .btnweak-bx {
        padding: 13px 30px;
      }
    }
  }

  .card-inner-games {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 30px 40px 30px;
    .card-pic {
      flex: 0 0 200px;
      max-width: 200px;
    }
    .right-card-games {
      flex: 0 0 calc(100% - 200px);
      max-width: calc(100% - 200px);
      text-align: left;
      padding-left: 20px;
      color: #fff;
    }
  }
  .games-content-bx {
    max-width: 250px;
    h5 {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ff7a00;
      margin-bottom: 0px;
      padding-bottom: 8px;
    }
    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.04em;
      color: #ffffff;
      margin-bottom: 0px;
      padding-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: #ffffff;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      margin-bottom: 0px;
    }
  }
}
.top-games-box {
  padding-top: 100px;
}
.best-plan-card {
  .plan-card {
    box-shadow: 0px 16px 21px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    background: #fff;
    padding: 20px 20px 40px 20px;
    transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
    z-index: -1000;
    display: block;
  }
  .row {
    justify-content: center;

    .cardbxx {
      max-width: 390px;
      padding-top: 60px;
      perspective: 1000px;
    }
  }
  .one {
    .plan-card {
      // transform: perspective(300px) rotateY(  0deg) rotateX(  0deg);
      // -webkit-transform: perspective(300px) rotateY(  0deg) rotateX(  0deg);
      transform: rotateX(4deg) rotateY(7deg);
      -webkit-transform: rotateX(4deg) rotateY(7deg);
      -moz-transform: rotateX(4deg) rotateY(7deg);
      transform-style: preserve-3d;
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }
  .two {
    .plan-card {
      // transform: perspective(300px) rotateY(1deg) rotateX(1deg);
      // -webkit-transform: perspective(300px) rotateY(  1deg) rotateX(  1deg);
      transform: rotateX(3deg) rotateY(4deg);
      -webkit-transform: rotateX(3deg) rotateY(4deg);
      -moz-transform: rotateX(3deg) rotateY(4deg);
      transform-style: preserve-3d;
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }
  .three {
    .plan-card {
      // transform: perspective(300px) rotateY(  -2deg) rotateX(  0deg);
      // -webkit-transform: perspective(300px) rotateY(  -2deg) rotateX(  0deg);
      transform: rotateX(2deg) rotateY(-7deg);
      -webkit-transform: rotateX(2deg) rotateY(-7deg);
      -moz-transform: rotateX(2deg) rotateY(-7deg);
      transform-style: preserve-3d;
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }

  .plan-card {
    .title-plancard {
      font-weight: 700;
      font-size: 32px;
      line-height: 34px;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      color: var(--purple);
      max-width: 280px;
      margin: 0 auto;
      padding-bottom: 30px;
    }
    .card-descript-paln {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.04em;
      color: var(--purple);
      max-width: 320px;
      margin: 0 auto;
      padding-bottom: 25px;
    }
    .card-list-plan {
      ul {
        padding: 0px;
        margin: 0px;
        max-width: 230px;
        margin: 0 auto;
        padding-bottom: 30px;

        li {
          list-style: none;
          padding: 0px;
          margin: 0px;
          position: relative;
          text-align: left;
          padding-left: 25px;
          padding-bottom: 10px;
          .list-detail-plan {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.04em;
            color: var(--purple);
          }
          .icontick {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 20px;

            img {
              width: 19px;
            }
          }
        }
      }
    }
  }

  .card-icon-plan {
    max-width: 240px;
    margin: 0 auto;
    position: relative;
    height: 150px;
  }
  .one {
    .card-icon-plan {
      img {
        position: absolute;
        top: -40px;
        left: 0px;
        right: 0px;
        margin: 0 auto;
      }
    }
  }
  .two {
    .card-icon-plan {
      img {
        position: absolute;
        top: -80px;
        left: 0px;
        right: 0px;
        margin: 0 auto;
      }
    }
  }

  .three {
    .card-icon-plan {
      img {
        position: absolute;
        top: -110px;
        left: 0px;
        right: 0px;
        margin: 0 auto;
      }
    }
  }
}
// .best-plan
// {
// // .heading-top
// // {
// //     // max-width: 400px;
// //     // margin: 0 auto;

// // }

// }

.choose-plan {
  padding-top: 120px;
  padding-bottom: 500px;
  .best-plan-card {
    padding-top: 80px;
  }
}

.testimaonial-section {
  background: linear-gradient(
    62.75deg,
    #ac88de -26.91%,
    #8548da -23.67%,
    #4c85f4 53.73%,
    #556bbb 83.59%,
    #cc0f97 114.76%
  );
  box-shadow: 0px 19px 56px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 9;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: url("../assets/images/vectorbg3.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    background-position-y: -100px;
  }
}
.testimonial-contain {
  .custom-row {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.faq-main {
  padding-top: 60px;
  max-width: 1100px;
  margin: 0 auto;
  .accordion-item {
    background: transparent;
    box-shadow: none;
    border: none;
    padding-bottom: 25px;
  }
  .accordion-button {
    background: transparent;
    box-shadow: none;
    border: none;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: var(--purple);
    padding: 23px 30px 23px 30px;

    .shape-bx {
      // transform: perspective(400px) rotateX(-8deg);
      // -webkit-transform: perspective(400px) rotateX(-8deg);
      // -moz-transform: perspective(400px) rotateX(-8deg);
      background: linear-gradient(187.04deg, #f1f1f1 5.49%, #cee1ff 102.49%);
      box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: block;
      z-index: -1000;
      transform: rotateX(-16deg);
      -webkit-transform: rotateX(-16deg);
      -moz-transform: rotateX(-16deg);
      transform-style: preserve-3d;
      transform-origin: 0% 0%;
      -webkit-transform-origin: 0% 0%;
    }
  }

  .accordion-body {
    text-align: left;
    max-width: 900px;
    padding: 30px 20px 10px 60px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.04em;
      color: #ffffff;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      margin-bottom: 0px;
      padding-bottom: 15px;

      a {
        color: var(--yellow);
      }
    }
  }

  .faq-shape {
    perspective: 1000px;
    z-index: -1000;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
.faq-main .accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.faq-main .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: var(--purple);
}
.faq-main .accordion-item:nth-child(even) .faq-shape {
  transform: rotate(180deg);
}

.faq-main .accordion-item:nth-child(even) .accordion-button .shape-bx {
  transform: rotateX(-16deg);
  -webkit-transform: rotateX(-16deg);
  -moz-transform: rotateX(-16deg);
  transform-style: preserve-3d;
  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
}

.faq-main .accordion-button:hover {
  z-index: 1;
}

.faq-main .accordion-button:not(.collapsed)::after {
  background-image: url("../assets/images/plus.png");
  background-position: center;
  background-repeat: no-repeat;
}

.faq-main .accordion-button::after {
  background-image: url("../assets/images/minus.png");
  background-position: center;
  background-repeat: no-repeat;
}

.still-thnk-box {
  .still-content {
    max-width: 640px;
    margin: 0 auto;
    text-align: center;

    p {
      margin: 0px;
      font-weight: 700;
      font-size: 26px;
      line-height: 33px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #fffdfa;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
.week-free {
  .weekfre-card {
    max-width: 750px;
    margin: 0 auto;
    p {
      margin: 0px;
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #6a64e6;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      padding: 40px 10px 40px 10px;
    }
  }

  .weekfre-box {
    position: relative;
    z-index: 1;
    // &:before
    // {
    //     content: "";
    //     position: absolute;
    //     top: 0px;
    //     left: 0px;
    //     width: 100%;
    //     height: 100%;
    //     transform: perspective(300px) rotateX( 0deg);
    //     -webkit-transform: perspective(300px) rotateX( 0deg);
    //     background: radial-gradient(154.78% 126.74% at 90.75% 128.54%, rgba(255, 255, 255, 0.59) 0%,
    //      rgba(255, 255, 255, 0.59) 86.18%), radial-gradient(129.77% 116.12% at 3.63% -16.12%, rgba(255, 122, 0, 0.7) 0%,
    //       rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    //     backdrop-filter: blur(4px);
    //     border-radius: 18px;
    //     z-index: -1000;
    //     transform-origin: 0% 0%;
    //     -webkit-transform-origin: 0% 0%;
    //     display: block;

    // }

    .weekshape-bg {
      position: absolute;
      left: 0;
      top: -30px;
      width: 100%;
      height: 100%;
      perspective: 1000px;
      z-index: -1000;
      transform: rotate(180deg);

      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: radial-gradient(
            154.78% 126.74% at 90.75% 128.54%,
            rgba(255, 255, 255, 0.59) 0%,
            rgba(255, 255, 255, 0.59) 86.18%
          ),
          radial-gradient(
            129.77% 116.12% at 3.63% -16.12%,
            rgba(255, 122, 0, 0.7) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          #ffffff;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(4px);
        border-radius: 18px;
        z-index: -1000;
        transform: rotateX(-23deg);
        -webkit-transform: rotateX(-23deg);
        -moz-transform: rotateX(-23deg);
        transform-style: preserve-3d;
        transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        display: block;
      }
    }
  }
}
.still-thinking {
  padding-top: 120px;
  padding-bottom: 250px;
  overflow: hidden;
  .still-content {
    padding-bottom: 55px;
  }
}

.still-thnk-box {
  position: relative;
  .rightpic-grl {
    position: absolute;
    top: -150px;
    right: -170px;
    z-index: 9;
  }
}

.footer-section {
  position: relative;
  z-index: 9;
  padding: 60px 15px;

  // &:before
  // {
  //     content: "";
  //     position: absolute;
  //     top: 0px;
  //     left: 0px;
  //     width: 100%;
  //     height: 100%;
  //     z-index: -1;
  //     transform: perspective(500px) rotateX( -3deg) rotateY( 0deg);
  //     -webkit-transform: perspective(500px) rotateX( -3deg) rotateY( 0deg);
  //     background: linear-gradient(-97.08deg, #6552CF -2.26%, #B947B9 98.86%);
  //     box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.25);
  //     border-radius: 30px;
  //     z-index: -1000;
  //     display: block;
  //     transform-origin: 0% 0%;
  //     -webkit-transform-origin: 0% 0%;
  // }
  .footershape-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    perspective: 1000px;
    z-index: -1000;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -1000;
      background: linear-gradient(97.08deg, #6552cf -2.26%, #b947b9 98.86%);
      box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      z-index: -1000;
      display: block;
      transform: rotateX(-9deg);
      -webkit-transform: rotateX(-9deg);
      -moz-transform: rotateX(-9deg);
      transform-style: preserve-3d;
      transform-origin: 0% 0%;
      -webkit-transform-origin: 0% 0%;
    }
  }

  .foter-logo {
    text-align: left;
    padding-left: 60px;
  }

  .btnweak-bx {
    padding: 13px 30px;
  }

  .row {
    align-items: center;
  }

  .foter-listnew {
    ul {
      padding: 0px;
      margin: 0px;

      li {
        padding: 0px;
        margin: 0px;
        list-style: none;
        text-align: left;
        padding-bottom: 20px;

        a {
          font-style: 400;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          text-decoration: none;

          &:hover {
            color: var(--yellow);
          }
        }
      }
    }
  }
}

.foter-list {
  .foter-listnew {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;

    ul {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

.foter-copywrite {
  padding: 20px 0;
  ul {
    padding: 0px;
    margin: 0px;
    text-align: left;

    li {
      padding: 0px;
      margin: 0px;
      list-style: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      text-align: left;
      padding-right: 10px;
      display: inline-block;
      font-family: "Montserrat", sans-serif;
    }
  }
}

.week-free {
  .btn-week {
    transform: translateY(0px);
  }
}
.footer-main {
  padding: 0 20px;
}

.testimonial-contain {
  .testim-left {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;

    .left-pic {
      flex: 0 0 280px;
      max-width: 280px;
    }
    .right-box-testi {
      flex: 0 0 calc(100% - 280px);
      max-width: calc(100% - 280px);
    }
  }
}

.testimonial-bx {
  .testi-main {
    transform: translateY(-300px);
    height: 360px;
    max-width: 1100px;
    margin: 0 auto;
  }
}
.rocket-icsml {
  position: absolute;
  bottom: 300px;
  right: 80px;
}

// .slick-center {
//   width: 400px !important;
// }

.slider-cardbox {
  text-align: left;
  z-index: 1;
  padding: 60px 30px;

  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--purple);
    margin-bottom: 0px;
    text-align: left;
    padding-bottom: 10px;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: var(--purple);
    margin-bottom: 0px;
    text-align: left;
    padding-bottom: 20px;
  }
  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--purple);
    margin-bottom: 0px;
    text-align: left;
  }

  .slider-innercard {
    max-width: 600px;
    margin: 0 auto;
    // padding-left: 14px;
    padding-top: 6px;

    img {
      transform: perspective(300px) rotateY(3deg) rotateX(-1deg);
      border-radius: 20px;
      border: 8px solid #fff;
      width: 100%;
    }
  }
}

.testimonial-boxx .slick-prev {
  left: -15px;
}
.testimonial-boxx .slick-next {
  right: -15px;
}

.testimonial-contain .slick-next,
.testimonial-contain .slick-prev {
  width: 50px;
  height: 50px;
  filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
  z-index: 9;
}

.testimonial-contain .slick-nex {
  right: 0px;
}

.testimonial-boxx {
  position: relative;
  z-index: 9;
  perspective: 1000px;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -10px;
    width: 100%;
    height: 100%;
    background: linear-gradient(-180deg, #ffffff, #ffffff),
      radial-gradient(
        129.77% 116.12% at 3.63% -16.12%,
        rgba(255, 255, 255, 0.413) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      ),
      #e73fb5;
    border-radius: 18px;
    box-shadow: 4px 15px 20px rgba(0, 0, 0, 0.3);
    // transform: perspective(300px) rotateX(0deg) rotateY(0deg);
    // -webkit-transform: perspective(300px) rotateX(0deg) rotateY(0deg);
    z-index: -1000;
    display: block;
    transform: rotateX(11deg) rotateY(-3deg);
    -webkit-transform: rotateX(11deg) rotateY(-3deg);
    -moz-transform: rotateX(11deg) rotateY(-3deg);
    transform-style: preserve-3d;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
  }

  .slick-slider.slick-initialized {
    padding: 0 40px;
  }
}

.plan-card {
  .btn-buy {
    .btn-week {
      position: absolute;
      left: 0px;
      right: 10px;
      margin: 0 auto;
      bottom: -20px;

      .btnweak-bx {
        transform: perspective(250px) rotateY(-3deg);
        -webkit-transform: perspective(250px) rotateY(-3deg);
        -moz-transform: perspective(250px) rotateY(-3deg);
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .card-list-plan {
    margin-bottom: 20px;
  }
}

.skybtn {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  color: var(--white);
  background-color: var(--sky);
  border-radius: 12px;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 8px 15px;

  &:hover {
    background-color: #44fbfb;
  }
}

.pricecutt-of {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 2px;
    width: 100%;
    border-top: 2px solid #fc47c7;
    transform: rotate(-35deg);
    height: 2px;
    transform-origin: 30px;
  }
}

/* scroll design*/
body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000;
  background-color: #f1eafa;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  filter: "alpha(opacity=65)";
  -ms-filter: "alpha(opacity=65)";
  background-color: rgba(255, 255, 255, 0.75);
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  background-color: var(--purple-dark);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--purple-dark);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)";
}


// end scroll bar
.educator-footer {
  .footer-become {
    text-align: left;
  }
}

.footer-section.educator-footer {
  .btnweak-bx {
    padding: 13px 20px;
  }
}

@media (max-width: 1300px) {
  .get-started {
    padding: 13px 20px;
  }
  .navbar-top .navbar-collapse .navbar-nav li a {
    padding: 17px 20px;
  }
}

.serching-tutor {
  .slider-cardbox {
    .serch-tutor-boxx {
      img {
        filter: drop-shadow(1px 9px 14px rgba(0, 0, 0, 0.25));
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .mobile-only-shw {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .desktop-only-shw {
    display: none;
  }

  .educator-headermain .center-navbar .educator-menubar {
    padding: 0 15px !important;
    padding-top: 15px !important;
  }
}

.logo-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
}
.logo-wrapper p{
  font-size: 14px;
  color: #4c85f4;
padding-left: 7px;
padding-right: 7px;
padding-top: 3px;
  background-color: #ffd029;
  margin: 0;
  height: 25px;
  font-weight: 600;
  border-radius: 2px;
 
}

.dateformat{
  margin-bottom: 15px;
  height: 52px;
  border-radius: 13px;
  background-color: rgba(106, 100, 230, 0.1);
  border: none;
  padding: 5px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--purple);
  width: 100%;
}
.dateformat::placeholder{
  height: 10rem;
}
.react-datepicker-wrapper{
  width: 100%;
  cursor: pointer;
}
.active-games-carousel .slick-track{
  display: flex;
  gap: 20px;
}