.mymateial-main
{
    .game-opn-create {

        .gamestbs-btn {
            padding: 10px 20px;
        }
   
    }
    .inner-cardquizz 
    {
        .addlist-quiz 
        {
            padding-bottom: 15px;
        }
        .type-material-iner
        {
            min-height: 50px;
        }
    }
}

