#vtimeline {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 6rem 4rem 4rem 5rem;
  background-color: #ffffff20;
  border-radius: 20px;
  clip-path: polygon(0 0, 100% 4%, 95% 100%, 5% 100%);

  .vertical-timeline {
    padding: 0;
    &::before {
      width: 2px;
    }

    .vertical-timeline-element {
      .vertical-timeline-icon {
        background: #ff7a00;
        color: #fff;
        box-shadow: none;
        display: grid;
        place-items: center;
        font-size: 12px;
        font-weight: bold;
        box-shadow: inset 2px 2px 4px #fff8, inset -2px -2px 4px #0008;
      }

      .text-container {
        border-radius: 20px;
        background-color: #fff;
        padding: 8px;
        position: relative;
        top: -32px;
        box-shadow: unset;
        animation-duration: 4s;
        .vertical-timeline-element-content-arrow {
          top: 44px;
          border: 10px solid transparent;
          border-right: 10px solid #fff;
        }

        .content-container {
          display: flex;
          align-items: center;
          gap: 16px;
          text-align: left;

          .img {
            width: 100px;
            height: 100px;
            border-radius: 20px;
          }
          .content {
            h3 {
              font-size: 20px;
              color: #632caf;
              font-weight: 800;
              margin-bottom: 4px;
            }
            p {
              font-size: 12px;
              margin: 0;
            }
          }
        }

        .vertical-timeline-element-date {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 665px) {
  #vtimeline {
    width: 95%;
    padding: 6rem 1rem 4rem 1rem;
  }
}
