/* fullSelfVideoLayout */
/* FSVL */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;
  text-align: left;
}
.FSVL .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: black;
}

.FSVL .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.FSVL .selfParent {
  width: 100%;
}
.FSVL .selfVideo {
  width: 100%;
}
.FSVL .remoteParent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 180px;
  height: 100px;
}
.FSVL .remoteVideo {
  width: 100%;
  height: 100%;
}
.FSVL .selfName {
  background-color: rgb(165, 165, 165);
  position: relative;
  bottom: 23px;
  padding: 1px 5px;
  border-radius: 3px;
}
.FSVL .remoteName {
  background-color: rgb(165, 165, 165);
  position: relative;
  bottom: 23px;
  padding: 1px 5px;
  border-radius: 3px;
}
.FSVL .videosParent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* CSVL */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.CSVL .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  background-color: black;
  position: relative;
}

.CSVL .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.CSVL .selfParent {
  width: 100%;
}
.CSVL .selfVideo {
  width: 100%;
}
.CSVL .remoteParent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 180px;
  height: 100px;
}

.CSVL .remoteVideo {
  width: 100%;
  height: 100%;
}
.CSVL .selfName {
  background-color: rgb(165, 165, 165);
  position: relative;
  bottom: 23px;
  padding: 1px 5px;
  border-radius: 3px;
}
.CSVL .remoteName {
  background-color: rgb(165, 165, 165);
  position: relative;
  bottom: 23px;
  padding: 1px 5px;
  border-radius: 3px;
}
.CSVL .videosParent {
}

.CSVL .chat-wrapper {
  height: 100%;
}

.send-btn {
  width: 30px;
  height: 30px;
}
.CSVL .chat-wrapper {
  display: flex;
  flex-direction: column;
}
.CSVL-chat-wrapper {
  min-width: 240px;
  border-left: 16px solid white;
  background-color: white;
}
.CSVL .chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #632caf;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 194.444% */
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}
.CSVL .messages-wrapper {
  flex: 1;
  background-color: #fcdbb8;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: scroll;
  overflow-x: hidden;
}
.CSVL .chat-input {
  background-color: #fcdbb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}
.CSVL .chat-input input {
  width: 90%;
  margin-bottom: 13px;
  border-radius: 6px;
  background: #fff;
  height: 32.409px;
  border: none;
  padding: 5px;
  font-size: 16px;
}
.CSVL .send-btn {
  border: none;
  all: unset;
  position: absolute;
  right: 8px;
  top: 7px;
}
/* BSVL */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.BSVL .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  position: relative;
}
.BSVL .videosParent {
  height: 100%;
}
.BSVL .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  max-height: 50%;
  overflow: hidden;
  height: 50%;
}
.BSVL .board-wrapper {
  width: 100%;
  border-radius: 10px;
  height: 50%;
  position: relative;
  z-index: 30;
}
.BSVL .selfParent {
  width: 100%;
  height: 100%;
}
.BSVL .selfVideo {
  width: 100%;
  height: 100%;
}
.BSVL .remoteParent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 180px;
  height: 100px;
}
.BSVL .remoteVideo {
  width: 100%;
  height: 100%;
}
.BSVL .selfName {
}
.BSVL .remoteName {
}
.BSVL .videosParent {
}

.BSVL .chat-wrapper {
  height: 100%;
}

.send-btn {
  width: 30px;
  height: 30px;
}
.BSVL .chat-wrapper {
  display: flex;
  flex-direction: column;
}
.BSVL-chat-wrapper {
  min-width: 240px;
  border-left: 16px solid white;
  background-color: white;
}
.BSVL .chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #632caf;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 194.444% */
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}
.BSVL .messages-wrapper {
  flex: 1;
  background-color: #fcdbb8;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: scroll;
  overflow-x: hidden;
}
.BSVL .chat-input {
  background-color: #fcdbb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}
.BSVL .chat-input input {
  width: 90%;
  margin-bottom: 13px;
  border-radius: 6px;
  background: #fff;
  height: 32.409px;
  border: none;
  padding: 5px;
  font-size: 16px;
}
.BSVL .send-btn {
  border: none;
  all: unset;
  position: absolute;
  right: 8px;
  top: 7px;
}

/* FBSVL */
.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.FBSVL .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  position: relative;
}
.FBSVL .videosParent {
  height: 100%;
}
.FBSVL .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  max-height: 50%;
  overflow: hidden;
}
.FBSVL .board-wrapper {
  width: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 30;
}
.FBSVL .selfParent {
  width: 100%;
  height: 100%;
}
.FBSVL .selfVideo {
  width: 100%;
  height: 100%;
}
.FBSVL .remoteParent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 180px;
  height: 100px;
}
.FBSVL .remoteVideo {
  width: 100%;
  height: 100%;
}
.FBSVL .selfName {
}
.FBSVL .remoteName {
}
.FBSVL .videosParent {
}

.FBSVL .chat-wrapper {
  height: 100%;
}

.send-btn {
  width: 30px;
  height: 30px;
}
.FBSVL .chat-wrapper {
  display: flex;
  flex-direction: column;
}
.FBSVL-chat-wrapper {
  min-width: 240px;
  border-left: 16px solid white;
  background-color: white;
}
.FBSVL .chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #632caf;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 194.444% */
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}
.FBSVL .messages-wrapper {
  flex: 1;
  background-color: #fcdbb8;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: scroll;
  overflow-x: hidden;
}
.FBSVL .chat-input {
  background-color: #fcdbb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}
.FBSVL .chat-input input {
  width: 90%;
  margin-bottom: 13px;
  border-radius: 6px;
  background: #fff;
  height: 32.409px;
  border: none;
  padding: 5px;
  font-size: 16px;
}
.FBSVL .send-btn {
  border: none;
  all: unset;
  position: absolute;
  right: 8px;
  top: 7px;
}
/* GV */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.GV .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  position: relative;
}
.GV .videosParent {
  height: 100%;
  width: 100%;
}
.GV .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
}

.GV .selfParent {
  width: 100%;
  height: 50%;
  border-top: 8px solid white;
}
.GV .selfVideo {
  width: 100%;
  height: 100%;
}
.GV .remoteParent {
  height: 50%;
  border-bottom: 8px solid white;
}
.GV .remoteVideo {
  width: 100%;
  height: 100%;
}
.GV .selfName {
}
.GV .remoteName {
}
.GV .videosParent {
  display: flex;
  flex-direction: column;
}
.close-icon {
  display: none;
}

/*if chat is active and whiteboard is active, but grid view is not active, then show chat, whiteboard and selfVideoLayout */
/* CBSVL */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.CBSVL .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  background-color: black;
  position: relative;
}
.CBSVL .videosParent {
  height: 100%;
}
.CBSVL .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  max-height: 50%;
  overflow: hidden;
  height: 50%;
}
.CBSVL .board-wrapper {
  width: 100%;
  border-radius: 10px;
  height: 50%;
  position: relative;
  z-index: 30;
}
.CBSVL .selfParent {
  width: 100%;
  height: 100%;
}
.CBSVL .selfVideo {
  width: 100%;
  height: 100%;
}
.CBSVL .remoteParent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 180px;
  height: 100px;
}
.CBSVL .remoteVideo {
  width: 100%;
  height: 100%;
}
.CBSVL .selfName {
}
.CBSVL .remoteName {
}
.CBSVL .videosParent {
}

.CBSVL .chat-wrapper {
  height: 100%;
  flex: 1;
}

.send-btn {
  width: 30px;
  height: 30px;
}
.CBSVL .chat-wrapper {
  display: flex;
  flex-direction: column;
}
.CBSVL-chat-wrapper {
  min-width: 240px;
  border-left: 16px solid white;
  background-color: white;
  flex: 1;
}
.CBSVL .chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #632caf;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 194.444% */
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}
.CBSVL .messages-wrapper {
  flex: 1;
  background-color: #fcdbb8;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: scroll;
  overflow-x: hidden;
}
.CBSVL .chat-input {
  background-color: #fcdbb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}
.CBSVL .chat-input input {
  width: 90%;
  margin-bottom: 13px;
  border-radius: 6px;
  background: #fff;
  height: 32.409px;
  border: none;
  padding: 5px;
  font-size: 16px;
}
.CBSVL .send-btn {
  border: none;
  all: unset;
  position: absolute;
  right: 8px;
  top: 7px;
}
.CBSVL .left-content {
  width: 100%;
}
/* CBFVL */
.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.CBFVL .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  position: relative;
}
.CBFVL .videosParent {
  height: 100%;
}
.CBFVL .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  max-height: 50%;
  overflow: hidden;
}
.CBFVL .board-wrapper {
  width: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 30;
}
.CBFVL .selfParent {
  width: 100%;
  height: 100%;
}
.CBFVL .selfVideo {
  width: 100%;
  height: 100%;
}
.CBFVL .remoteParent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 180px;
  height: 100px;
}
.CBFVL .remoteVideo {
  width: 100%;
  height: 100%;
}
.CBFVL .selfName {
}
.CBFVL .remoteName {
}
.CBFVL .videosParent {
}

.CBFVL .chat-wrapper {
  height: 100%;
}

.send-btn {
  width: 30px;
  height: 30px;
}
.CBFVL .chat-wrapper {
  display: flex;
  flex-direction: column;
}
.CBFVL-chat-wrapper {
  min-width: 240px;
  border-left: 16px solid white;
  background-color: white;
}
.CBFVL .chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #632caf;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 194.444% */
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}
.CBFVL .messages-wrapper {
  flex: 1;
  background-color: #fcdbb8;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: scroll;
  overflow-x: hidden;
}
.CBFVL .chat-input {
  background-color: #fcdbb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}
.CBFVL .chat-input input {
  width: 90%;
  margin-bottom: 13px;
  border-radius: 6px;
  background: #fff;
  height: 32.409px;
  border: none;
  padding: 5px;
  font-size: 16px;
}
.CBFVL .send-btn {
  border: none;
  all: unset;
  position: absolute;
  right: 8px;
  top: 7px;
}

@media (max-width: 780px) {
  /* set widths to 100% */
  /* .content-wrapper */
  /* 
  .FSVL .content-wrapper,
  .CSVL .content-wrapper,
  .BSVL .content-wrapper,
  .FBSVL .content-wrapper,
  .GV .content-wrapper {
    max-width: 100%;
    border: none;
    min-height: 100vh;

    border-radius: 0px;
  } */
  .CSVL-chat-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
  .chat-header {
    margin-top: 0px;
    margin-bottom: 0px !important;
    position: relative;
  }
  .CSVL-chat-wrapper {
    background-color: #fcdbb8;
    padding-top: 20px;
    z-index: 50;
    padding-left: 0px;
  }
  .close-icon {
    display: block !important;
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
  }
  .video-controls-right-alignment {
    max-width: 200px;
    right: 6px;
    top: 10px;
  }

  .video-controls-container-right-whiteboard-inactive {
    max-width: 200px;
    min-width: 170px;
  }
  .video-controls-left-alignment {
    left: 6px;
    top: 10px;
  }
  .video-controls-container-right {
    min-width: 170px;
  }
}
@media (min-width: 780px) {
  .chat-wrapper {
    max-width: 300px;
  }
  .content-wrapper:first-child {
    width: 100%;
  }
}

/* GVC */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.GVC .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  background-color: black;
  position: relative;
}
.GVC .videosParent {
  height: 100%;
  width: 100%;
}
.GVC .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
}
.GVC .board-wrapper {
  width: 100%;
  border-radius: 10px;
  height: 50%;
  position: relative;
  z-index: 30;
}
.GVC .selfParent {
  width: 100%;
  height: 50%;
}
.GVC .selfVideo {
  width: 100%;
  height: 100%;
}
.GVC .remoteParent {
  height: 50%;
  border-bottom: 8px solid white;
}
.GVC .remoteVideo {
  width: 100%;
  height: 100%;
}
.GVC .selfName {
}
.GVC .remoteName {
}
.GVC .videosParent {
}

.GVC .chat-wrapper {
  height: 100%;
  flex: 1;
}

.send-btn {
  width: 30px;
  height: 30px;
}
.GVC .chat-wrapper {
  display: flex;
  flex-direction: column;
}
.GVC-chat-wrapper {
  min-width: 240px;
  border-left: 16px solid white;
  background-color: white;
  flex: 1;
}
.GVC .chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #632caf;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 194.444% */
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}
.GVC .messages-wrapper {
  flex: 1;
  background-color: #fcdbb8;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: scroll;
  overflow-x: hidden;
}
.GVC .chat-input {
  background-color: #fcdbb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}
.GVC .chat-input input {
  width: 90%;
  margin-bottom: 13px;
  border-radius: 6px;
  background: #fff;
  height: 32.409px;
  border: none;
  padding: 5px;
  font-size: 16px;
}
.GVC .send-btn {
  border: none;
  all: unset;
  position: absolute;
  right: 8px;
  top: 7px;
}
.GVC .left-content {
  width: 100%;
}
/* GVB */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.GVB .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  position: relative;
}
.GVB .videosParent {
  width: 100%;
  display: flex;
}
.GVB .left-content {
  min-height: 50%;
}
.GVB .video-and-controls-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
}

.GVB .selfParent {
  flex: 1;
}
.GVB .selfVideo {
  width: 100%;
  height: 100%;
}
.GVB .remoteParent {
  flex: 1;
}
.GVB .remoteVideo {
  width: 100%;
  height: 100%;
}
.GVB .selfName {
}
.GVB .remoteName {
}
.GVB .videosParent {
  display: flex;
}
.GVB .board-wrapper {
  height: 50%;
}
.close-icon {
  display: none;
}
/* GVBC */

.page-wrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
}
.GVBC .content-wrapper {
  max-width: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 90vh;
  border: 16px solid white;
  border-radius: 20px;
  display: flex;
  background-color: black;
  position: relative;
}
.GVBC .videosParent {
  display: flex;
  align-items: center;
  height: 100%;
}
.GVBC .left-content {
  min-height: 50%;
  display: flex;
  align-items: center;
  position: relative;
}
.GVBC .video-and-controls-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
}

.GVBC .selfParent {
  flex: 1;
}
.GVBC .selfVideo {
  width: 100%;
  height: 100%;
}
.GVBC .remoteParent {
  flex: 1;
}
.GVBC .remoteVideo {
  width: 100%;
  height: 100%;
}
.GVBC .selfName {
}
.GVBC .remoteName {
}
.GVBC .videosParent {
  display: flex;
}
.GVBC .board-wrapper {
  height: 50%;
}
.close-icon {
  display: none;
}

.GVBC .chat-wrapper {
  height: 100%;
}

.send-btn {
  width: 30px;
  height: 30px;
}
.GVBC .chat-wrapper {
  display: flex;
  flex-direction: column;
}
.GVBC-chat-wrapper {
  min-width: 240px;
  border-left: 16px solid white;
  background-color: white;
}
.GVBC .chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #632caf;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 194.444% */
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}
.GVBC .messages-wrapper {
  flex: 1;
  background-color: #fcdbb8;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: scroll;
  overflow-x: hidden;
}
.GVBC .chat-input {
  background-color: #fcdbb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}
.GVBC .chat-input input {
  width: 90%;
  margin-bottom: 13px;
  border-radius: 6px;
  background: #fff;
  height: 32.409px;
  border: none;
  padding: 5px;
  font-size: 16px;
}
.GVBC .send-btn {
  border: none;
  all: unset;
  position: absolute;
  right: 8px;
  top: 7px;
}
.messages-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
