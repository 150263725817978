.contact-bxx
{
    .subtitle
    {
        max-width: 580px;
        margin: 0 auto;
        padding: 10px 0 50px 0;
        p 
        {
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.04em;
            color: var(--yellow);
            margin: 0px;
        }
    }
    .subsform-fild
    {
        padding-bottom: 20px;
        .form-control
        {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            background: #fff;
            height: 60px;
            font-size: 14px;
            line-height: 20px;
            padding: 6px 20px;
            letter-spacing: 0.04em;
            color: #C4C4C4;

            &::placeholder
            {
                color: #C4C4C4;
            }

        }
        textarea.form-control
        {
            height: 180px;
            resize: none;
            padding: 20px 20px;
           
        }
    }
    .send-messsage
    {
        padding-top: 50px;
    }
}

.form-contact
{
    position: relative;
    z-index: 1;
    padding: 70px 40px 40px 40px;
    .contact-detail
    {
        max-width: 900px;
        margin: 0 auto;
    }

    &:before 
    {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        opacity: 0.1;
        border-radius: 37px;
        transform: perspective(1000px) rotateX(15deg) rotateY(0deg);
        z-index: -1;
    }

}
.girl-sidepic {
    position: absolute;
    top: 0px;
    left: -130px;
}
.contact-page
{
    padding-top:100px;
    margin-top: -50px;
    background: linear-gradient( 
-143.54deg, #AC88DE -20.63%, #8548DA -17%, #4C85F4 69.57%, #556BBB 102.97%, #CC0F97 137.84%);
    &:before 
    {
        content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: url(../assets/images/vectorbg5.png);
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    background-position-y: -100px;
    background-size: cover;
    }
}
.contact-bxx {
    padding-bottom: 150px;
}