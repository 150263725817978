.gamesflow-section
{
    .contain-custm
    {
        max-width: 890px;
        margin: 0 auto;
        padding: 0 12px;
    }
}
.gamesflow-main
{
    padding-bottom: 120px;

    .gameflow-card
    {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        background-color: var(--white);
        padding: 10px 15px 0px 15px;
    }
}
.gameflow-innercard
{
    .title
    {
        font-size: 26px;
        line-height: 35px;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: var(--purple-dark);
        margin-bottom: 0px;
        padding-bottom: 10px;
    }
    .subtitle
    {
        font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: var(--orange);
    margin-bottom: 0px;
    padding-bottom: 15px;
    }
    .gameflow-content
    {
        max-width: 768px;
    margin: 0 auto;
        p 
        {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.04em;
            font-weight: 400;
            color: var(--purple);
            margin-bottom: 0px;
        }
    }
}
.flowlisting-view
{
    max-width: 768px;
    margin: 0 auto;
    .quizflow-list
    {
        padding-left: 0px;
        display: flex;
        line-height: 1;
        flex-wrap: wrap;
        margin: 0px;
        padding: 40px 0;
        
        li 
        {
            flex: 0 0 25%;
            max-width: 25%;
           


            .listin-quizflow
            {
                position: relative;
                .quixlok
                {
                    .lockquiz-icon
                    {
                        width: 41px;
                        height: 41px;
                        border-radius: 50%;
                        background: radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%,
                         rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #C4C4C4;
                         display: inline-flex;
                         align-items: center;
                         justify-content: center;
                         color: #fff;
                         font-size: 12px;
                    }
                }

                &:after 
                {
                    content: "";
                    position: absolute;
                    top: 20px;
                    right: -35%;
                    width: 70%;
                    height: 5px;
                    background: #F1F1F1;
                    border-radius: 37px;
                }

                
            }

            &:last-child
            {
                .listin-quizflow
                {
                    &:after 
                    {
                        display: none;
                    }
                }
            }


            .flw-quiz-content
                {
                    padding-top: 8px;
                    .sml-tex
                    {
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.04em;
                        color: #828282;
                        font-weight: 400;
                    }
                    .medm-tex
                    {
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 800;
                        color: #828282;
                    }

                }
        }

        .active 
        {
            .listin-quizflow 
            {
                .quixlok 
                {
                    .lockquiz-icon 
                    {
                        background: radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), 
                        radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%,
                         rgba(255, 255, 255, 0) 100%), #FF7A00;
                    }
                }
            }
            .flw-quiz-content
            {
                .medm-tex
                {
                    color: var(--purple-dark);
                }
            }
            .listin-quizflow
            {
                &:after 
                {
                   
                    background: #C4C4C4;
                   
                }
            }
        }
    }
}
.gameflow-card
{
    
    .close-iconbtn
    {
        text-align: right;
        .btnclose-glry
        {
            border: none;
            background-color: transparent;
            width: 25px;
            height: 25px;
            padding: 0px;
            color: var(--orange);
            font-size: 20px;
        }
    }
}
.gamestart-btnn
{
    .btnweak-bx
    {
        font-size: 14px;
        line-height: 21px;
        padding: 13px 20px;
    }

}
.flowgame-btnn
{
    .btn-buy
    {
        transform: translateY(20px);
    }
}
@media only screen and (max-width:767px){

    .flowlisting-view .quizflow-list li {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .flowlisting-view .quizflow-list li .listin-quizflow
    {
        position: relative;
    display: flex;
    flex: 1;
    text-align: left;
    min-height: 80px;
    flex-wrap: wrap;
    }
    .flowlisting-view .quizflow-list .active .listin-quizflow .quixlok .lockquiz-icon
    {
        z-index: 9;
        position: relative;
    }
    .flowlisting-view .quizflow-list li .flw-quiz-content
    {
        padding-top: 0px;
        padding-left: 10px;
        flex: 1;
        padding-bottom: 10px;
    }
    .flowlisting-view .quizflow-list li .listin-quizflow .quixlok .lockquiz-icon
    {
        width: 35px;
        height: 35px;
        font-size: 10px;
    }
    .flowlisting-view .quizflow-list li .listin-quizflow:after {
        content: "";
        position: absolute;
        top: 52%;
        right: auto;
        width: 3px;
        height: 40%;
        background: #F1F1F1;
        border-radius: 37px;
        left: 15px;
    }
    .flowlisting-view .quizflow-list li:last-child .listin-quizflow
    {
        min-height: auto;
    }
}