.current-lesson-main {
  .current-lesson-topbr {
    max-width: 850px;
    margin: 0 auto;
    padding: 0 40px;
    .medm-tex {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.04em;
      color: var(--yellow-dark);
    }
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 35px;
      letter-spacing: 0.04em;
      color: var(--white);
      margin-bottom: 0px;
      padding: 20px 0;
    }
  }
  .lesson-listing-curnt {
    padding: 0px;
    margin: 0px;

    li {
      padding: 0px;
      margin: 0px;
      list-style: none;
      display: inline-block;
      padding-right: 40px;
      &:last-child {
        padding-right: 0px;
      }
    }
  }
  .current-lesson-contain {
    position: relative;

    .backbtn {
      position: absolute;
      top: 30px;
      left: 0px;
      .backbtnn-icnn {
        font-size: 28px;
        color: var(--orange);
      }
    }
  }
}
.custm-container {
  max-width: 1034px;
  margin: 0 auto;
  padding: 0 12px;
}
.video-box-lessson {
  .video-glry {
    position: relative;

    .plynow-btn {
      .playvd-btn {
        width: 50px;
        height: 50px;
      }
    }
    .videopic {
      border-radius: 20px;
      overflow: hidden;
      img {
        border-radius: 20px;
      }
    }
    .resize-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      .btn-resize-video {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        border: none;
        color: var(--orange);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .lesson-glrybx-new {
    padding-top: 20px;
    img {
      border-radius: 20px;
    }
  }
}

.chatbox-room {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 20px 15px 10px 15px;
}
.chat-upperbox {
  .custom-row {
    display: flex;
    flex-wrap: wrap;
    line-height: 1;
  }
  .chatbox-inner {
    flex: 0 0 80%;
    max-width: 80%;
    padding-bottom: 12px;
    .message-box {
      display: inline-block;
      .message-tex {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.04em;
      }
      .message-time {
        text-align: right;
        .time {
          font-weight: 300;
          font-size: 10px;
          line-height: 15px;
          color: #bdbdbd;
        }
      }
    }
  }
}

.chat-sender {
  text-align: right;
  margin-left: auto;
  .message-box {
    background-color: var(--purple-dark);
    color: var(--white);
    border-radius: 20px 20px 0px 20px;
    text-align: left;
    padding: 12px 10px 8px 15px;
  }
}
.chat-receiver {
  text-align: left;
  .message-box {
    background-color: var(--white);
    color: var(--purple-dark);
    border-radius: 20px 20px 20px 0px;
    text-align: left;
    padding: 12px 15px 8px 15px;
  }
}

.chat-footer-message {
  .message-send-new {
    position: relative;
    .message-textarea {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      border: none;
      resize: none;
      color: var(--white);
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.04em;
      padding: 20px 100px 10px 20px;
      &::placeholder {
        color: var(--white);
      }
    }
    .right-chaticon {
      position: absolute;
      top: 20px;
      right: 15px;

      ul {
        padding: 0px;
        margin: 0px;

        li {
          padding: 0px;
          margin: 0px;
          list-style: none;
          display: inline-block;
          padding-left: 15px;

          .sendme-btn {
            background-color: transparent;
            border: none;
            padding: 0px;
            width: 22px;
          }
          .attachment-btn {
            background-color: transparent;
            border: none;
            padding: 0px;
            width: 22px;
          }
        }
      }
    }
  }
}

.chat-footer-main {
  .chat-footer-message {
    padding-top: 25px;
  }
}

.chatbox-room {
  .chat-innerbox {
    max-height: 457px;
    overflow-y: auto;
    padding-right: 15px;
  }
}
.chatbox-main {
  .row {
    .one {
      padding-bottom: 20px;
    }
    .two {
      padding-bottom: 20px;
    }
  }
}
.lesson-chatbx {
  padding: 50px 0;
}

// start current lession

.current-lesson-main {
  .current-lesson-topbr {
    .onelist {
      font-weight: 700;
    }
  }
}

.cureent-lession-bx {
  .current-lesson-main {
    .current-lesson-topbr {
      max-width: 1100px;
    }
  }
}
.video-box-lessson {
  .downld-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    .btn-resize-video {
      width: 25px;
      height: 25px;
      border-radius: 10px;
      border: none;
      color: var(--orange);
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.cureent-lession-bx {
  .video-box-lessson {
    .lesson-glrybx-new {
      position: relative;
      padding-top: 0px;
      margin-top: 20px;
      .resize-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        .btn-resize-video {
          width: 25px;
          height: 25px;
          border-radius: 10px;
          border: none;
          color: var(--orange);
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.completelession-main {
  .custm-container {
    max-width: 1240px;
  }
  .video-box-lessson {
    .video-glry {
      .videopic {
        img {
          width: 100%;
        }
      }
    }
  }
}
.pagination-lession-inner {
  padding-bottom: 50px;
  ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      .pagination-btn {
        color: var(--yellow-dark);
        font-weight: 600;
        font-size: 16px;
        text-decoration: none;
        line-height: 1;
        display: flex;
        flex-wrap: wrap;
        &:hover {
          .tex-lesn {
            text-decoration: underline;
          }
        }
      }
      .iconn {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
.pagination-lession-inner {
  ul {
    .one {
      .iconn {
        padding-right: 5px;
      }
    }
  }
}
.pagination-lession-inner {
  ul {
    .two {
      .iconn {
        padding-left: 5px;
      }
    }
  }
}
.livelession-btn {
  .btnlession-live.chatbtn-two {
    .btnweak-bx {
      color: var(--white);
    }
  }
}

// Newlessionlive

.livelession-btn {
  padding: 120px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .btnlession-live {
    padding: 0 40px 40px 40px;
    .btnweak-bx {
      padding: 10px 20px;
      width: 250px;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    }
  }
}
.loginmodal {
  .session-created {
    .modal-header {
      padding: 50px 0 50px 0;
    }
  }
}

// start lession card

.lession-time-card {
  .lessontime-bottom {
    max-width: 620px;
    margin: 0 auto;

    .newlesson-clock {
      max-width: 450px;
      margin: 0 auto;
    }
  }
}

.lession-time-card {
  padding: 40px 0;
  .btn-list-lesson {
    .btn-listing-lesson {
      .btnweak-bx {
        font-size: 18px;
        width: 220px;
        padding: 10px 10px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
      }
      .whitebtn-tx {
        .btnweak-bx {
          color: var(--white);
        }
      }
    }
  }
}

.loginmodal {
  .cancel-lession {
    .cancl-lession-btn {
      margin-bottom: 0px;
      padding-bottom: 30px;
    }
  }
}

.add-group-modal2 {
  .cancel-lession {
    .modal-title {
      p {
        max-width: 380px;
        padding-bottom: 5px;
        .deco-tex {
          text-decoration: underline;
          color: var(--orange);
        }
      }
    }
  }
}

.completelession-step2 {
  .video-box-lessson {
    .lesson-glrybx-new {
      img {
        width: 100%;
      }
    }
  }
}

.student-chat-main {
  .educator-lsttabs-new {
    .chat-topbar-list {
      .dropdown-main {
        .addactv-btndrp {
          display: inline-block;
          text-decoration: none;
        }
      }
    }
  }
}

// start media query

@media only screen and (min-width: 768px) {
  .completelession-main .chatbox-room .chat-innerbox {
    max-height: 576px;
  }
}

@media only screen and (max-width: 767px) {
  .video-box-lessson .video-glry .videopic img {
    width: 100%;
  }
  .video-box-lessson .lesson-glrybx-new img {
    width: 100%;
  }
  .current-lesson-main .current-lesson-topbr h1 {
    font-size: 26px;
    line-height: 30px;
  }
  .current-lesson-main .current-lesson-topbr {
    padding: 0 20px;
  }
  .current-lesson-main .current-lesson-contain .backbtn {
    top: -30px;
  }
  .livelession-btn {
    padding: 60px 0;
  }
  .livelession-btn .btnlession-live {
    padding: 0 40px 40px 0px;
  }
  .current-lesson-main .current-lesson-contain .backbtn .backbtnn-icnn img {
    width: 30px;
  }
}
.lessonCancelled {
  background-color: #bdbdbd56;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}

.missedDeadlineBox {
  background-color: rgba(240, 248, 255, 0.251);
  width: 700px;
  max-width: 90%;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  padding: 35px;
  h1 {
    color: #632caf;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 118.182% */
    letter-spacing: 0.88px;
    max-width: 490px;
    margin-left: auto;
    margin-right: auto;
  }
  .inner-box {
    background-color: #bdbdbd7d;
    max-width: 590px;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 28px;
    .btn-buy {
      position: relative;
      top: 30px;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
