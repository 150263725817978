.fileUploadContainer{
    height: 150px;
    background-color: #f5f5f5;
    border: 2px dashed #ccc;
    color: #5b5a5a;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .dropzone{

        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.fileUploadContainer:hover{
    background-color: #f0f0f0;
}

.drag-drop-material{
    width: 100px !important;
    height: 100px !important;
    border-radius: 10px !important;
    position: relative !important;
    z-index: 50 !important;
    object-fit: cover !important;
    border: 3px solid #ff7a00;
}
.drag-drop-react-player video{
object-fit: cover !important;
border-radius: 10px !important;
border: 3px solid #ff7a00;
}
.drag-drop-react-player{
display: flex;
justify-content: center;
position: relative !important;
z-index: 50 !important;
}
.video-cover{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.player-or-img-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .cross-icon{
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 51;
        cursor: pointer;
        color: #ff7a00;
   

    }
}