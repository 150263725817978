.video-alignment {
  z-index: 2;
}

.wrappanel {
  position: absolute;
  transform: translate(100px, 25px);
}
.pageWrapper {
  fill: linear-gradient(147deg, #5081f2 27.21%, #7658df 81.6%) !important;
  filter: drop-shadow(0px -7px 59px rgba(0, 0, 0, 0.25)) !important;
  background: linear-gradient(147.04deg, #5081f2 27.21%, #7658df 81.6%);
  width: 100vw;
  height: 100vh;
}

.receive {
  border-radius: 20px 20px 20px 0px;
  background: #e2ceff;
  max-width: 80%;
  margin-right: auto;
  position: relative;
}
.receive span {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
