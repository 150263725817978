.personal-reaward-info {
  .heading {
    font-size: 26px;
    font-weight: bold;
  }
  .details {
    font-size: 12px;
    margin: 12px 0;
  }

  .current-points {
    background: #ffffff20;
    color: #ff7a00;
    font-weight: bold;
    width: fit-content;
    padding: 10px 2.5rem;
    border-radius: 20px;
    margin: 24px auto;
    display: flex;
    align-items: center;
    strong {
      font-size: 26px;
      margin-left: 1rem;
      color: #ffd300;
    }
  }
}
