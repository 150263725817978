/* :root {
  --send-bg: #eeffde;
  --send-color: black;
  --receive-bg: #ffffff;
  --receive-text: black;
  --page-background: #f6efdf;
} */

.send {
  color: black;
  background: #eeffde;
  align-self: flex-end;
}

.receive {
  background: #ffffff;
  color: black;
  align-self: flex-start;
}

.chat-room-header {
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
}

.chat-room-header button {
  height: 60%;
  width: 2%;
  border: none;
  background-color: rgba(169, 169, 169, 0%);
}

.chat-room-header img {
  height: 20px;
}

.chat-room-header header {
  background-color: #222831;
  height: 9%;
  color: white;
  position: fixed;
  max-width: 100%;
  min-width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 1%;
  box-sizing: border-box;
}

.chat-room-header span {
  margin: 0 auto;
  font-weight: bold;
  font-size: 1.5em;
}
.send {
  background-color: #632caf;
  border-radius: 20px 20px 0px 20px;
  margin-left: auto;
  color: white;
  max-width: 75%;
  position: relative;
}

.send span {
  position: absolute;
  bottom: 6px;
  right: 6px;
}
