.photosty-inercrd {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background-color: var(--white);
  padding: 40px 15px 0px 15px;
  .iner-photosth-conent {
    p {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      color: var(--purple);
      margin-bottom: 0px;
      max-width: 768px;
      margin: 0 auto;
    }
  }
}
.games-photosth-main {
  .games-photsth-header {
    position: relative;

    h1 {
      font-size: 32px;
      line-height: 45px;
      font-weight: 700;
      color: var(--white);
      margin: 0px;
      padding: 0 80px;
    }

    .backbtn-lg {
      position: absolute;
      top: 0px;
      left: 0px;
      img {
        width: 47px;
      }
    }
    .editbtn-lg {
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
      img {
        width: 37px;
      }
    }
  }
}
.photosty-games-crd {
  padding: 45px 0 150px 0;
}

.gameflow-photosth-play {
  .flowlisting-view {
    max-width: 900px;
    .quizflow-list {
      .listin-quizflow {
        .quixlok {
          .lockquiz-icon {
            width: 48px;
            height: 48px;
            color: var(--purple-dark);
            font-size: 18px;
          }
        }
      }
      .iconminus {
        .quixlok {
          .lockquiz-icon {
            color: var(--white);
          }
        }
      }
    }
  }
}

.flowgame-btnn {
  .gameflw-save {
    margin: 0px;
    padding: 0px;
    li {
      display: inline-block;
      padding: 0 15px;
      .btn-title {
        font-size: 18px;
        line-height: 27px;
      }
      .btn-week {
        padding-right: 30px;
        .btnweak-bx {
          width: 240px;
        }
      }
    }
  }
}

.loginmodal {
  .student-btn-savedgame {
    .btnlogin-main {
      margin-bottom: 0px;
      padding-bottom: 25px;
    }
  }
}
.games-photosynths {
  .gamestart-btnn {
    .btnweak-bx {
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    }
  }
}
.dropmenu-custlistt {
  .lockquiz-icon {
    border: none;
    box-shadow: none;
    &:after {
      display: none;
    }
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
.gamesflows-twobx {
  .photosty-inercrd {
    padding: 70px 15px 0px 15px;
  }
  .flowlisting-view {
    .quizflow-list {
      padding: 60px 0 50px 0;
    }
  }
}
.gamesflows-twobx {
  .gameflow-photosth-play {
    .flowlisting-view {
      .quizflow-list {
        li {
          .flw-quiz-content {
            .medm-tex {
              color: var(--purple-dark);
            }
          }
        }
      }
    }
  }
}

.photosty-inercrd {
  .iner-photosth-conent {
    .newgames-flwcontent {
      padding-top: 40px;
      .greyclr {
        color: var(--grey-light);
      }
    }
  }
}

.newgameflows-bx {
  .quizflow-list {
    li {
      text-align: left;
    }
  }
  .flowlisting-view {
    .quizflow-list {
      li {
        .listin-quizflow {
          &:after {
            content: "";
            position: absolute;
            top: 20px;
            right: 0%;
            width: 92%;
            height: 5px;
            background: #f1f1f1;
            border-radius: 37px;
          }
        }

        &:last-child {
          .listin-quizflow {
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }
}

.gamesflows-twobx.gamesflows-nwbox
  .gameflow-photosth-play
  .flowlisting-view
  .quizflow-list
  li
  .listin-quizflow:after {
  right: 0%;
  width: 92%;
}
.gamesflows-twobx {
  .newgameflows-bx {
    .flowlisting-view {
      .quizflow-list {
        padding-top: 40px;
        padding-bottom: 60px;
      }
    }
  }
}

// start media query

@media only screen and (min-width: 768px) {
  .odd-num-photosth {
    .flowlisting-view {
      .quizflow-list {
        li {
          &:nth-child(odd) {
            .listin-quizflow {
              margin-top: 80px;
            }
          }
          &:last-child {
            margin-top: 0px;
            .listin-quizflow {
              margin-top: 80px;
            }
          }
          &:nth-child(even) {
            .flw-quiz-content {
              min-height: 80px;
            }
          }
        }
      }
    }
  }
  .gameflow-photosth-play .flowlisting-view .quizflow-list li {
    flex: 1;
    max-width: initial;
  }
  .gameflow-photosth-play
    .flowlisting-view
    .quizflow-list
    li
    .listin-quizflow:after {
    right: -24%;
    width: 50%;
  }
  .gamesflows-twobx
    .gameflow-photosth-play
    .flowlisting-view
    .quizflow-list
    li
    .listin-quizflow:after {
    right: -35%;
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .odd-num-photosth .flowlisting-view .quizflow-list li .listin-quizflow {
    flex: 0 0 50px;
    max-width: 50px;
  }
  .odd-num-photosth .flowlisting-view .quizflow-list li .flowgame-phtos-card {
    display: flex;
    flex-wrap: wrap;
  }
  .odd-num-photosth .flowlisting-view .quizflow-list li .flw-quiz-content {
    text-align: left;
  }
  .odd-num-photosth
    .flowlisting-view
    .quizflow-list
    li:nth-child(even)
    .flowgame-phtos-card
    .flw-quiz-content {
    order: 2;
  }
  .odd-num-photosth
    .flowlisting-view
    .quizflow-list
    li:nth-child(even)
    .flowgame-phtos-card
    .listin-quizflow {
    order: 1;
  }
  .odd-num-photosth .flowlisting-view .quizflow-list li .listin-quizflow:after {
    top: 59%;
    left: 21px;
  }
  .odd-num-photosth .flowgame-btnn .gameflw-save li {
    padding-top: 20px;
  }
  .odd-num-photosth .flowgame-btnn .gameflw-save li .btn-week .btnweak-bx {
    width: 220px;
  }
  .gameflw-save .gamestart-btnn .btnweak-bx {
    padding: 13px 10px;
  }
  .odd-num-photosth .flowgame-btnn .gameflw-save li .btn-title {
    font-size: 14px;
  }
  .photosty-games-crd {
    padding: 45px 0 60px 0;
  }
  .games-photosth-main .games-photsth-header .backbtn-lg img {
    width: 37px;
  }
  .games-photosth-main .games-photsth-header .editbtn-lg img {
    width: 27px;
  }
  .games-photosth-main .games-photsth-header h1 {
    padding: 0px 40px;
    font-size: 24px;
    line-height: 35px;
  }
  .gamesflows-twobx .flowlisting-view .quizflow-list li .listin-quizflow:after {
    top: 62%;
    left: 20px;
  }
  .gamesflows-twobx .flowgame-btnn .gameflw-save li .btn-week .btnweak-bx {
    width: 200px;
  }
  .gamesflows-twobx .flowgame-btnn .gameflw-save li .btn-title {
    font-size: 14px;
  }
  .gamesflows-twobx.gamesflows-nwbox
    .gameflow-photosth-play
    .flowlisting-view
    .quizflow-list
    li
    .listin-quizflow:after {
    right: 0%;
    width: calc(100% - 60px);
    left: auto;
    top: 50%;
  }
}
