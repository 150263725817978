.student-report-popup
{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 23px;
    background-color: rgba(255, 255, 255, 0.10);
    padding: 40px 20px;
    position: relative;
    margin-bottom: 60px;
}

.student-report-popup 
{
    .current-lesson-main
    {
        .current-lesson-topbr
        {
            max-width: 100%;
        }
    }
}

.questionbox-main
{
  

    .iner-quest-report
    {
        text-align: left;

        .left-quedtions
        {
            background-color: #f6f6ff;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
        }
    }
    .Question-listbx
    {
        padding: 20px;
        
        .title
        {
            text-align: left;
            font-size: 16px;
            line-height: 20px;
            color: #828282;
            font-weight: 400;
            padding-bottom: 5px;
            .bold 
            {   
                color: var(--purple-dark);
                font-weight: 700;
                padding-right: 10px;
            }
            .open-quest
            {
               
                color: var(--orange);
                font-size: 12px;
                font-weight: 400;
              
            }
        }
        .question-contwnt
        {
            p 
            {
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 18px;
                color: var(--grey);
                font-weight: 400;
            }
        }
    }
   
    .answer-right
    {
        .aswer-details
        {
            padding: 20px;
            p 
            {
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 18px;
                color: var(--grey);
                font-weight: 400;
            }
        }
    }
    .iner-quest-report
    {
        border-bottom: 3px solid #E2CEFF;
        &:last-child
        {
            border-bottom: none;
        }
    }
}

.question-scrlbar-bx
{
    overflow: hidden;
    padding: 0px 0;
    background: #fff;
    border-radius: 23px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin-top: 30px;
}
.question-sheet
{
    padding: 0px 0;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}
.iner-quest-report
{
    .incoret-macth
    {
        display: flex;
    flex-wrap: wrap;
    align-items: center;

    .macthing-content
    {
        flex: 1;
        padding-right: 10px;
    }

    }
    .matching-btn
    {
        .matching-incoret
        {
            font-size: 12px;
            font-weight: 600;
            background-color: var(--purple-dark);
            color: var(--white);
            padding: 5px 10px;
            border-radius: 5px;
        }
    }
}

.questionbox-main 
{
    .incoret-match-box 
    {
        background-color: rgbA(99, 44, 175, 0.20);
        .left-quedtions
        {
            background-color: transparent;
        }
    }
}
.student-reports
{
    .close-flowgame 
    {
        .btnclose-flowgm 
        {
            top: -20px;
            right: 0px;
        }
    }
}

.beginer-box
{
    .iner-quest-report
    {
        .two
        {
            align-self: center;
        }
    }
}

// start media query

@media only screen and (max-width:767px){

    .student-reports .close-flowgame .btnclose-flowgm {
        top: -30px;
    }
    .student-report-popup
    {
        margin-bottom: 40px;
    }
    .student-report-popup .current-lesson-main .current-lesson-topbr
    {
        padding: 0px;
    }
}