.footer-dasboard
{
    background: linear-gradient(-143.54deg, #AC88DE -20.63%, #8548DA -17%, #4C85F4 69.57%, #556BBB 102.97%, #CC0F97 137.84%);

    &::before
    {
        background: none;
    }

}
.dasboard-iiner
{
    background: linear-gradient(147.04deg, #5081F2 27.21%, #7658DF 81.6%);
    padding: 100px 0 0 0;
    margin-top: -40px;

}

.dasboard-iiner
{
    .container-dashboard {
        max-width: 1340px;
        margin: 0 auto;
        padding: 0 10px;
    }
    .game-soon
    {
        background-color: rgba(255, 255, 255, 0.10);
        border-radius: 37px;
        padding: 20px 30px;

        .gamerow
        {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .gamesoon-mdd, .gamesoom-medium, .gamesoon-right
            {
                flex:0 0 33.33%;
                max-width: 33.33%;
            }

        }
        .gamesoon-left
        {
            text-align: left;
            .toptex-game
            {
                h4 
                {
                    font-weight: 800;
                    font-size: 32px;
                    line-height: 36px;
                    text-transform: uppercase;
                    color:var(--yellow-dark);
                    margin-bottom: 0px;
                    padding-bottom: 15px;
                }
                .texsml-total
                {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: #f2f2f2;
                }
            }
            .takepart-tex
            {
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                color:var(--yellow-dark);
                max-width: 240px;
                padding-top: 40px;
            }
        }

    }
    .gamesoon-centerbx
    {
        h5 
        {
            font-weight: 800;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase;
            color:#fff;
            margin-bottom: 0px;
            padding-bottom: 15px;
        }

        .alluser
        {
            font-weight: 800;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            color:#fff;
            margin-bottom: 0px;
            padding-top: 10px;
            padding-bottom: 5px;
        }
        .eachpoint
        {
            .eachpoint-earn
            {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color:var(--yellow-dark);
                text-decoration: underline;
            }
        }

    }

        .gamesoon-count
        {
            ul 
            {
                padding: 0px;
                margin: 0px;

                li 
                {
                    padding: 0px 3px 6px 3px;
                    margin: 0px;
                    list-style: none;
                    display: inline-block;
                    .number-remail
                    {
                        width: 56px;
                        height: 75px;
                        background-color: #fff;
                        border-radius: 12px;
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 20px;
                        color: #000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
                    }

                    &:nth-child(2)
                    {
                        padding-right: 12px;
                    }

                }
            }
        }
        .gamesoom-medium
        {
            padding: 0 15px;
        }
}

.student-gallery
{
    .std-conatiner
    {
        max-width: 1340px;
        margin: 0 auto;
        padding: 0 10px;


    }
}
.std-listing-gllry
{
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    li 
    {
        padding: 0px 10px;
        margin: 0px;
        list-style: none;
        flex: 0 0 calc(200px + 20px);
        max-width: calc(200px + 20px);
        margin-bottom: 100px;
       
        .gallery-std-bx
        {
            background-color: rgba(255, 255, 255, 0.10);
            border-radius: 20px;
            .galery-content-std
            {
                margin-top: -30px;
                .galery-title
                {
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: #fff;
                    padding-bottom: 25px;
                    max-width: 123px;
                    margin: 0 auto;
                    min-height: 60px;
                }

            }
        }

        .galer-card-pic
        {
            position: relative;
            max-width: 160px;
            margin: 0 auto;
            position: relative;
            top: -70px;

            .galerybx-pix1
            {
                padding: 5px;
                
                img 
                {
                    max-width: 150px;
                    height: 146px;
                    width: 100%;
                }
            }
            .shapebg-gallery
            {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                width: 100%;
                height: 100%;
                perspective: 1000px;
                z-index: -1000;

                &:before 
                {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3);
                    border-radius: 18px;
                    transform: rotateY(-1deg) rotateX(-10deg);
                    -webkit-transform: rotateY(-1deg) rotateX(-10deg);
                    -moz-transform: rotateY(-1deg) rotateX(-10deg);
                    transform-style: preserve-3d;
                    
                }

            }
         

        }

    }
}
.student-gallery
{
    padding-top: 170px;
}

.circl-box
{
    .circlbox-container
    {
        max-width: 1450px;
        margin: 0 auto;
        padding: 0 10px;
    }

    .circlebx-list
    {
        position: relative;

        .circle-linebx
        {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            z-index: 0;
        }


    }

}
.circlebx-list
{
    .circl-game-box
    {
        position: relative;
        z-index: 9;
        .list-game-cardbd
        {
            padding: 0px;
            margin: 0px;
            display: flex;
           
            justify-content: space-around;
            
            li 
            {
                padding: 0px;
                margin: 0px;
                list-style: none;
                margin-top: 140px;

                img 
                {
                    border:5px solid var(--yellow-dark);
                    border-radius: 50%;
                }

                .gamecircle-tex
                {
                    padding-top: 15px;
                    h5 
                    {
                        font-size: 32px;
                    line-height: 34px;
                    font-weight: 800;
                    color: #fff;
                    margin-bottom: 0px;
                    }
                    h6 
                    {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                        color:  var(--yellow-dark);
                        margin-bottom: 0px;
                    }
                }


            }

            .smalbox
            {
                img 
                {
                    max-width: 110px;
                }
                
                .gamecircle-tex
                {
                    h5 
                    {
                        font-size: 16px;
                    line-height: 15px;
                    font-weight: 800;
                    color: #fff;
                    margin-bottom: 0px;
                    }
                    h6 
                    {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                        color:  var(--yellow-dark);
                        margin-bottom: 0px;
                    }
                }
            }

            .medimbox
            {
                img 
                {
                    max-width: 150px;
                }
                
                .gamecircle-tex
                {
                    h5 
                    {
                        font-size: 22px;
                    line-height: 24px;
                   
                    }
                    h6 
                    {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        
                    }
                }
            }

        }
    }
}
.std-listing-gllry li:nth-child(even) .galer-card-pic .shapebg-gallery:before
{
    transform: rotateY(10deg) rotateX(-10deg);
    -webkit-transform: rotateY(10deg) rotateX(-10deg);
    -moz-transform: rotateY(10deg) rotateX(-10deg);
}
.std-listing-gllry li:nth-child(odd) .galer-card-pic .shapebg-gallery:before
{
    transform: rotateY(-5deg) rotateX(-10deg);
    -webkit-transform: rotateY(-5deg) rotateX(-10deg);
    -moz-transform: rotateY(-5deg) rotateX(-10deg);
}


.circl-box {
    padding: 100px 0;
}

.back-circl-dot
{
    max-width: 1200px;
    margin: 0 auto;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    z-index: 9;
    .backdot-listt
    {
        padding: 0px;
        margin: 0px;
        display: flex;
        justify-content: space-around;
        position: relative;
        z-index: 9;

        li 
        {
            padding: 0px;
        margin: 0px;
        list-style: none;

       .dotcircl-bk
       {
           .dotcrl-icn
           {
               width: 65px;
               height: 65px;
               background: var(--yellow-dark);
               border-radius: 50%;
               z-index: 9;
           }
       } 

        }

        .md-dot
        { .dotcircl-bk
            {
                .dotcrl-icn
                {
                    width: 40px;
                    height: 40px;
                   
                }
            } 

        }
        .sml-dot
        { .dotcircl-bk
            {
                .dotcrl-icn
                {
                    width: 25px;
                    height: 25px;
                   
                }
            } 

        }


    }
}