.about-page
{
    padding-top: 100px;
    .slider-title
    {
        padding-bottom: 80px;
        p 
        {
            max-width: 750px;
        }
        .btn-week
        {
            padding-top: 20px;
        }
    }
    .right-box-slider
{
    z-index: 1;
 .pic1
 {
     position: absolute;
     top: 0px;
     left: 0px;
 }   
}
}


.stud-learn
{
    .stud-learn-list
    {
        ul 
        {
            padding: 0px;
            margin: 0px;
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;

            li 
            {
                padding: 0px 10px 20px 10px;
                margin: 0px;
                list-style: none;
                text-align: left;
                flex: 0 0 250px;
                max-width: 250px;
               .list-std-leann 
               {
                   position: relative;
                   height: 220px;
                   display: flex;
                   align-items: center;
                   flex-wrap: wrap;
                  
                   padding: 20px 40px 20px 30px;

                //    &:before 
                //    {
                //        content: "";
                //        position: absolute;
                //        top: 0px;
                //        left: 0;
                //        width: 100%;
                //        height: 100%;
                //         transform: perspective(1000px) rotateY(-23deg);
                //         -webkit-transform: perspective(1000px) rotateY(-23deg);
                //         background: radial-gradient(89.85% 73.57% at 84.21% 75.36%, rgba(0, 0, 0, 0.23) 0%,
                //         rgba(0, 0, 0, 0) 86.18%), radial-gradient(63.69% 56.22% at 25.75% 17.94%, rgba(255, 255, 255, 0.177) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #632CAF;
                //         border-radius: 37px;
                //         z-index: -1000;
                //         display: block;
                //         transform-origin: 0% 0%;
                //         -webkit-transform-origin: 0% 0%;
                //    }

                   .title 
                   {
                    font-weight: 900;
                    font-size: 42px;
                    line-height: 34px;
                    color: #fff;
                    font-family: 'Mulish', sans-serif;
                    padding-bottom: 15px;
                    padding-top: 20px;
                   }
                   p 
                   {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                    font-family: 'Mulish', sans-serif;
                    margin: 0px;
                   }
                   .lisbx-stdunt
                   {
                       z-index: 9;
                   }

               }

               .shapecount-bg
               {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                width: 100%;
                height: 100%;
                margin: 0 auto;
               }
              

            }
        }
    }
}

.stud-learn .stud-learn-list ul li:nth-child(even) .list-std-leann .shapecount-bg
{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
   -moz-transform: rotate(180deg);
}

// .stud-learn .stud-learn-list ul li:nth-child(even) .list-std-leann:before
// {
    
//     left: 0px;
// }
// .stud-learn .stud-learn-list ul li:nth-child(even) .list-std-leann
// {
//     transform: rotate(-180deg);
    
// }
// .stud-learn .stud-learn-list ul li:nth-child(even) .list-std-leann .lisbx-stdunt
// {
//     transform: rotate(180deg);
// }
.stud-learn .stud-learn-list ul li .list-std-leann p
{
    padding-right: 20px;
}
.stud-learn .stud-learn-list ul li:nth-child(2) .list-std-leann p
{
    padding-right: 20px;
}
.stud-learn .stud-learn-list ul li:nth-child(1) .list-std-leann p
{
    padding-right: 20px;
}

.stud-contain
{

    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    transform: translateY(120px);

    .girlright-pic
    {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 99;
    }
}

.about-content
{
    .about-card
    {
        text-align: left;
        position: relative;
        z-index: 9;
        padding: 70px 20px 50px 20px;

        &:before 
        {
            content: "";
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1000;
            box-shadow: 0px 19px 36px rgba(0, 0, 0, 0.25);
            border-radius: 25px;
            background: #fff;
            transform: perspective(1000px) rotateY(0deg) rotateX(3deg);
            -webkit-transform: perspective(1000px) rotateY(3deg) rotateX(0deg);
            display: block;
            transform-origin: 0% 0%;
            -webkit-transform-origin: 0% 0%;
        }


        .about-cardiner
        {
            max-width: 900px;
            margin: 0 auto;
            h4 
            {
                font-weight: 700;
                font-size: 34px;
                line-height: 39px;
                letter-spacing: 0.04em;
                color: var(--purple);
                margin: 0px;
                padding-bottom: 20px;
            }
            .small-tex
            {
                color: var(--purple);
                margin: 0px;
                padding-bottom: 20px;
            }
        }

    }
}
.about-section-boxx
{
    .about-card
    {
        &:before 
        {
            display: none;
        }
        .aboutshape-bg
        {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            perspective: 1000px;
            z-index: -1000;
            transform: rotate(180deg);

            &:before 
            {
                content: "";
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1000;
            box-shadow: 0px 19px 36px rgba(0, 0, 0, 0.25);
            border-radius: 25px;
            background: #fff;
            display: block;
            transform: rotateX(7deg) rotateY(5deg);
            -webkit-transform: rotateX(7deg) rotateY(5deg);
            -moz-transform: rotateX(7deg) rotateY(5deg);
            transform-style: preserve-3d;
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            }

        }
    }
}

.about-section-boxx
{
    padding-top: 0px;
}
.about-page-wrapper{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.foter-about
{
    padding-top: 150px;
}
.prodt-contt
{
    text-align: left;
    h4 
    {
        font-weight: 700;
        font-size: 32px;
        line-height: 56px;
        color: #fff;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        letter-spacing: 0.04em;
        margin: 0px;
        padding-bottom: 15px;
    }
    .small-tex 
    {
        color: #fff;
        padding-bottom: 30px;
    }
}
.procust-section-new
{
    position: relative;
    z-index: 1;
    padding: 40px 40px;
    &::before
    {
        content: "";
        position: absolute;
        top: 0px;
        left: 20px;
        width: 100%;
        height: 100%;
        border-radius: 37px;
    background: rgba(255, 255, 255, 0.10);
    transform: perspective(1000px) rotateX(6deg) rotateY(3deg);
    z-index: -1;

    }

}

.procust-section-new
{
    .slider-cardbox
    {
        padding: 0px;
    }
}

.procust-section-new .slick-next, .procust-section-new .slick-prev
{
    display: none !important;

}
// .slick-dots li button:before
// {
//     background: #fff;
//     opacity: 1;
// }
// .slick-dots li.slick-active button:before
// {
//     opacity: 1;
// }

.slick-dots li.slick-active button:before
{
    color: var(--yellow);
    opacity: 1;
    font-size: 10px;
}
.slick-dots li button:before
{
    color: #fff;
    opacity: 1;
    font-size: 10px;

}
.product-section {
    margin-bottom: 50px;
}

.product-slidertwo .procust-section-new::before
{
    transform: perspective(1000px) rotateX(-6deg) rotateY(-3deg);
        left: -20px;
}
.product-slidertwo  .prodt-contt {
    text-align: left;
    padding-left: 20px;
}

    .prodt-listbtn
    {
        padding: 0px;
        margin: 0px;

        li 
        {
            padding: 0px;
        margin: 0px;
        list-style: none;
        display: inline-block;
         padding-right: 45px; 
         padding-bottom: 30px;  

         .tutor-becm 
         {
            .btnweak-bx
            {
                padding: 13px 20px;
                width: 170px;
                text-align: center;
            }
         }

    }

    }


    .teamlist-tex
    {
        ul 
        {
            padding: 0px;
            margin: 0px;

            li 
            {
                padding: 0px 10px 20px 10px;
                margin: 0px;
                list-style: none;
                display: inline-block;

                .teamlist-frame
                {
                    width: 230px;
                    height: 235px;
                    background: #fff;
                    border-radius: 13px;
                    transform: perspective(1000px) rotateY(-11deg) rotateX(7deg);
                    transform-origin: right;
                    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3); 
                    border-radius: 18px;
                    margin: 0 auto;
                    margin-bottom: 20px;
                }

                &:nth-child(odd) 
                {
                    .teamlist-frame 
                    {
                        transform: perspective(1000px) rotateY(11deg) rotateX(-7deg);
                        transform-origin: left;
                        position: relative;
                        left: 10px;
                    }
                }
                 
            }
        }
    }

    .our-team-bx
    {
        .teamlist-tex
        {
            padding-top: 50px;

            .member {
                margin-bottom: 80px;
            }

            .t-name {
                font-weight: 600;
                font-size: 28px;
                line-height: 30px;
                text-align: left;
                color: #fff;
                margin: 0 0 6px 0;
            }

            .t-status {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #FFD300;
                text-align: left;
                margin: 0 0 15px 0;
            }

            .small-tex {
                font-size: 14px;
                margin: 0;
                padding: 0;
                text-align: left;
                letter-spacing: 0.04em;
                line-height: 20px;
                color: #fff;
            }

            .teamlist-frame
                {
                    width: 230px;
                    height: 230px;
                    background: #fff;
                    border-radius: 13px;
                    transform: perspective(1000px) rotateY(15deg) rotateX(0deg);
                    transform-origin: right;
                    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3); 
                    border-radius: 18px;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    overflow: hidden;
                    border: 9px solid #fff;

                    img{
                        transform: perspective(1000px) rotateY(0) rotateX(0);
                        width: 100%;
                        height: 100%;

                    }

                }   

        }

        .title-team {
            font-weight: 900;
            font-size: 26px;
            line-height: 30px;
            color: #FFD300;
            max-width: 620px;
            margin: 30px auto 30px;
        }

        .hd-tex {
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.04em;
            color: #fff;
        }

    }

    .product-box
    {
        .heading-top
        {
            padding-bottom: 50px;
        }
    }

    .more-about {
        margin: 40px 0 0 0;
        color: #fff;
        font-size: 16px;
        font-weight: 400;

        a{
            color: #FFD300;
            text-decoration: underline;
            &:hover{
                color: #e5c113;
            }
        }

    }

.our-product
{
    .product-box
    {
        max-width: 1065px;
        margin: 0 auto;
    }
}


.about-section-boxx
{
    padding-top: 80px;
    padding-bottom: 50px;
    .custom-row
    {
        display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
    line-height: 1;

    .left-pix-card
    {
        flex: 0 0 20%;
        max-width: 20%;

        .kids-pic2
        {
            position: relative;
            top: 0px;
            left: -10px;

            img 
            {
                max-width: 170px;
                width: 100%;
            }

        }
    }
    .right-aboutbx-crad
    {
        flex: 0 0 80%;
        max-width: 80%;
    }

    }
    @media only screen and (max-width:767px){
        padding-top: 100px;

    }
}
.about-page-container{
    padding-top: 0px !important;
}
.prodt-contt 
{
.products-contentbx
{
    padding-bottom: 40px;
}

    .small-tex
    {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width:767px){

    .about-page .right-box-slider .pic1 {
        position: relative;
       
    }

    .about-section-boxx .custom-row .left-pix-card {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .about-section-boxx .custom-row .right-aboutbx-crad {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .about-section-boxx .custom-row .left-pix-card .kids-pic2 img {
        max-width: 100px;
        width: 100%;
    }
    .about-section-boxx .custom-row .left-pix-card .kids-pic2 {
        position: absolute;
        top: -80px;
        left: -10px;
    }
}