.child-inercard-prog
{
    background-color: var(--white);
    border-radius: 20px;
    padding: 20px 20px;
    transition: all 300ms cubic-bezier(.73,.11,.01,.98);
    &:hover 
    {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      
        transform: translateY(-5px);
    }

    .custrow {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .leftcard-prog
    {
        flex:0 0 auto;
        max-width: 100%;
        width: 30%;
    }
    .right-prog
    {
        flex:0 0 auto;
        max-width: 100%;
        width: 70%;
    }
    .child-profbx
    {
        text-align: left;
    }
    .chlid-cardd
    {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .child-profle
    {
        flex: 0 0 auto;
        max-width: 100%;
        width: 50px;

        .profile-circl-child
        {
            width: 50px;
            height: 50px;
            background: var(--orange);
            color: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            border-radius: 50%;
        }
        
    }
    .child-name
        {
            flex: 1;
            padding-left: 10px;
            .name-child
            {
                font-size: 18px;
                line-height: 20px;
                font-weight: 700;
                color: var(--purple-dark);
            }
            .child-subject
            {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.04em;
                color: #828282;
            }
        }

}

.children-card-progd
{
    .chlidcard-outer
    {
        text-decoration: none;
    }
}
.child-righgtlist
{
    .child-activty-list
    {
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .iner-child-actvlist
        {
            padding-right: 20px;
            &:last-child
            {
                padding-right: 0px;
            }

            h5 
            {
                font-size: 25px;
                font-weight: 700;
                color: var(--orange);
                margin-bottom: 0px;
            }
            h6 
            {
                font-size: 16px;
                color: var(--grey);
                font-weight: 500;
            }
        }

    }
}
.children-card-progd
{
    padding-bottom: 15px;
}
.children-proglist
{
    padding-bottom: 80px;
}

// start media query

@media only screen and (max-width:991px){


.child-inercard-prog .right-prog {
    width: 100%;
}
.child-inercard-prog .leftcard-prog {

    width: 100%;
}
.child-righgtlist .child-activty-list .iner-child-actvlist {
    padding-right: 10px;
}
.child-righgtlist .child-activty-list .iner-child-actvlist h6 {
    font-size: 16px;
  
}
.child-inercard-prog .child-profbx
{
    padding-bottom: 20px;
}


}
@media only screen and (max-width:767px){
    .child-inercard-prog
    {
        padding: 20px 10px;
    }
    .child-inercard-prog .child-name .name-child
    {
        font-size: 16px;
    }
    .child-righgtlist .child-activty-list .iner-child-actvlist {
        padding-right: 10px;
    }
    .child-righgtlist .child-activty-list .iner-child-actvlist h5
    {
        font-size: 20px;
    }
    .child-righgtlist .child-activty-list .iner-child-actvlist h6 {
        font-size: 12px;
    }
    .child-righgtlist .child-activty-list
    {
        justify-content: flex-start;
    }

}