.footer-parents
{
    .foter-list 
{
    .foter-listnew 
    {
        ul
        {
            flex: 0 0 100%;;
            max-width: 100%;
            text-align: right;
            padding-right: 20px;
            li 
            {
                display: inline-flex;
                padding: 0 40px;
            }
        }
    }
}
}


.taskpurple 
{
    li 
    {
        .btn-learner-lsit 
        {
            color:#000;
            background: rgba(106, 100, 230, 0.1);
        }
        .active 
        {
            color: var(--white);
            background: var(--purple);
        }
    }
}

.modal-pupil-new 
{
    .persltask-form
    {
        .loginform-bx 
        {
            .taskbtn-uppr
            {
                margin-bottom: 30px;
               
            }
        }   
    }
    
}

.datepicker-purple input[type="date"]::-webkit-calendar-picker-indicator
{
  
    background-image: url(../assets/images/calender-purple.svg);
    
    background-repeat: no-repeat;
 
}
.loginmodal 
{
    .loginform-bx 
    {
        .subsform-fild 
        {
            .form-control.texblck 
            {
                color: #000;
                &::placeholder
                {
                    color: #000;
                }

            }
        }
    }
}