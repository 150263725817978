.custome-container {
  max-width: 1850px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
:root {
  --primary-gradient: linear-gradient(
    62.75deg,
    #ac88de -26.91%,
    #8548da -23.67%,
    #4c85f4 53.73%,
    #556bbb 83.59%,
    #cc0f97 114.76%
  );
  --purple: #6a64e6;
  --purple-dark: #632caf;
  --yellow: #ffc700;
  --yellow-dark: #ffd300;
  --orange: #ff7a00;
  --white: #fff;
  --black: #000;
  --grey: #333;
  --sky: #4fe7e7;
  --grey-light: #828282;
  --disabled-btn-bg: radial-gradient(
      89.66% 75.31% at 55.08% 86.94%,
      rgba(214, 214, 214, 0.55) 0%,
      rgba(0, 0, 0, 0) 86.18%
    ),
    radial-gradient(
      63.69% 56.22% at 25.75% 17.94%,
      rgba(255, 255, 255, 0.413) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ebebeb;
  --disabled-btn-color: rgba(0, 0, 0, 0.25);
}
.App {
  text-align: center;
}

.underline {
  text-decoration-line: underline !important;
}

.btnlogin:disabled {
  background: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
}

.btnlogin:disabled:hover {
  background: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed;
}

/* header dashboard */
.side-menu {
  position: absolute;
  top: 10px;
  left: -210px;
  /* // adjust the value to move the menu offscreen */
  width: 200px;
  display: block;
  height: 100vh;
  /* background-color: #fff; */
  transition: all 0.3s ease-in-out;
}
.side-menu::-webkit-scrollbar {
  display: none;
}
.side-menu input::placeholder {
  color: white;
}
.side-menu.show {
  /* left: 0; */
  display: none;
}
.highlightLanguage {
  /* border-radius: 12px !important; */
  color: white !important;
}
.blurLanguage {
  /* color: var(--orange) !important; */
  color: var(--yellow) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.css-ll3xzl-MuiSlider-track {
  /* border-color: var(--purple-dark) !important; */
  border-color: #ffffff !important;
}

.css-19l5h75-MuiSlider-markLabel {
  width: 30% !important;
  color: #ffffff !important;
}
.css-y35fuw-MuiSlider-thumb {
  width: 15px !important;
  height: 15px !important;
  color: var(--orange) !important;
}
.css-udnwse-MuiSlider-mark {
  background-color: var(--orange) !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.small-tex {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1240px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .why-illumiya .cardbx-left {
    max-width: 500px;
    margin: 0 auto;
  }
  .kids-better-box {
    max-width: 1000px;
  }
}

.error {
  color: red;
  font-size: 0.9rem;
}
.error-box {
  border: 3px #fd5353 solid !important;
}

a {
  text-decoration: none !important;
}

/* credit card form */

.credit-card {
  color: white;
  margin-top: 41px;
  position: relative;
}

.credit-card .front,
.credit-card .back {
  background-color: hsl(200, 80%, 30%);
  background-color: #556bbb;
  /* border: 1px solid hsl(200, 80%, 10%); */
  border-radius: 13px;
  /* width: 325px; */
  width: 70%;
  height: 165px;
  padding: 0.75rem 1rem;
  padding-bottom: 1.25rem;
}
/* .credit-card .back {
  background-color: rgba(255, 255, 255, 0.62);
} */

.credit-card .front {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.credit-card .card-data-row {
  display: flex;
  margin-bottom: auto;
}

.credit-card .logo {
  height: 40px;
  width: 50px;
  flex-grow: 0;
}

.credit-card .brand-name {
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: bold;
}
#expiration {
  width: 6ch;
}
.credit-card .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.credit-card fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.credit-card .horizontal-input-stack {
  display: flex;
  gap: 0.5rem;
}

.credit-card .cc-inputs input {
  width: 4ch;
  font-family: monospace;
}

.credit-card input,
.credit-card select {
  padding: 0.25em 0.5em;
  border: none;
  border-radius: 0.25em;
  appearance: none;
}

.credit-card label {
  font-size: 0.65rem;
  text-transform: uppercase;
}

.credit-card .input-row {
  display: flex;
  gap: 2rem;
}

.credit-card .name-group {
  flex-grow: 1;
}

.credit-card .front::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 100%;
  background-color: hsl(0, 0%, 100%, 0.15);
  top: -250px;
  left: -150px;
  z-index: -1;
}

.credit-card .front::after {
  content: "";
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 100%;
  background-color: hsl(0, 0%, 100%, 0.075);
  bottom: -475px;
  left: -150px;
  z-index: -1;
}

.credit-card .back {
  position: absolute;
  top: 2rem;
  left: 4.2rem;
}

.credit-card .stripe {
  /* background-color: hsl(200, 80%, 10%); */
  height: 35px;
  position: absolute;
  left: 0;
  right: 0;
  top: 1.5rem;
}

.credit-card .cvc-group {
  position: absolute;
  bottom: 3.25rem;
  right: 0.5rem;
}

.credit-card .cvc-input {
  width: 6ch;
  font-family: monospace;
}
.css-1ngmfq-MuiSlider-root {
  /* display: block !important; */
}

.Next__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  cursor: pointer;
  filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
}

.previow__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  cursor: pointer;
  filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
}

.Next__button2 {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 40%;
  cursor: pointer;
  filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.previow__button2 {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 40%;
  cursor: pointer;
  z-index: 1;
  filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
}

@media (max-width: 1440px) {
  .Next__button2 {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 35%;
    cursor: pointer;
    filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
  }

  .previow__button2 {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 35%;
    cursor: pointer;

    filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
  }
}

@media (max-width: 768px) {
  .Next__button2 {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 10%;
    cursor: pointer;
    filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
  }

  .previow__button2 {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 10%;
    cursor: pointer;
    filter: drop-shadow(4px 8px 20px rgba(0, 0, 0, 0.3));
  }
}

@media (max-width: 385px) {
  .css-2sxh93 {
    left: -56%;
  }
}

.Not_available {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Game_Not_found {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.list-counter {
  position: relative;
  perspective: 130px;
  width: 400px;
  height: 130px;
  margin-top: -150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  color: var(--yellow-dark);
}

.shapebx-count {
  position: absolute;
  top: -6px;
  left: 10px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #ffffff;
  opacity: 0.2;
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  display: block;
  transform: rotateX(7deg);
  -webkit-transform: rotateX(7deg);
  -moz-transform: rotateX(7deg);
  perspective: 130px;
  transform-style: preserve-3d;
  transform-origin: 0;
  -webkit-transform-origin: 0;
}

.mb_tab {
  margin-bottom: 110px;
}

.mb_tab2 {
  margin-bottom: 50px;
}

@media (max-width: 820px) {
  .Game_Not_found {
    height: 30vh;
  }

  .mb_tab {
    margin-bottom: 270px;
  }
  .mb_tab2 {
    margin-bottom: 120px;
  }
}

@media (max-width: 768px) {
  .list-counter {
    width: 280px;
  }

  .mb_tab {
    margin-bottom: 70px;
  }
  .mb_tab2 {
    margin-bottom: 10px;
  }
}

.su {
  position: relative;
  z-index: 9;
}

.su:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  transform: perspective(1000px) rotateY(0deg) rotateX(-14deg);
  -webkit-transform: perspective(1000px) rotateY(0deg) rotateX(-14deg);
  display: block;
  z-index: -1000;
  background: #f3ebeb;
  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
}
