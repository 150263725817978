.questionone-main
{
    padding: 45px 0 150px 0;
    .iner-questionbx
    {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        background-color: var(--white);
        padding: 40px 40px 0px 40px;
    }
    .question-btom-btn
    {
        padding-top: 20px;
    }
}

.iner-questionbx
{
    .question-heading
    {
        padding-bottom: 30px;
        h3 
        {
            font-size: 26px;
            line-height: 35px;
            letter-spacing: 0.04em;
            font-weight: 700;
            color: var(--purple-dark);
            margin-bottom: 0px;
            padding-bottom: 10px;
        }
    }
}
.questionform
{
    .questionbx-frm
    {
        .labelbx-quest
        {
            font-size: 15px;
            line-height: 20px;
            font-weight: 700;
            color: var(--grey);
            margin-bottom: 0px;
            text-align: left;
            padding-bottom: 10px;
            display: block;
        }
        .choose-learning-list
        {
            padding-bottom: 0px;
        }
    }
    .learner-listing-games 
    {
        li 
        {
            .btn-learner-lsit 
            {
                background: rgba(106, 100, 230, 0.1);
            }
            .active 
            {
                    background: var(--orange);
                    color: var(--white);
            }
        }
        
    }
}
.form-questionbx
{
    .questionbx-frm
    {
        textarea 
        {
            background: rgba(106, 100, 230, 0.1);
            border: none;
            border-radius: 13px;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 20px;
            height: 150px !important;
            resize: none;
        }

        .form-control
        {
            font-size: 14px;
            line-height: 20px;
            color: var(--purple);
            font-weight: 400;
            height: 52px;
            border-radius: 13px;
            background-color: rgba(106, 100, 230, 0.1);
            border: none;
            padding: 5px 20px;
           
            
            &::placeholder
            {
                color: var(--grey-light);
            }
        }
        .smalltex-frm
        {
            text-align: left;
            color: var(--grey-light);
            font-size: 14px;
        }

    }
    img 
    {
        max-width: 100%;
    }
}

.questionbx-frm
{
    .correct-aswr-crd
    {
        .inner-cardquizz
        {
            padding: 0px;
            border-radius: 0px;
           
        }
        .inner-picfrme
        {
            border-radius: 20px;
            overflow:hidden;
        }
    }
}

.custm-check input:checked ~ .inner-picfrme
{
    border:4px solid var(--orange);
  
}
.checkbx-try
{
    .gameflow-check 
    {
        padding-left: 40px;
        .checkmark 
        {
            height: 24px;
            width: 24px;
            border-radius: 6px;
            top: -3px;
            
        }
        .inner-bodyquiz
        {
            text-align: left;
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
            color: var(--grey);
            margin-bottom: 0px;
        }
    }
}
.checkbx-try .custm-check .checkmark:after
{
    left: 9px;
    top: 5px;
    display: none;
}

.custm-check input:checked ~ .checkmark:after
{
    display: block;
}

.question-btom-btn
{
    ul 
    {
        padding: 0px;
        margin: 0px;

        li 
        {
            display: inline-block;
            padding: 0 20px;
            padding-top: 15px;
            .gamestart-btnn 
            {
                transform: translateY(20px);
                .btnweak-bx 
                {
                    font-size: 16px;
                    line-height: 27px;
                    padding: 13px 10px;
                    width: 210px;
                }
                .btn-week
                {
                    padding-right: 20px;
                }
            }
        }
    }
}
.questionbx-frm {
    padding-bottom: 20px;
}
.questionform 
{
    .questionbx-frm.option-sml 
    {
        .labelbx-quest 
    {
        padding-bottom: 5px;
    }
    }
}
.choose-aswr-card
{
    padding-top: 20px;
}
.num-qust-frm
{
    max-width: 25%;
}

// start media query

@media only screen and (max-width:767px){

.questionone-main
{
    .choose-aswr-card
    {
        .one
        {
            flex: 0 0 auto;
            width: 50%;
        }
    }
}
.questionone-main .choose-aswr-card .inner-cardquizz
{
    margin-bottom: 20px;
}
.custm-check input:checked ~ .inner-picfrme {
    border: 2px solid var(--orange);
}
.num-qust-frm {
    max-width: 100%;
}
.questionone-main .iner-questionbx
{
    padding: 40px 15px 0px 15px;
}
.question-btom-btn ul li .gamestart-btnn .btnweak-bx
{
    font-size: 14px;
    line-height: 21px;
    width: 180px;
}
.questionone-main {
    padding: 45px 0 80px 0;
}

}

.question-btom-btn.material-video-btn 
{
    ul 
    {
        li 
        {
            .gamestart-btnn 
            {
                transform: translateY(0px);
            }
        }
    }
}
.questionone-main 
{
    .question-btom-btn.material-video-btn  
    {
        padding-bottom: 20px;
    }
}

// start add mymaterials

.slider-video-material
{
    padding: 12px;
    .slider-iner-videomatrl
    {
        position: relative;
        overflow:hidden;

        .innergalery-file
        {
            border-radius: 10px;

            img 
            {
                border-radius: 10px;
            }
        }

    }
    .plynow-btn
    {
        .playvd-btn
        {
            width: 50px;
            height: 50px;
        }
    }

    
}

.slider-iner-videomatrl
{
    .resize-btn
    {
        position: absolute;
    top: 10px;
    right: 10px;
       .btn-resize-video 
       {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        border: none;
        color: var(--orange);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
       }

    }
}
.material-addvideo
{
    .heading-matrl
    {
        text-align: left;
        .heading-top
        {
            font-size: 15px;
            line-height: 20px;
            font-weight: 700;
            color: var(--grey);
            margin-bottom: 0px;
            text-align: left;
            display: inline-block;
            padding-right: 10px;
        }

       
        .section-hedgmatrl
            {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-left: 12px;

                .addbtn-matrl
                {
                    padding: 0px;
                    width: 25px;
                    height: 25px;
                    border-radius: 50px;
                    border: none;
                    background-color: var(--orange);
                    color: var(--white);
                    font-size: 11px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
    }
}
.material-addvideo
{
    padding: 0 30px;
    position: relative;
}

.videomaterial-listt .slick-next, .videomaterial-listt .slick-prev
{
    width: 30px;
    height: 30px;
    z-index: 9;
    top: auto;
    bottom: 0px;
}
.videomaterial-listt .slick-prev
{
    left: -30px;
    transform: rotate(270deg);
    bottom: 20px;
}
.videomaterial-listt .slick-next
{
    right: auto;
    left: -30px;
    bottom: 70px;
    transform: rotate(90deg);
}
.videomaterial-listt .slick-disabled
{
    opacity: 0.5;
    pointer-events:none;
}

.material-addvideo
{
    .btnclose-rightt {
        position: absolute;
        top: 0px;
        right: 0px;
        .closebtn-cardbx
        {
            width: 25px;
            height: 25px;
            border: none;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--orange);
            background-color: transparent;
            padding: 0px;
            font-weight: 300;
        }
    }
}
.material-videobx {
    padding-bottom: 30px;
}
.btnsave-matrial
{
    text-align: left;
    padding-bottom: 30px;
    .btnweak-bx
    {
        font-size: 16px;
    }
}