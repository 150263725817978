.video-controls-left-alignment {
  text-align: center;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 31;
}

.video-controls-right-alignment {
  text-align: center;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 31;
}

.video-controls-right-alignment-chat-active {
  text-align: center;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 31;
}

.video-controls-center-alignment {
  text-align: center;
  position: absolute;
  bottom: 15px;
  right: calc(50vw - 5.5vmin);
  z-index: 2;
}

.video-controls-mic {
  cursor: pointer;
  padding-right: 2vmin;
  padding-left: 0.5vmin;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
}

.video-controls-video {
  height: 40px;
  cursor: pointer;
  padding-right: 2vmin;
  width: 50px;
}

.video-controls-fullscreen {
  cursor: pointer;
}

.video-controls-chat {
  cursor: pointer;
}

.video-controls-record {
  height: 5vmin;
  cursor: pointer;
  padding-right: 3vmin;
}

.video-controls-screenshot {
  height: 5vmin;
  cursor: pointer;
  padding-right: 3vmin;
}

.video-controls-stop {
  cursor: pointer;
}

.video-controls-whiteboard-fullscreen {
  cursor: pointer;
}

.video-controls-whiteboard {
  cursor: pointer;
}

.video-controls-grid {
  cursor: pointer;
}

.video-controls-container-left {
  background-color: #50505052;
  min-height: 62px;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  color: white;
  background-color: rgba(255, 255, 255, 0.6);
}

.video-controls-container-right {
  background-color: rgba(255, 255, 255, 0.6);
  min-height: 62px;
  min-width: 44vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  color: white;
  padding: 0px 28px;
}

.video-controls-container-right-whiteboard-inactive {
  background-color: #50505052;
  min-height: 62px;
  min-width: 328px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  color: white;
  background-color: rgba(255, 255, 255, 0.6);
  justify-content: space-between;
  padding: 0px 28px 0px 28px;
}

.video-controls-container-right-whiteboard-inactive-one {
  background-color: #50505052;
  min-height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.6);
  min-width: 150px;
  padding: 2px 20px;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  color: white;
}

.video-controls-container-center {
  background-color: #50505052;
  min-height: 62px;
  min-width: 11vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  color: white;
}
.video-controls-whiteboard {
  width: 40px;
  height: 32px;
}
.video-controls-grid {
  width: 40px;
  height: 32px;
}
.video-controls-fullscreen {
  width: 40px;
  height: 30px;
}
.video-controls-stop {
  width: 36px;
  height: 36px;
}
.video-controls-chat {
  width: 40px;
  height: 33px;
}

@media (max-width: 550px) {
  .video-controls-container-right-whiteboard-inactive {
    min-width: 228px;
    padding: 0px 10px 0px 10px;
  }
}

@media (max-width: 1050px) {
  .video-controls-container-left {
    min-height: 40px;
    min-width: 12vmin;
  }
  .video-controls-mic {
    min-width: 28px;
    min-height: 25px;
    width: 35px;
    height: 25px;
  }

  .video-controls-video {
    width: 45px;
    height: 25px;
  }
  .video-controls-container-right-whiteboard-inactive {
    padding: 0px 10px 0px 10px;
  }

  .video-controls-whiteboard {
    width: 30px;
    height: 25px;
  }
  .video-controls-grid {
    width: 30px;
    height: 25px;
  }
  .video-controls-fullscreen {
    width: 30px;
    height: 22px;
  }
  .video-controls-stop {
    width: 25px;
    height: 22px;
  }
  .video-controls-chat {
    width: 28px;
    height: 22px;
  }
  .video-controls-container-right-whiteboard-inactive {
    min-width: 220px;
    min-height: 40px;
  }
  .video-controls-whiteboard-fullscreen {
    width: 30px;
    height: 22px;
  }
  .video-controls-container-right {
    min-width: 220px;
    min-height: 40px;
  }
  .video-controls-container-right {
    padding: 0px 10px 0px 10px;
  }
  .video-controls-container-right-whiteboard-inactive-one {
    min-height: 40px;
  }
}
