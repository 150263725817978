.display-flex{
    display: flex !important;
}
.toast-error {
    font-size: 16px;
    width: 410px;
    right: 120px;
  }
  
.student-weekly-cardiner
{
    background-color: var(--white);
    cursor: pointer;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 25px;
    height: 220px;
    overflow: hidden;
    transition: all 300ms linear;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    // &:hover 
    // {
    //     transition: all 300ms linear;
    //     height: 320px;
    // }
    .discountText {
        font-weight: bold;
        color: black;
    }
    .points-btn
    {
        .totalpoints-btn
        {
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            color: var(--white);
            background-color: var(--sky);
            border-radius: 11px;
            height: 34px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;
            max-width: 120px;
            width: 100%;
        }
    }
  
    .quiz-bdy-content-bx 
    {
        p
        {
            max-width: 100%;
        }
        h4 
        {
            padding-bottom: 0px;
        }
        .duration-days
        {
            padding-bottom: 20px;
        }
    }
    .quiz-bdy-content-bx
    {
        position: relative;

        .weeklyhwr
        {
            padding-top: 20px;
            opacity: 0;
            transition: all 300ms linear;
        }
    }
    
    &:hover 
    {
        .weeklyhwr
        {
            opacity: 1;
            transition: all 300ms linear;
        }
    }

}
.livelessons{
    .bg{
        background-color: var(--white);
        border-radius: 10px;
        padding: 1rem;
    }
    // .buybtn{
    //     background-color: var(--yellow-dark);
    //     color: var(--purple-dark);
    //     border: none;
    //     font-weight: bold;
    //     border-radius: 0.5rem;
    //     padding: 0.5rem;
    // }
        .buybtn{
            max-width: 13rem;
            margin: 1rem 0;
            padding: 0.7rem;
            font-size:15px;
        }
    .btn-price-space {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        div:first-child{
            margin-right: 15px;
        }
    }
}

.subscription-tab{
    .dialog-points{
        p{
            color: #ffd300;
        }
    }
}
.dialog-buybtn{
    padding: 10px;
    border: none;
    // display: flex;
    // justify-content: space-between;
    span:first-child{
        margin-right: 10px;
    }
    b{
        margin-left: 10px;
    }
}
.term-of-subscritpion select{
    width: 100% !important;
    padding-left: 13px;
    justify-content: flex-start;
    margin-right: auto;
    cursor: pointer;

    -webkit-appearance: none;
    appearance: none;
    height: 41px;
    background-color: rgba(106, 100, 230, 0.1);
    outline: none;
    border: none;
    border-radius: 5px;

    

}

.term-of-subscritpion {
    position: relative;
    
}
.term-of-subscritpion::after {
    content: "▼";
    // content: '\2304	    ';
    color: rgba(0, 0, 0, 0.6);
    top: 10.5px;
    font-size: 0.8rem;
    right: 14px;
    position: absolute;
}
// .term-of-subscritpion::before {
//     content: "-5%";
//     // content: '\2304	    ';
//     color: rgba(0, 0, 0, 0.6);
//     top: 10.5px;
//     font-weight: 700;
//     font-size: 14px;
//     right: 54px;
//     position: absolute;
// }